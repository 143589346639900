import React, { useEffect } from 'react';
import { CheckBadgeIcon, EyeIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Alert,
    Select,
    Option,
} from "@material-tailwind/react";
import { useState } from "react";
import { AlertCustom } from "../../../componentes/AlertCustom";
import { useDispatch, useSelector } from 'react-redux';
import { PedidosForm } from './PedidosForm';
import { useNavigate } from 'react-router-dom';
import ModalActivarDesactivar from '../../../componentes/ModalActivarDesactivar';
import { pedidosListaRegistros, actualizarEstadoPedido } from '../../../actions/pedidos/pedidosActions';
import { formatDate } from '../../../utils/utils';
import { getExcel } from '../../../actions/reportes/reportesActions';
import { ModalChangeState } from '../../../componentes/ModalChangeState';

const TABLE_HEAD = ['Id', "Nombre", "Fecha de ingreso", "Total", "Estado", "Acciones"];


export function OrdenesList() {
    const navigate = useNavigate();
    const useData = useSelector((state) => state.userLogin || null);
    const [openDeleteCategoria, setOpenDeleteCategoria] = useState(false);
    const [idPedido, setIdPedido] = useState(null);
    const [tipoForm, setTipoForm] = useState('Crear');
    //const [activar_eliminar, setActivarEliminar] = useState(null);
    const { error, userInfo } = useData;

    useEffect(() => {
        if (!error) {
            if (userInfo) {
                if (userInfo?.perfil == 31)
                    navigate("/home");
            } else if ([16, 22].includes(userInfo?.perfil)) {
                return
            }
        }
    }, []);

    const dispatch = useDispatch();

    const [openOrdenesForm, setOpenPedidoForm] = useState(false);
    const [okCreate, setOkCreate] = React.useState(false);
    const [mensaje, setMensaje] = React.useState("");

    const [openChangeState, setOpenChangeState] = useState(false);
    const [idPedidoSeleccionado, setIdPedidoSeleccionado] = useState(null);
    const [page, setPage] = React.useState(1);
    const [limitPage, setLimitPage] = React.useState(10);
    const [valorBusqueda, setValorBusqueda] = React.useState('');

    const [dataList, setDataList] = React.useState([]);

    const { loading, pedidos, pages, page: newPages } = useSelector((state) => state.pedidosListR);

    const handlePageSizeChange = (e) => {
        setLimitPage(parseInt(e.target.value));
        setPage(1);
    };
    const handleSearchChange = (e) => {
        setValorBusqueda(e.target.value || '');
    };
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    const getReport = async () => {
        dispatch(getExcel({
            value: Number(4),
            name: String('Reporte ordenes'),
        }));
    }

    const handleOpenChangeState = (idPedido) => {
        setIdPedidoSeleccionado(idPedido);
        setOpenChangeState(!openChangeState);
    };

    const handleUpdateState = (id, newState) => {
        dispatch(actualizarEstadoPedido({
            id,
            newState,
            valorBusqueda: valorBusqueda,
            page: page,
            limitPage: limitPage
        }));
    };

    useEffect(() => {
        dispatch(pedidosListaRegistros({ page, limitPage, valorBusqueda }))
    }, [dispatch, page, limitPage, valorBusqueda])

    useEffect(() => {
        if (pedidos && loading === false) {
            setDataList(pedidos);
        }
    }, [pedidos, loading])
    useEffect(() => {
        dispatch(pedidosListaRegistros({ page, limitPage, valorBusqueda }))
    }, [valorBusqueda])
    return (



        <Card className="h-full w-full">
            <ModalChangeState
                open={openChangeState}
                handleOpen={() => setOpenChangeState(!openChangeState)}
                idPedido={idPedidoSeleccionado}
                onUpdateState={handleUpdateState}
            />
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                            Ordenes
                        </Typography>
                    </div>
                    <div className="flex w-full shrink-0 gap-2 md:w-max relative"> {/* Agrega 'relative' al contenedor */}
                        <Button className="flex items-center gap-3 bg-purple-500" size="sm" onClick={getReport}>
                            <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Desacargar reporte
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <div className='flex w-full gap-2 mb-[1%] mt-[1%] items-center'>
                <div className="flex-shrink-0">
                    <Select value={limitPage} onChange={handlePageSizeChange}>
                        <Option key={1} value={'10'}>10 por pagina</Option>
                        <Option key={1} value={'30'}>30 por pagina</Option>
                        <Option key={1} value={'50'}>50 por pagina</Option>
                    </Select>
                </div>
                <div className="flex-grow w-1/2">
                    <Input
                        label="Buscar"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                        onChange={(e) => {
                            e.preventDefault();
                            handleSearchChange(e);
                        }}
                    />
                </div>
            </div>
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-y border-blue-gray-100 bg-purple-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList && dataList.map(
                            (
                                {
                                    id,
                                    nombre_usuario,
                                    fecha_ingreso,
                                    total,
                                    estado
                                },
                                index,
                            ) => {
                                const isLast = index === dataList.length - 1;
                                const classes = isLast
                                    ? "p-4"
                                    : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={nombre_usuario}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold"
                                                >
                                                    {id}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold"
                                                >
                                                    {nombre_usuario}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {formatDate(fecha_ingreso)}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                ${total}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="w-max">
                                                <Chip
                                                    variant="ghost"
                                                    size="sm"
                                                    value={estado}
                                                    color={estado === 'PENDIENTE' ? "orange" : estado === 'EN PROCESO' ? 'blue' : estado === 'TERMINADO' ? 'green' : estado === 'CANCELADO' ? "red" : "black"}
                                                />
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Tooltip content="Ver pedido">
                                                <Button
                                                    className="bg-blue-500 inline-flex items-center justify-center p-2 border rounded-lg"
                                                    size="sm"
                                                    onClick={() => { setOpenPedidoForm(true); setTipoForm('Editar'); setIdPedido(id) }}
                                                >
                                                    <EyeIcon className="h-5 w-5" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip content="Cambiar estado">
                                                <Button
                                                    className="bg-blue-500"
                                                    size="sm"
                                                    onClick={() => handleOpenChangeState(id)}
                                                >
                                                    Cambiar Estado
                                                </Button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Button variant="outlined" size="sm" disabled={page === 1} onClick={() => handlePageChange(page - 1)}>
                    Anterior
                </Button>
                <div className="flex items-center gap-2">
                    {Array.from({ length: pages }, (_, index) => index + 1).map((pageNumber) => (
                        <IconButton
                            key={pageNumber}
                            variant={pageNumber === page ? "outlined" : "text"}
                            size="sm"
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </IconButton>
                    ))}
                </div>
                <Button variant="outlined" size="sm" disabled={page === pages} onClick={() => handlePageChange(page + 1)}>
                    Siguiente
                </Button>
            </CardFooter>
            {openOrdenesForm ? <PedidosForm open={openOrdenesForm} setOpen={setOpenPedidoForm} setOkCreate={setOkCreate} setMensaje={setMensaje} id_pedido={idPedido} /> : null}
            {openDeleteCategoria && <ModalActivarDesactivar open={openDeleteCategoria} setOpen={setOpenDeleteCategoria} cabecera='Desactivar pedido' cuerpo='¿Desea desactivar este pedido?' confirmacion='Aceptar' salida='Cancelar' color='red' tipoItem='categoria' />}
            {okCreate ? <AlertCustom hexBorder={'#2ec946'} hexBG={'#2ec946'} hexText={'#2ec946'} mensaje={mensaje} /> : null}
        </Card>
    );
}