import React from 'react'
import { Navigate } from 'react-router-dom';

import { isAuthenticated } from './index';


//private route, only show for authenticated users
const PrivateRoute = () => {
    const auth = isAuthenticated(); // determine if authorized, from context or however you're doing it
    if (auth) {
        return <Navigate to="/home" />;
      } else {
        return <Navigate to="/home" replace={true} />;
      }
}
export default PrivateRoute;