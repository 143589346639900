export const PRODUCTOS_LIST_REQUEST = "PRODUCTOS_LIST_REQUEST";
export const PRODUCTOS_LIST_SUCCESS = "PRODUCTOS_LIST_SUCCESS";
export const PRODUCTOS_LIST_FAIL = "PRODUCTOS_LIST_FAIL";
export const PRODUCTOS_LIST_RESET = "PRODUCTOS_LIST_RESET";

export const PRODUCTOS_CREATE_REQUEST = "PRODUCTOS_CREATE_REQUEST";
export const PRODUCTOS_CREATE_SUCCESS = "PRODUCTOS_CREATE_SUCCESS";
export const PRODUCTOS_CREATE_FAIL = "PRODUCTOS_CREATE_FAIL";

export const PRODUCTOS_DETAILS_REQUEST = "PRODUCTOS_DETAILS_REQUEST";
export const PRODUCTOS_DETAILS_SUCCESS = "PRODUCTOS_DETAILS_SUCCESS";
export const PRODUCTOS_DETAILS_FAIL = "PRODUCTOS_DETAILS_FAIL";
export const PRODUCTOS_DETAILS_RESET = "PRODUCTOS_DETAILS_RESET";

export const PRODUCTOS_DETAILS_NT_REQUEST = "PRODUCTOS_DETAILS_NT_REQUEST";
export const PRODUCTOS_DETAILS_NT_SUCCESS = "PRODUCTOS_DETAILS_NT_SUCCESS";
export const PRODUCTOS_DETAILS_NT_FAIL = "PRODUCTOS_DETAILS_NT_FAIL";
export const PRODUCTOS_DETAILS_NT_RESET = "PRODUCTOS_DETAILS_NT_RESET";

export const PRODUCTOS_UPDATE_REQUEST = "PRODUCTOS_UPDATE_REQUEST";
export const PRODUCTOS_UPDATE_SUCCESS = "PRODUCTOS_UPDATE_SUCCESS";
export const PRODUCTOS_UPDATE_FAIL = "PRODUCTOS_UPDATE_FAIL";
export const PRODUCTOS_UPDATE_RESET = "PRODUCTOS_UPDATE_RESET";

export const PRODUCTOS_DELETE_REQUEST = "PRODUCTOS_DELETE_REQUEST";
export const PRODUCTOS_DELETE_SUCCESS = "PRODUCTOS_DELETE_SUCCESS";
export const PRODUCTOS_DELETE_FAIL = "PRODUCTOS_DELETE_FAIL";
export const PRODUCTOS_DELETE_RESET = "PRODUCTOS_DELETE_RESET";

export const PRODUCTOS_ACTIVATE_REQUEST = "PRODUCTOS_ACTIVATE_REQUEST";
export const PRODUCTOS_ACTIVATE_SUCCESS = "PRODUCTOS_ACTIVATE_SUCCESS";
export const PRODUCTOS_ACTIVATE_FAIL = "PRODUCTOS_ACTIVATE_FAIL";
export const PRODUCTOS_ACTIVATE_RESET = "PRODUCTOS_ACTIVATE_RESET";

export const PRODUCTOS_INFO_CART_REQUEST = "PRODUCTOS_INFO_CART_REQUEST";
export const PRODUCTOS_INFO_CART_SUCCESS = "PRODUCTOS_INFO_CART_SUCCESS";
export const PRODUCTOS_INFO_CART_FAIL = "PRODUCTOS_INFO_CART_FAIL";
export const PRODUCTOS_INFO_CART_RESET = "PRODUCTOS_INFO_CART_RESET";