import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Textarea,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { listContactoDetails } from "../../../actions/contacto/contactoActions";

export function ContactoForm({ open, setOpen, setOkCreate, setMensaje, id_contacto }) {
    const dispatch = useDispatch();
    const handleOpen = () => setOpen((cur) => !cur);
    const [dataContacto, setDataContacto] = useState(null);

    useEffect(() => {
        if (id_contacto)
            dispatch(listContactoDetails(id_contacto))
    }, [dispatch, id_contacto]);

    const { contacto } = useSelector((state) => state.contactoDetailsR);

    useEffect(() => {
        if (contacto?.result) {
            setDataContacto(contacto?.result[0]);
        }
    }, [contacto]);

    return (
        <Dialog
            open={open}
            handler={handleOpen}
            className="bg-transparent shadow-none flex items-center justify-center"
        >
            <Card className="w-full max-w-[40rem] mx-auto my-4">
                <CardBody className="flex flex-col gap-4">
                    <Typography variant="h4" color="blue-gray" className="text-center">
                        Datos del mensaje de contacto
                    </Typography>
                    <form className="flex flex-col space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <Typography
                                    variant="small"
                                    className="mb-2 text-left font-medium !text-purple-900"
                                >
                                    Nombres
                                </Typography>
                                <Input
                                    color="purple"
                                    size="lg"
                                    placeholder="Nombres"
                                    name="first-name"
                                    className="focus:border-t-purple-900"
                                    containerProps={{
                                        className: "min-w-full",
                                    }}
                                    value={dataContacto?.nombres || ""}
                                    disabled
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                />
                            </div>
                            <div>
                                <Typography
                                    variant="small"
                                    className="mb-2 text-left font-medium !text-purple-900"
                                >
                                    Apellidos
                                </Typography>
                                <Input
                                    color="purple"
                                    size="lg"
                                    placeholder="Apellidos"
                                    name="last-name"
                                    className="focus:border-t-purple-900"
                                    containerProps={{
                                        className: "!min-w-full",
                                    }}
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    value={dataContacto?.apellidos || ""}
                                    disabled
                                />
                            </div>
                        </div>
                        <div>
                            <Typography
                                variant="small"
                                className="mb-2 text-left font-medium !text-purple-900"
                            >
                                Tu correo
                            </Typography>
                            <Input
                                color="purple"
                                size="lg"
                                placeholder="nombre@email.com"
                                name="email"
                                className="focus:border-t-purple-900"
                                containerProps={{
                                    className: "!min-w-full",
                                }}
                                labelProps={{
                                    className: "hidden",
                                }}
                                value={dataContacto?.correo || ""}
                                disabled
                            />
                        </div>
                        <div>
                            <Typography
                                variant="small"
                                className="mb-2 text-left font-medium !text-purple-900"
                            >
                                Tu mensaje
                            </Typography>
                            <Textarea
                                rows={6}
                                color="purple"
                                placeholder="Mensaje"
                                name="message"
                                className="focus:border-t-purple-900"
                                containerProps={{
                                    className: "!min-w-full",
                                }}
                                labelProps={{
                                    className: "hidden",
                                }}
                                value={dataContacto?.mensaje || ""}
                                disabled
                            />
                        </div>
                    </form>
                </CardBody>
                <CardFooter className="pt-0">
                    <div className="flex justify-end mt-8">
                        <Button
                            className="bg-[#646464] w-full md:w-auto"
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(false);
                            }}
                        >
                            Cerrar
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </Dialog>
    );
}
