import axios from "axios";
import {
  PRODUCTOS_CREATE_FAIL,
  PRODUCTOS_CREATE_REQUEST,
  PRODUCTOS_CREATE_SUCCESS,
  PRODUCTOS_DELETE_FAIL,
  PRODUCTOS_DELETE_REQUEST,
  PRODUCTOS_DELETE_SUCCESS,
  PRODUCTOS_DETAILS_FAIL,
  PRODUCTOS_DETAILS_REQUEST,
  PRODUCTOS_DETAILS_RESET,
  PRODUCTOS_DETAILS_SUCCESS,
  PRODUCTOS_DETAILS_NT_REQUEST,
  PRODUCTOS_DETAILS_NT_SUCCESS,
  PRODUCTOS_DETAILS_NT_FAIL,
  PRODUCTOS_LIST_FAIL,
  PRODUCTOS_LIST_REQUEST,
  PRODUCTOS_LIST_SUCCESS,
  PRODUCTOS_UPDATE_FAIL,
  PRODUCTOS_UPDATE_REQUEST,
  PRODUCTOS_UPDATE_SUCCESS,
  PRODUCTOS_ACTIVATE_REQUEST,
  PRODUCTOS_ACTIVATE_SUCCESS,
  PRODUCTOS_ACTIVATE_FAIL,
  PRODUCTOS_INFO_CART_REQUEST,
  PRODUCTOS_INFO_CART_SUCCESS,
  PRODUCTOS_INFO_CART_FAIL,
  PRODUCTOS_INFO_CART_RESET,
} from "../../constantes/productosConstants";
import {
  COMBO_PRODUCTOS_LIST_FAIL,
  COMBO_PRODUCTOS_LIST_REQUEST,
  COMBO_PRODUCTOS_LIST_SUCCESS
} from "../../constantes/comboProductosConstants"
import {
  PREDICCION_PRODUCTOS_LIST_REQUEST,
  PREDICCION_PRODUCTOS_LIST_SUCCESS,
  PREDICCION_PRODUCTOS_LIST_FAIL
} from "../../constantes/dataPrediccionConstants"
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import { URL_ML } from "../../constantes/mlUrl";
import Swal from "sweetalert2";
import { AlertNoti } from "../../componentes/AlertNoti";

export const productosListaRegistros = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/productos/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}&categoria=${parameters.id_categoria}`,
      config
    );
    dispatch({
      type: PRODUCTOS_LIST_SUCCESS,
      payload: data,
    });

  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: PRODUCTOS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProductos = (productos) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      URL_SERVER + "/productos/crear",
      productos?.data,
      config
    );
    dispatch({
      type: PRODUCTOS_CREATE_SUCCESS,
      payload: data,
    });
    productos.setOkCreate(true);
    productos.setMensaje(data?.mensaje || 'Ingresado correctamente');
    dispatch(productosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Producto creado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al crear producto' })
    dispatch({
      type: PRODUCTOS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    productos.setOkCreate(false);
    productos.setMensaje('Error al crear');
  }
};

export const deleteProductos = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    const data = await axios.delete(URL_SERVER + `/productos/eliminar/${id}`, config);
    dispatch({
      type: PRODUCTOS_DELETE_SUCCESS,
    });
    dispatch(productosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Producto inactivado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al eliminar producto' })
    dispatch({
      type: PRODUCTOS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const activeProductos = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_ACTIVATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    const data = await axios.put(URL_SERVER + `/productos/activar/${id}`, id, config);
    dispatch({
      type: PRODUCTOS_ACTIVATE_SUCCESS,
    });
    dispatch(productosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Producto activado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al activar producto' })
    dispatch({
      type: PRODUCTOS_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateProductos =
  (productos) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCTOS_UPDATE_REQUEST,
      });

      //get user from state
      const {
        userLogin: { userInfo },
      } = getState();
      //headers
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: userInfo.token,
        },
      };
      //update product
      const { data } = await axios.put(
        URL_SERVER + `/productos/actualizar`,
        productos?.data,
        config
      );
      dispatch({
        type: PRODUCTOS_UPDATE_SUCCESS,
        payload: data,
      });
      productos?.setOkCreate(true);
      productos?.setMensaje(data?.mensaje || 'Ingresado correctamente');
      dispatch(productosListaRegistros({
        valorBusqueda: '',
        page: 1,
        limitPage: 10,
      }));
      AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Producto actualizado' })
    } catch (error) {
      AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al actualizar producto' })
      dispatch({
        type: PRODUCTOS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      productos?.setOkCreate(false);
      productos?.setMensaje('Error al crear');
    }
  };
export const listProductosDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCTOS_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/productos/obtener/${id}`, config);
    dispatch({
      type: PRODUCTOS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCTOS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listProductosDetailsNoToken = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCTOS_DETAILS_NT_REQUEST });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/productos/detail/${id}`, config);
    dispatch({
      type: PRODUCTOS_DETAILS_NT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCTOS_DETAILS_NT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const comboProductos = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMBO_PRODUCTOS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/productos/combo`,
      config
    );
    dispatch({
      type: COMBO_PRODUCTOS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMBO_PRODUCTOS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCTOS_DETAILS_RESET });
};
export const clearDataInfoForCart = () => async (dispatch, getState) => {
  dispatch({ type: PRODUCTOS_INFO_CART_RESET });
};
export const productosInfoForCarousel = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_INFO_CART_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/productos/infoforcarousel?valorBusqueda=&page=1&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: PRODUCTOS_INFO_CART_SUCCESS,
      payload: data,
    });

  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: PRODUCTOS_INFO_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const productosInfoForCart = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCTOS_INFO_CART_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/productos/infoforcart?valorBusqueda=&page=1&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: PRODUCTOS_INFO_CART_SUCCESS,
      payload: data,
    });

  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: PRODUCTOS_INFO_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getImg = (parameters) => async (dispatch, getState) => {
  try {
    const imageUrl = `${URL_SERVER}/images_p/${parameters.img_name}`;
    parameters.setImagesPath(prev => ({ ...prev, [parameters.id]: imageUrl }));
  } catch (error) {
    console.log(error);
  }
};
export const prediccionML = (id_prediccion, cantidad_meses) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PREDICCION_PRODUCTOS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_ML +
      `/prediccion?id_prediccion=${id_prediccion}&months=${cantidad_meses}`,
      config
    );
    dispatch({
      type: PREDICCION_PRODUCTOS_LIST_SUCCESS,
      payload: data,
    });
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Prediccion terminada' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al predecir' })
    dispatch({
      type: PREDICCION_PRODUCTOS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};