import axios from "axios";
import {
  PEDIDOS_CREATE_FAIL,
  PEDIDOS_CREATE_REQUEST,
  PEDIDOS_CREATE_SUCCESS,
  PEDIDOS_DELETE_FAIL,
  PEDIDOS_DELETE_REQUEST,
  PEDIDOS_DELETE_SUCCESS,
  PEDIDOS_DETAILS_FAIL,
  PEDIDOS_DETAILS_REQUEST,
  PEDIDOS_DETAILS_RESET,
  PEDIDOS_DETAILS_SUCCESS,
  PEDIDOS_LIST_FAIL,
  PEDIDOS_LIST_REQUEST,
  PEDIDOS_LIST_SUCCESS,
  PEDIDOS_UPDATE_FAIL,
  PEDIDOS_UPDATE_REQUEST,
  PEDIDOS_UPDATE_SUCCESS,
  PEDIDOS_ACTIVATE_REQUEST,
  PEDIDOS_ACTIVATE_SUCCESS,
  PEDIDOS_ACTIVATE_FAIL,
  PEDIDOS_INFOUSER_REQUEST,
  PEDIDOS_INFOUSER_SUCCESS,
  PEDIDOS_INFOUSER_FAIL,
} from "../../constantes/pedidosConstants";
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import { listCarritoDetails } from "../carrito/carritoActions";
import Swal from "sweetalert2";
import { AlertNoti } from "../../componentes/AlertNoti";

export const pedidosListaRegistros = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PEDIDOS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/pedidos/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: PEDIDOS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response);
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: PEDIDOS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPedido = (pedido) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PEDIDOS_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        token: userInfo.token,
      },
    };
    //create pedido
    const { data } = await axios.post(
      URL_SERVER + "/pedidos/crear",
      pedido,
      config
    );
    dispatch({
      type: PEDIDOS_CREATE_SUCCESS,
      payload: data,
    });

    dispatch(listCarritoDetails({
      valorBusqueda: '',
      page: 1,
      limitPage: 1000
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Pedido realizado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'erro', title: 'Error al crear pedido' })
    dispatch({
      type: PEDIDOS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

  }
};

export const updatePedido = (categoria, setOkCreate, setMensaje) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PEDIDOS_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    //update product
    const { data } = await axios.put(
      URL_SERVER + `/pedidos/${categoria.id}`,
      categoria,
      config
    );
    dispatch({
      type: PEDIDOS_UPDATE_SUCCESS,
      payload: data,
    });
    setOkCreate(true);
    setMensaje(data?.mensaje || 'Ingresado correctamente');
    dispatch(pedidosListaRegistros({
      keyword: categoria.valorBusqueda,
      pageNumber: categoria.page,
      pageSize: categoria.limitPage,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Pedido actualizado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al actualizar pedido' })
    dispatch({
      type: PEDIDOS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    setOkCreate(false);
    setMensaje('Error al crear');
  }
};
export const actualizarEstadoPedido = (pedido) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PEDIDOS_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    //update product
    const { data } = await axios.put(
      URL_SERVER + `/pedidos/cambiarEstado`,
      pedido,
      config
    );
    dispatch({
      type: PEDIDOS_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(pedidosListaRegistros({
      valorBusqueda: pedido.valorBusqueda,
      page: pedido.page,
      limitPage: pedido.limitPage,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Pedido actualizado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al actualizar pedido' })
    dispatch({
      type: PEDIDOS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listPedidoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PEDIDOS_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };

    const { data } = await axios.get(URL_SERVER + `/pedidos/obtener/${id}`, config);
    dispatch({
      type: PEDIDOS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PEDIDOS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listPedidosInfoUser = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({ type: PEDIDOS_INFOUSER_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };

    const { data } = await axios.get(URL_SERVER + `/pedidos/pedidos_infouser?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`, config);
    dispatch({
      type: PEDIDOS_INFOUSER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PEDIDOS_INFOUSER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: PEDIDOS_DETAILS_RESET });
};