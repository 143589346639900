import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { cambairClave } from "../../actions/autenticacion/loginActions";
import { validarCadenaConLongitud, validarEmail, validarPassword } from "../../funfiones/validaciones";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export function CambiarPassword({ }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const [oldPass, setOldPass] = React.useState(null);
  const [newPass, setNewPass] = React.useState(null);
  const [repeatNewPass, setRepeatNewPass] = React.useState(null);

  const [avisoOldPass, setAvisoOldPass] = React.useState(' ');
  const [avisoNewPass, setAvisoNewPass] = React.useState(' ');
  const [avisoRepeatNewPass, setAvisoRepeatNewPass] = React.useState(' ');

  const cambiarClave = (credenciales) => dispatch(cambairClave(credenciales));

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        if (userInfo?.perfil == 31) {
          return
        }
        else
          navigate("/home");
      }
    }
  }, [userInfo, error]);
  function validarOldContasena(e) {
    if (!validarCadenaConLongitud(e.target.value, 6, 16)) {
      setAvisoOldPass('La longitud de la contraseña debe ser de 6 a 16 caracteres')
    } else {
      setAvisoOldPass('')
    }
    setOldPass(e.target.value);
  }
  function validarNewContasena(e) {
    if (validarCadenaConLongitud(e.target.value, 6, 16) && e.target.value === newPass) {
      setAvisoNewPass('')
      setAvisoRepeatNewPass('')
    } else if (e.target.value !== newPass) {
      setAvisoNewPass('Las contraseñas no coinciden')
    }
    else {
      setAvisoNewPass('La longitud de la contraseña debe ser de 6 a 16 caracteres')
    }
    setNewPass(e.target.value);
  }
  function validarRepatNewContasena(e) {
    if (e.target.value === newPass) {
      setAvisoRepeatNewPass('')
      setAvisoNewPass('')
    } else {
      setAvisoRepeatNewPass('Las contraseñas no coinciden')
    }
    setRepeatNewPass(e.target.value);
  }
  return (
    <>
      <div className="py-12 md:py-16 lg:py-20">
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="blue-gray">
              Cambiar contraseña
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Utilizar el siguiente formulario para actualizar tu contraseña
            </Typography>
            <Typography className="-mb-2" variant="h6">
              Contraseña actual
            </Typography>
            <Input autoComplete="new-password" value={oldPass} size="lg" type="password" onChange={validarOldContasena} />
            <Typography color='red'>{avisoOldPass}</Typography>
            <Typography className="-mb-2" variant="h6">
              Nueva contraseña
            </Typography>
            <Input autoComplete="new-password" value={newPass} size="lg" type="password" onChange={validarNewContasena} />
            <Typography color='red'>{avisoNewPass}</Typography>
            <Typography className="-mb-2" variant="h6">
              Introduce tu nueva contraseña otra vez
            </Typography>
            <Input autoComplete="new-password" value={repeatNewPass} size="lg" type="password" onChange={validarRepatNewContasena} />
            <Typography color='red'>{avisoRepeatNewPass}</Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <Button className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
              onClick={(e) => {
                e.preventDefault();
                cambiarClave({
                  oldPass,
                  newPass,
                  repeatNewPass
                });
              }} fullWidth>
              Guardar cambios
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}