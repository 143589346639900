import React, { useState } from "react";

import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";

const ModalGenerico = (props) => {
    //const [showModal, setShowModal] = useState(true);
    const { cabecera, cuerpo, showModal, setShowModal }=props;
    const handleOpen = () => setShowModal((cur) => !cur);
    return (
        <>
            <Dialog size="sm" open={showModal} handler={handleOpen}>
                <DialogHeader toggler={handleOpen}>
                    {cabecera}
                </DialogHeader>
                <DialogBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {cuerpo}
                    </p>
                </DialogBody>
            </Dialog>
        </>
    );
}

export default ModalGenerico;