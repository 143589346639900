import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cambairClave, cambioClavePorRecuperacion, validToken } from '../../actions/autenticacion/loginActions';
import { useDispatch } from "react-redux";
import "./PasswordInput.css";
import { Button, Input, Typography } from '@material-tailwind/react';

const RestaurarClaveScreen = () => {
  const { token } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokenValid, setTokenValid] = useState(false);
  const [msgErrorContra, setMsgErrorContra] = useState(" ")
  const [password, setPassword] = useState('');
  const [passwordConfi, setPasswordConfi] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  useEffect(() => {
    dispatch(validToken({ token, setTokenValid }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //var entrada_1 = e.target[0].value;
    //var entrada_2 = e.target[2].value;
    if ((password === passwordConfi) && (password.length >= 6 && passwordConfi.length >= 6)) {
      setMsgErrorContra("");
      dispatch(cambioClavePorRecuperacion({ clave: password, token }))
    } else if (password.length < 6 && passwordConfi.length < 6) {
      setMsgErrorContra("Mínimo 8 caracteres");
    } else {
      setMsgErrorContra("Las contraseñas no coinciden");
    }
  };

  if (!tokenValid) {
    return <div>Token inválido</div>;
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full p-10 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-800 md:text-2xl dark:text-white">
            Restaurar contraseña
          </h2>
          <form className="mt-4 space-y-4 lg:mt-4 md:space-y-5" onSubmit={handleSubmit}>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nueva contraseña</label>
            <div className="password-input-container">
              <Input
                type="password"
                placeholder="Nueva contraseña"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {/*<button
                className="password-input-toggle-button"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                />
              </button>*/}
            </div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirmar contraseña</label>
            <div className="password-input-container">
              <Input
                type="password"
                placeholder="Confirmar contraseña"
                value={passwordConfi}
                onChange={(e) => {
                  setPasswordConfi(e.target.value);
                  if (e.target.value === password) {
                    setMsgErrorContra("");
                  } else {
                    setMsgErrorContra("Las contraseñas no coinciden");
                  }

                }}
              />
              {/*<button
                className="password-input-toggle-button"
                onClick={togglePasswordVisibility1}
              >
                <FontAwesomeIcon
                  icon={showPassword1 ? faEyeSlash : faEye}
                  aria-label={showPassword1 ? "Ocultar contraseña" : "Mostrar contraseña"}
                />
              </button>*/}
            </div>
            <Typography color='red'>{msgErrorContra}</Typography>
            <Button color="purple" type="submit">Confirmar</Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default RestaurarClaveScreen;
