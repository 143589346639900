import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const ValidateToken = (props) => {
  let location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      try {
        const token = JSON.parse(localStorage.getItem("userInfo")).token;

        if (token) {
          const decodedJwt = parseJwt(token);
          if (decodedJwt.exp * 1000 < Date.now()) {
            props.logOut();
          }
        } else {
          props.logOut();
        }
      } catch (error) {
        props.logOut();
      }
    }, 1000);

  }, [location, props]);

  return;
};

export default ValidateToken;
