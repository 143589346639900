import axios from "axios";
import {
  DASHBOARD_FAIL,
  DASHBOARD_REQUEST,
  DASHBOARD_RESET,
  DASHBOARD_SUCCESS,
} from "../../constantes/dashboardConstants";
//import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";


export const dashboardData = (anio) => async (dispatch, getState) => {
  try {
    dispatch({ type: DASHBOARD_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/dashboard/${anio}`, config);
    dispatch({
      type: DASHBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DASHBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};