import React, { useEffect } from 'react';
import { CheckBadgeIcon, EyeIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Alert,
    Select,
    Option,
} from "@material-tailwind/react";
import { useState } from "react";
import { AlertCustom } from "../../../componentes/AlertCustom";
import { useDispatch, useSelector } from 'react-redux';
import { categoriasListaRegistros } from '../../../actions/categorias/categoriasActions';
import { useNavigate } from 'react-router-dom';
import { carritoListaRegistros } from '../../../actions/carrito/carritoActions';
import ModalActivarDesactivar from '../../../componentes/ModalActivarDesactivar';
import { UsuariosForm } from './UsuariosForm';
import { usuariosListaRegistros } from '../../../actions/usuarios/usuarioActions';
import { getExcel } from '../../../actions/reportes/reportesActions';

const TABLE_HEAD = ["Codigo", "Nombre", "Apellido", "Celular", "Correo", "Direccion", "Observacion", "Estado", "Acciones"];


export function UsuariosList() {
    const navigate = useNavigate();
    const useData = useSelector((state) => state.userLogin || null);
    const [openDeleteUsuario, setOpenDeleteUsuario] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);
    const [tipoForm, setTipoForm] = useState('Crear');
    const [activar_eliminar, setActivarEliminar] = useState(null);
    const { error, userInfo } = useData;

    useEffect(() => {
        if (!error) {
            if (userInfo) {
                if (userInfo?.perfil == 31)
                    navigate("/home");
            } else if ([16, 22].includes(userInfo?.perfil)) {
                return
            }
        }
    }, []);

    const dispatch = useDispatch();

    const [openUsuarioForm, setOpenUsuarioForm] = useState(false);
    const [okCreate, setOkCreate] = React.useState(false);
    const [mensaje, setMensaje] = React.useState("");

    const [page, setPage] = React.useState(1);
    const [limitPage, setLimitPage] = React.useState('10');
    const [valorBusqueda, setValorBusqueda] = React.useState('');

    const [dataList, setDataList] = React.useState([]);

    const { loading, usuarios, pages, page: newPages } = useSelector((state) => state.usuariosListR);

    const handleOpenUsuarioForm = () => {
        setTipoForm('Crear');
        setOpenUsuarioForm(!openUsuarioForm);
    };
    const handlePageSizeChange = (e) => {
        setLimitPage(String(e));
        setPage(1);
    };
    const handleSearchChange = (e) => {
        setValorBusqueda(e.target.value || '');
    };
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    const getReport = async () => {
        dispatch(getExcel({
            value: Number(6),
            name: String('Reporte mensajes de contacto'),
        }));
    }
    useEffect(() => {
        if (okCreate) {
            setOpenUsuarioForm(!openUsuarioForm)
            setTimeout(() => {
                setOkCreate(false);
            }, 2000);
        }
    }, [okCreate]);

    useEffect(() => {
        dispatch(usuariosListaRegistros({ page, limitPage, valorBusqueda, navigate }))
    }, [dispatch, page, limitPage, valorBusqueda])

    useEffect(() => {
        if (usuarios && loading === false) {
            setDataList(usuarios);
        }
    }, [usuarios, loading])
    useEffect(() => {
        dispatch(usuariosListaRegistros({ page, limitPage, valorBusqueda, navigate }))
    }, [valorBusqueda])
    return (



        <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                            Usuarios
                        </Typography>
                    </div>
                    <div className="flex w-full shrink-0 gap-2 md:w-max relative">
                        <Button className="flex items-center gap-3 bg-purple-500" size="sm"onClick={getReport}>
                            <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Desacargar reporte
                        </Button>
                        <Button className="flex items-center gap-3 bg-purple-500" size="sm" onClick={handleOpenUsuarioForm}>
                            <PlusIcon strokeWidth={2} className="h-4 w-4" /> Agregar Usuario
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <div className='flex w-full gap-2 mb-[1%] mt-[1%] items-center'>
                <div className="flex-shrink-0">
                    <Select value={limitPage} onChange={handlePageSizeChange}>
                        <Option key={1} value={'10'}>10 por pagina</Option>
                        <Option key={1} value={'30'}>30 por pagina</Option>
                        <Option key={1} value={'50'}>50 por pagina</Option>
                    </Select>
                </div>
                <div className="flex-grow w-1/2">
                    <Input
                        label="Buscar"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                        onChange={(e) => {
                            e.preventDefault();
                            handleSearchChange(e);
                        }}
                    />
                </div>
            </div>
            <CardBody className="overflow-scroll px-0">

                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-y border-blue-gray-100 bg-purple-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList && dataList.map(
                            (
                                {
                                    id,
                                    nombre,
                                    apellido,
                                    celular,
                                    correo,
                                    direccion,
                                    observacion,
                                    estado,
                                },
                                index,
                            ) => {
                                const isLast = index === dataList.length - 1;
                                const classes = isLast
                                    ? "p-4"
                                    : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={id}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold"
                                                >
                                                    {id}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {nombre}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {apellido}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {celular}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {correo}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {direccion}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {observacion}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="w-max">
                                                <Chip
                                                    variant="ghost"
                                                    size="sm"
                                                    //value={online ? "online" : "offline"}
                                                    value={estado}
                                                    color={estado === 'ACTIVO' ? "green" : "red"}
                                                />
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Tooltip content="Editar usuario">
                                                <Button
                                                    className="inline-flex items-center justify-center p-2 border rounded-lg bg-blue-500 mx-1.5"
                                                    size="sm"
                                                    onClick={() => { setOpenUsuarioForm(true); setTipoForm('Editar'); setIdUsuario(id) }}
                                                >
                                                    <PencilIcon className="h-5 w-5" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip content={estado === 'ACTIVO' ? "Eliminar usuario" : "Activar usuario"}>
                                                <Button className={`inline-flex items-center justify-center p-2 border rounded-lg bg-${estado === 'ACTIVO' ? `red` : `blue`}-500`} size="sm"
                                                    onClick={() => { setOpenDeleteUsuario(true); setIdUsuario(id); setActivarEliminar(estado) }}>
                                                    {estado === 'ACTIVO' ? <TrashIcon className="h-5 w-5" /> : <CheckBadgeIcon className="h-5 w-5" />}
                                                </Button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Button variant="outlined" size="sm" disabled={page === 1} onClick={() => handlePageChange(page - 1)}>
                    Anterior
                </Button>
                <div className="flex items-center gap-2">
                    {Array.from({ length: pages }, (_, index) => index + 1).map((pageNumber) => (
                        <IconButton
                            key={pageNumber}
                            variant={pageNumber === page ? "outlined" : "text"}
                            size="sm"
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </IconButton>
                    ))}
                </div>
                <Button variant="outlined" size="sm" disabled={page === pages} onClick={() => handlePageChange(page + 1)}>
                    Siguiente
                </Button>
            </CardFooter>
            {openUsuarioForm ? <UsuariosForm open={openUsuarioForm} setOpen={setOpenUsuarioForm} setOkCreate={setOkCreate} setMensaje={setMensaje} tipoForm={tipoForm} id_usuario={idUsuario} /> : null}
            {/*openDeleteUsuario && <ModalActivarDesactivar open={openDeleteUsuario} setOpen={setOpenDeleteUsuario} cabecera='Desactivar usuario' cuerpo='¿Desea desactivar este usuario?' confirmacion='Aceptar' salida='Cancelar' color='red' tipoItem='usuario' />*/}
            {openDeleteUsuario && <ModalActivarDesactivar id={idUsuario} activar_eliminar={activar_eliminar} open={openDeleteUsuario} setOpen={setOpenDeleteUsuario}
                cabecera={activar_eliminar === 'ACTIVO' ? 'Desactivar usuario' : 'Activar usuario'}
                cuerpo={activar_eliminar === 'ACTIVO' ? '¿Desea desactivar este usuario?' : '¿Desea activar este usuario?'}
                confirmacion='Aceptar' salida='Cancelar' color={activar_eliminar === 'ACTIVO' ? 'red' : 'green'} tipoItem='usuario' />}
            {okCreate ? <AlertCustom hexBorder={'#2ec946'} hexBG={'#2ec946'} hexText={'#2ec946'} mensaje={mensaje} /> : null}
        </Card>
    );
}