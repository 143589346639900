import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Select,
    Option,
} from "@material-tailwind/react";
import { validarCadenaConLongitud, validarEmail } from "../../../funfiones/validaciones";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUsuario, updateUsuario, listComboPerfiles, listUsuarioDetails } from "../../../actions/usuarios/usuarioActions";
import { AlertCustom } from "../../../componentes/AlertCustom";

export function UsuariosForm({ open, setOpen, setOkCreate, setMensaje, tipoForm, id_usuario }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpen = () => setOpen((cur) => !cur);

    const [nombre, setNombre] = React.useState("");
    const [apellido, setApellido] = React.useState("");
    const [celular, setCelular] = React.useState("");
    const [correo, setCorreo] = React.useState("");
    const [observacion, setObservacion] = React.useState("");
    const [id_perfil, setIdPerfil] = React.useState(null);

    const [avisoNombre, setAvisoNombre] = React.useState(" ");
    const [avisoApellido, setAvisoApellido] = React.useState(" ");
    const [avisoCelular, setAvisoCelular] = React.useState(" ");
    const [avisoCorreo, setAvisoCorreo] = React.useState(" ");
    const [avisoObservacion, setAvisoObservacion] = React.useState(" ");
    const [avisoId_perfil, setAvisoIdPerfil] = React.useState(' ');

    const [perfilComboList, setPerfilComboList] = React.useState([]);

    const guardarUsuario = (credenciales) => dispatch(createUsuario(credenciales));
    const actualizarUsuario = (credenciales) => dispatch(updateUsuario(credenciales));
    const comboPerfiles = useSelector((state) => state.comboPerfilesListR);
    const usuarioDataEdit = useSelector((state) => state.usuariosDetailsR);

    useEffect(() => {
        dispatch(listComboPerfiles({ navigate }));
        if (tipoForm === 'Editar') {
            dispatch(listUsuarioDetails(id_usuario));
        } else {
            setNombre('');
            setApellido('');
            setCelular('');
            setCorreo('');
            setObservacion('');
            setIdPerfil('');
        }
    }, [dispatch, tipoForm, id_usuario, navigate]);

    useEffect(() => {
        if (comboPerfiles) {
            setPerfilComboList(comboPerfiles?.combo_perfiles);
        }
    }, [comboPerfiles]);

    useEffect(() => {
        if (usuarioDataEdit?.usuarios?.result && id_usuario && tipoForm === 'Editar') {
            setNombre(usuarioDataEdit?.usuarios?.result[0]?.nombre || '');
            setApellido(usuarioDataEdit?.usuarios?.result[0]?.apellido || '');
            setCelular(usuarioDataEdit?.usuarios?.result[0]?.celular || '');
            setCorreo(usuarioDataEdit?.usuarios?.result[0]?.correo || '');
            setObservacion(usuarioDataEdit?.usuarios?.result[0]?.observacion || '');
            setIdPerfil(usuarioDataEdit?.usuarios?.result[0]?.id_perfil || '');
        }
    }, [usuarioDataEdit, id_usuario, tipoForm]);

    function validarNombre(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 100)) {
            setAvisoNombre("");
        } else {
            setAvisoNombre('Campo invalido/incorrecto');
        }
        setNombre(e.target.value);
    }

    function validarApellido(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 100)) {
            setAvisoApellido("");
        } else {
            setAvisoApellido('Campo invalido/incorrecto');
        }
        setApellido(e.target.value);
    }

    function validarCelular(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 13)) {
            setAvisoCelular("");
        } else {
            setAvisoCelular('Campo invalido/incorrecto');
        }
        setCelular(e.target.value);
    }

    function validarCorreo(e) {
        if (validarEmail(e.target.value)) {
            setAvisoCorreo("");
        } else {
            setAvisoCorreo('Campo invalido/incorrecto');
        }
        setCorreo(e.target.value);
    }

    function validarObservacion(e) {
        setObservacion(e.target.value);
    }

    function validarPerfil(e) {
        if (e) {
            setAvisoIdPerfil("");
        } else {
            setAvisoIdPerfil('Campo invalido/incorrecto');
        }
        setIdPerfil(e);
    }

    const hanleCrearUsuario = (e) => {
        e.preventDefault();
        const data = {
            id: id_usuario || null,
            nombre: nombre,
            apellido: apellido,
            celular: celular,
            correo: correo,
            observacion: observacion,
            id_perfil: id_perfil,
        };

        tipoForm === 'Crear' ? guardarUsuario({ data: data, setOkCreate, setMensaje }) : actualizarUsuario({ data: data, setOkCreate, setMensaje });
    };

    return (
        <>
            <Dialog
                open={open}
                handler={handleOpen}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full max-w-4xl">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="purple">
                            {tipoForm === 'Crear' ? 'Nuevo' : 'Editar'} Usuario
                        </Typography>
                        <Typography className="mb-3 font-normal text-purple-300" variant="paragraph">
                            Ingrese los datos del usuario
                        </Typography>
                        <div className="flex flex-col sm:flex-row sm:flex-wrap -mx-3 mb-3">
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Input color="purple" label="Nombre" autoComplete="new-password" size="lg" value={nombre} onChange={validarNombre} />
                                <Typography color='red'>{avisoNombre}</Typography>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Input color="purple" label="Apellido" autoComplete="new-password" size="lg" value={apellido} onChange={validarApellido} />
                                <Typography color='red'>{avisoApellido}</Typography>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Input color="purple" label="Celular" autoComplete="new-password" size="lg" value={celular} maxLength={13} onChange={validarCelular} />
                                <Typography color='red'>{avisoCelular}</Typography>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Input color="purple" label="Correo" autoComplete="new-password" size="lg" value={correo} onChange={validarCorreo} />
                                <Typography color='red'>{avisoCorreo}</Typography>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Input color="purple" label="Observación" autoComplete="new-password" size="lg" value={observacion} onChange={validarObservacion} />
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Select color="purple" label="Perfil" size="lg" onChange={(e) => validarPerfil(e)}>
                                    {perfilComboList.map((element) => (
                                        <Option key={element?.id} value={String(element?.id)}>
                                            {element?.nombre}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="flex flex-col sm:flex-row sm:flex-wrap -mx-3 mb-3">
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Button className="bg-[#646464] w-full" onClick={() => setOpen(false)}>
                                    Cancelar
                                </Button>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-4">
                                <Button color="purple" className="w-full" onClick={hanleCrearUsuario}>
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Dialog>
            <AlertCustom hexBorder={'#2ec946'} hexBG={'#2ec946'} hexText={'#2ec946'} />
        </>
    );
}
