"use client"

import React from "react"
import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon,
    CogIcon,
    ArchiveBoxIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/solid";

const iconsMap = {
    PresentationChartBarIcon: PresentationChartBarIcon,
    ShoppingBagIcon: ShoppingBagIcon,
    UserCircleIcon: UserCircleIcon,
    Cog6ToothIcon: Cog6ToothIcon,
    InboxIcon: InboxIcon,
    PowerIcon: PowerIcon,
    ChevronRightIcon: ChevronRightIcon,
    ChevronDownIcon: ChevronDownIcon,
    CogIcon: CogIcon,
    ArchiveBoxIcon:ArchiveBoxIcon,
    ArchiveBoxIcon:ArchiveBoxIcon,
};

export default function CardDashboard({ titulo, valor, icono }) {
    const IconComponent = iconsMap[icono];
    return (
        <div class="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div class="p-4 bg-green-400">
            <IconComponent className="h-7 w-7" />
            </div>
            <div class="px-4 text-gray-700">
                <h3 class="text-sm tracking-wider">{titulo}</h3>
                <p class="text-3xl">{valor}</p>
            </div>
        </div>
    );
}