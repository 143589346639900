"use client"

import { useEffect, useState } from "react"
import CardDashboard from "../../../componentes/CardDashboard"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { dashboardData } from "../../../actions/dashboard/dashboardActions"
import DashboardLine from "../../../componentes/DashboardLine"

const cardTitle = [
    'Cantidad de productos',
    'Cantidad de carritos',
    'Cantidad de pedidos',
];

export default function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cant_productos, setCantProductos] = useState(null);
    const [cant_carritos, setCantCarritos] = useState(null);
    const [cant_pedidos, setCantPedidos] = useState(null);
    const [cant_ventas, setCantVentas] = useState(null);
    const [ingresos, setIngresos] = useState(null);
    const [anio, setAnio] = useState(new Date().getFullYear());
    const useData = useSelector((state) => state.userLogin || null);
    const dashboardinfo = useSelector((state) => state.dashboardDetailsR || null);
    const { error, userInfo } = useData;

    useEffect(() => {
        if (!error) {
            if (userInfo) {
                if (userInfo?.perfil == 31)
                    navigate("/home");
            } else if ([16, 22].includes(userInfo?.perfil)) {
                return
            }
        }
    }, []);

    useEffect(() => {
        if (dashboardinfo?.dashboard?.result) {
            setCantProductos(dashboardinfo?.dashboard?.result?.cant_productos)
            setCantCarritos(dashboardinfo?.dashboard?.result?.cant_carritos)
            setCantPedidos(dashboardinfo?.dashboard?.result?.cant_pedidos)
            setCantVentas(dashboardinfo?.dashboard?.result?.info_ventas)
            setIngresos(dashboardinfo?.dashboard?.result?.ingresos)
        }
    }, [dashboardinfo]);

    useEffect(() => {
        dispatch(dashboardData(anio));
    }, [anio]);

    const handleYearChange = (e) => {
        setAnio(e.target.value);
    };

    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear - 3; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    return (
        <>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-50 mb-9 mt-3">Bienvenido, {userInfo?.name} {userInfo?.lastname}</h2>
            <div className="bg-gray-100 dark:bg-gray-900">
                <main>
                    <section className="py-12 md:py-16 lg:py-20">
                        <div className="container mx-auto px-4">
                            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-50 mb-8">Dashboard</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                <CardDashboard titulo={cardTitle[0]} valor={cant_productos || 0} icono={'ArchiveBoxIcon'} />
                                <CardDashboard titulo={cardTitle[1]} valor={cant_carritos || 0} icono={'ArchiveBoxIcon'} />
                                <CardDashboard titulo={cardTitle[2]} valor={cant_pedidos || 0} icono={'ArchiveBoxIcon'} />
                            </div>
                        </div>
                    </section>
                    <div className="mb-4">
                        <label htmlFor="yearSelect" className="block text-gray-700 dark:text-gray-300">Seleccionar año:</label>
                        <select
                            id="yearSelect"
                            className="mt-2 p-2 border border-gray-300 dark:border-gray-700 rounded-md"
                            value={anio}
                            onChange={handleYearChange}
                        >
                            {generateYears().map((year) => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className=" flex">
                        <div className=" flex-auto  w-full px-1">
                            <DashboardLine salesData={cant_ventas} titulo='Cantidad de productos vendidos' descripcion='Cantidad de productos vendidos por mes del presente año' />
                        </div>
                        <div className=" flex-auto  w-full px-1">
                            <DashboardLine salesData={ingresos} titulo='Ingresos por ventas' descripcion='Cantidad de ingreso por productos vendidos por mes del presente año' icon='CurrencyDollarIcon' />
                        </div>
                    </div>
                </main>
                <footer className="bg-gray-900 text-gray-400 py-8">
                    <div className="container mx-auto px-4 flex items-center justify-between">
                        <p>&copy; 2023 Acme Store. All rights reserved.</p>
                        <nav>
                            <ul className="flex space-x-4">
                                <li>
                                    <a href="#" className="hover:text-gray-50 transition-colors" prefetch={false}>
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-gray-50 transition-colors" prefetch={false}>
                                        Shop
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-gray-50 transition-colors" prefetch={false}>
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-gray-50 transition-colors" prefetch={false}>
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </footer>
            </div>
        </>
    )
}
