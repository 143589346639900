import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { login } from "../actions/autenticacion/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

export function LoginCard({ open, setOpen, setOpenRegister, setRutaResult, setRecoverPassCard }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpen = () => setOpen((cur) => !cur);
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const [correo, setCorreo] = React.useState("");
  const [password, setPassword] = React.useState("");

  const iniciarSesion = (credenciales) => dispatch(login(credenciales));
  const [notiLogin, setNotiLogin] = useState(false);

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        setOpen(false);
        if (userInfo?.perfil == 31) {
          if (location.pathname === '/home')
            window.location.reload();
          else
            navigate("/home");
        } else if ([16, 22].includes(userInfo?.perfil)) {
          navigate("/dashboard");
          window.location.reload();
        }
      }
    }
  }, [userInfo, error]);

  function validarCorreo(e) {
    setCorreo(e.target.value);
  }

  function validarPassw(e) {
    setPassword(e.target.value);
  }

  return (
    <>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem] sm:max-w-[28rem] lg:max-w-[32rem]">
          <CardBody className="flex flex-col gap-4 max-h-[400px] overflow-y-auto">
            <Typography variant="h4" color="blue-gray">
              Iniciar sesión
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Ingresa tu correo y contraseña para iniciar sesión
            </Typography>
            <Typography className="-mb-2" variant="h6">
              Tu correo
            </Typography>
            <Input
              label="Correo"
              autoComplete={'new-password'}
              value={correo}
              size="lg"
              onChange={validarCorreo}
            />
            <Typography className="-mb-2" variant="h6">
              Tu contraseña
            </Typography>
            <Input
              label="Contraseña"
              autoComplete={'new-password'}
              value={password}
              size="lg"
              type="password"
              onChange={validarPassw}
            />
            <div className="-ml-2.5 -mt-3">
              <Typography variant="small" className="mt-4 flex justify-center">
                ¿Olvidaste tu contraseña?
                <Typography
                  as="a"
                  href="#signup"
                  variant="small"
                  color="purple"
                  className="ml-1 font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                    setRecoverPassCard(true);
                  }}
                >
                  Recuperar contraseña
                </Typography>
              </Typography>
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
              onClick={(e) => {
                e.preventDefault();
                iniciarSesion({ data: { correo, password } });
                setOpen(false);
              }}
              fullWidth
            >
              Iniciar sesión
            </Button>
            <Typography variant="small" className="mt-4 flex justify-center">
              ¿No tienes una cuenta?
              <Typography
                as="a"
                href="#signup"
                variant="small"
                color="purple"
                className="ml-1 font-bold"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                  setOpenRegister(true);
                }}
              >
                Registrarse
              </Typography>
            </Typography>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
