import React, { useEffect, useState } from "react";
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Radio, Input } from "@material-tailwind/react";
import { URL_SERVER } from "../constantes/serverUrl";
import { createPedido } from "../actions/pedidos/pedidosActions";
import { useDispatch, useSelector } from "react-redux";
import { obtenerMiID, listUsuarioDetails } from "../actions/usuarios/usuarioActions";

const ModalConfirmCompra = ({ total, showModal, setShowModal, cartItems, setTotal }) => {
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);
    const [deliveryOption, setDeliveryOption] = useState("domicilio");
    const [paymentOption, setPaymentOption] = useState("transferencia");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [idUsuario, setIdUsuario] = useState(null);
    const { loading, usuarios } = useSelector((state) => state.usuariosDetailsR || { usuarios: [] });

    // Estados de error
    const [errorMessage, setErrorMessage] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOpen = () => setShowModal((cur) => !cur);

    const handleNext = () => {
        let valid = true;

        // Resetear mensajes de error
        setNameError("");
        setEmailError("");
        setPhoneError("");
        setAddressError("");

        if (step === 2 && paymentOption === 'transferencia' && !file) {
            setErrorMessage('Debes subir el comprobante de la transferencia.');
            return; // Detiene el avance si no hay archivo
        }
        setErrorMessage(''); // Limpia el mensaje de error si el archivo está presente
        setStep(step + 1);

        if (step === 3) {
            if (!name) {
                setNameError("El nombre es requerido.");
                valid = false;
            }
            if (!email) {
                setEmailError("El email es requerido.");
                valid = false;
            }
            if (!phone) {
                setPhoneError("El teléfono es requerido.");
                valid = false;
            }
            if (deliveryOption === "domicilio" && !address) {
                setAddressError("La dirección es requerida para entrega a domicilio.");
                valid = false;
            }

            if (!valid) return;
            const formData = new FormData();
            formData.append('paymentOption', paymentOption);
            if (paymentOption === 'transferencia') {
                formData.append('file', file);
            }
            formData.append('deliveryOption', deliveryOption);
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('address', address);
            formData.append('product_list', JSON.stringify(cartItems));
            formData.append('total', (total * 1.15)?.toFixed(2) || 0);
            // Confirmación final del pedido
            dispatch(createPedido(formData));

            setShowModal(false);
            setTotal(0);
        }

        if (step < 3) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleOptionChange = (event) => {
        if (step === 1) {
            setDeliveryOption(event.target.value);
        } else if (step === 2) {
            setPaymentOption(event.target.value);
        }
    };

    const getId = () => {
        dispatch(obtenerMiID(setIdUsuario))
    }
    const getData = () => {
        dispatch(listUsuarioDetails(idUsuario))
    }

    useEffect(() => {
        getId()
    }, [])
    useEffect(() => {
        if (idUsuario !== null)
            getData()
    }, [idUsuario])
    useEffect(() => {
        if (usuarios?.result && Array.isArray(usuarios.result) && usuarios.result.length > 0) {
            setName(usuarios.result[0].nombre || "");
            setEmail(usuarios.result[0].correo || "");
            setPhone(usuarios.result[0].celular || "");
            setAddress(usuarios.result[0].direccion || "");
        }
    }, [usuarios]);

    return (
        <>
            <Dialog size="xl" open={showModal} handler={handleOpen}>
                <DialogHeader toggler={handleOpen}>
                    {step === 1 && `USD $${total}`}
                    {step === 2 && "Formas de pago"}
                    {step === 3 && "Resumen del pedido"}
                </DialogHeader>
                <DialogBody className="overflow-auto max-h-[calc(100vh-200px)] p-4">
                    {step === 1 && (
                        <div className="w-full">
                            <p className="text-base leading-relaxed text-gray-600 font-normal mb-4">
                                Selecciona una opción de entrega:
                            </p>
                            <div className="flex flex-col space-y-2">
                                <Radio
                                    id="domicilio"
                                    name="deliveryOption"
                                    label="Entrega a domicilio"
                                    value="domicilio"
                                    checked={deliveryOption === "domicilio"}
                                    onChange={handleOptionChange}
                                />
                                <p className="text-base leading-relaxed text-gray-600 font-normal mb-4 ml-9">
                                    Envios
                                </p>
                                <Radio
                                    id="retirar"
                                    name="deliveryOption"
                                    label="Retirar pedido"
                                    value="retirar"
                                    checked={deliveryOption === "retirar"}
                                    onChange={handleOptionChange}
                                />
                                <p className="text-base leading-relaxed text-gray-600 font-normal mb-4 ml-9">
                                    Dirección. Plazo: de 8:00 - 18:00
                                </p>
                            </div>
                        </div>
                    )}

                    {step === 2 && (

                        <div className="w-full">
                            <p className="text-base leading-relaxed text-gray-600 font-normal mb-4">
                                Selecciona una forma de pago:
                            </p>
                            <div className="flex flex-col space-y-2">
                                <Radio
                                    id="transferencia"
                                    name="paymentOption"
                                    label="Transferencia bancaria"
                                    value="transferencia"
                                    checked={paymentOption === "transferencia"}
                                    onChange={handleOptionChange}
                                />
                                {/* Si se selecciona transferencia bancaria, mostrar la sección de cuentas bancarias y subida de archivo */}
                                {paymentOption === "transferencia" && (
                                    <div className="w-full p-6">
                                        {/* Drag and drop area for uploading */}
                                        <div
                                            className="border-2 border-gray-300 rounded-lg p-2 mb-3 text-center"
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) => {
                                                e.preventDefault();
                                                const droppedFile = e.dataTransfer.files[0];
                                                if (droppedFile && (droppedFile.type.includes("image") || droppedFile.type === "application/pdf")) {
                                                    console.log("se subio la imagen");
                                                    setFile(droppedFile);
                                                } else {
                                                    alert("Solo se permiten archivos JPG, PNG o PDF.");
                                                }
                                            }}
                                        >
                                            <h3 className="font-bold text-lg">Arrastra tu Comprobante de Depósito Bancario Aquí</h3>
                                            <p className="text-sm text-gray-500 mb-4">(Formatos permitidos: jpg, png, pdf)</p>
                                            <input
                                                type="file"
                                                accept="image/*,application/pdf"
                                                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                                onChange={(e) => {
                                                    const selectedFile = e.target.files[0];
                                                    if (selectedFile && (selectedFile.type.includes("image") || selectedFile.type === "application/pdf")) {
                                                        setFile(selectedFile);
                                                    } else {
                                                        alert("Solo se permiten archivos JPG, PNG o PDF.");
                                                    }
                                                }}
                                            />
                                        </div>

                                        {/* Sección de cuentas bancarias */}
                                        <div className="grid grid-cols-2 gap-6">
                                            <div className="flex items-center space-x-4">
                                                <img src={`${URL_SERVER}/images_b/bancoPichincha.png`} alt="Banco Pichincha" className="w-12 h-12" />
                                                <div>
                                                    <p className="font-bold">Banco Pichincha</p>
                                                    <p>Cta. Corriente No.2204921545</p>
                                                    <p>Veronica Zambrano</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                                <Radio
                                    id="otros"
                                    name="paymentOption"
                                    label="Otros métodos de pago"
                                    value="otros"
                                    checked={paymentOption === "otros"}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="w-full flex">
                            {/* Listado de productos */}
                            <div className="w-1/2 pr-4 overflow-y-auto max-h-96">
                                <h3 className="text-lg font-semibold mb-4">Productos en tu carrito</h3>
                                <ul className="space-y-2">
                                    {cartItems.map((item, index) => (
                                        <div key={index} className="space-y-4">
                                            <div className="grid gap-4 sm:grid-cols-3 items-center">
                                                <div className="sm:col-span-2 flex items-center gap-4">
                                                    <div className="w-24 h-24 shrink-0 bg-white p-1 rounded-md">
                                                        <img
                                                            src={`${URL_SERVER}/images_p/${item.imagenpath}`}
                                                            className="w-full h-full object-contain"
                                                            alt={`Producto ${item.nombre}`}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-base font-bold text-gray-800">{item.nombre}</h3>
                                                    </div>
                                                </div>
                                                <div className="ml-auto">
                                                    <h4 className="text-lg font-bold text-gray-800">{`$${item?.total?.toFixed(2)}`}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                                <div className="mt-4 font-bold text-right">
                                    Total: USD {total}
                                </div>
                            </div>

                            {/* Formulario */}
                            <div className="w-1/2 pl-4 space-y-4">
                                <h3 className="text-lg font-semibold mb-4">Detalles del pedido</h3>
                                <p className="mb-4"><strong>Método de pago:</strong> {paymentOption}</p>
                                {deliveryOption === "retirar" && (
                                    <p className="mb-4"><strong>Retirar en:</strong> Dirección. Plazo: de 8:00 - 18:00</p>
                                )}
                                {deliveryOption === "domicilio" && (
                                    <>
                                        <p className="mb-4"><strong>Entrega a domicilio</strong></p>
                                        <Input
                                            label="Dirección de entrega"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            required
                                            className="mb-4"
                                            error={!!addressError}
                                        />
                                        {addressError && (
                                            <p className="text-red-600 text-sm">{addressError}</p>
                                        )}
                                    </>
                                )}
                                <Input
                                    label="Nombre"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    className="mb-4"
                                    error={!!nameError}
                                />
                                {nameError && (
                                    <p className="text-red-600 text-sm">{nameError}</p>
                                )}
                                <Input
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="mb-4"
                                    error={!!emailError}
                                />
                                {emailError && (
                                    <p className="text-red-600 text-sm">{emailError}</p>
                                )}
                                <Input
                                    label="Teléfono"
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                    className="mb-4"
                                    error={!!phoneError}
                                />
                                {phoneError && (
                                    <p className="text-red-600 text-sm">{phoneError}</p>
                                )}
                            </div>
                        </div>
                    )}
                </DialogBody>
                <DialogFooter>
                    {step > 1 && (
                        <Button color="gray" onClick={handleBack} className="mr-2">
                            Volver
                        </Button>
                    )}
                    <Button color="green" onClick={handleNext}>
                        {step === 3 ? "Confirmar" : "Avanzar"}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ModalConfirmCompra;
