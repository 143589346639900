import React ,{useState}from "react";

import { Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
//import { USER_LOGOUT, USER_DETAILS_RESET } from "../constants/loginConstants";
//import { useNavigate } from "react-router-dom";

const ModalSimple = () => {
    //const dispatch = useDispatch();
   // let navigate = useNavigate();
    //var {cabecera, cuerpo,salida}= 'props'
    const [showModal, setShowModal] = useState(true);
    return ( 
        <>
        <Dialog  size="sm" active={showModal} toggler={() => setShowModal(false)}>
            <DialogHeader toggler={() => setShowModal(false)}>
                {'Su Sesión Ah Expirado!!!'}
            </DialogHeader>
            <DialogBody>
                <p className="text-base leading-relaxed text-gray-600 font-normal">
                    {'Querido usuario su sesión expiro, por favor ingrese nuevamente al sistema  '}
                </p>
            </DialogBody>
            <DialogFooter>
                

            </DialogFooter>
        </Dialog>
    </>
     );
}
 
export default ModalSimple;