import {
    PRODUCTOS_LIST_RESET,
    PRODUCTOS_CREATE_FAIL,
    PRODUCTOS_DELETE_REQUEST,
    PRODUCTOS_CREATE_REQUEST,
    PRODUCTOS_CREATE_SUCCESS,
    PRODUCTOS_DELETE_FAIL,
    PRODUCTOS_DELETE_RESET,
    PRODUCTOS_DELETE_SUCCESS,
    PRODUCTOS_DETAILS_FAIL,
    PRODUCTOS_DETAILS_REQUEST,
    PRODUCTOS_DETAILS_RESET,
    PRODUCTOS_DETAILS_SUCCESS,
    PRODUCTOS_DETAILS_NT_FAIL,
    PRODUCTOS_DETAILS_NT_REQUEST,
    PRODUCTOS_DETAILS_NT_RESET,
    PRODUCTOS_DETAILS_NT_SUCCESS,
    PRODUCTOS_LIST_FAIL,
    PRODUCTOS_LIST_REQUEST,
    PRODUCTOS_LIST_SUCCESS,
    PRODUCTOS_UPDATE_FAIL,
    PRODUCTOS_UPDATE_REQUEST,
    PRODUCTOS_UPDATE_RESET,
    PRODUCTOS_UPDATE_SUCCESS,
    PRODUCTOS_ACTIVATE_REQUEST,
    PRODUCTOS_ACTIVATE_SUCCESS,
    PRODUCTOS_ACTIVATE_FAIL,
    PRODUCTOS_ACTIVATE_RESET,
    PRODUCTOS_INFO_CART_REQUEST,
PRODUCTOS_INFO_CART_SUCCESS,
PRODUCTOS_INFO_CART_FAIL,
PRODUCTOS_INFO_CART_RESET,
} from '../constantes/productosConstants'
export const productosListReducer = (
    state = { loading: true, productos: [] },
    action
) => {
    switch (action.type) {
        case PRODUCTOS_LIST_REQUEST:
            return { loading: true, productos: [] };
        case PRODUCTOS_LIST_SUCCESS:
            return {
                loading: false,
                productos: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case PRODUCTOS_LIST_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_LIST_RESET:
            return { productos: [] };
        default:
            return state;
    }
};

export const productosCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCTOS_CREATE_REQUEST:
            return { loading: true };
        case PRODUCTOS_CREATE_SUCCESS:
            return { loading: false, success: true };
        case PRODUCTOS_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productosDetailsReducer = (state = { producto: {} }, action) => {
    switch (action.type) {
        case PRODUCTOS_DETAILS_REQUEST:
            return { ...state, loading: true };
        case PRODUCTOS_DETAILS_SUCCESS:
            return { loading: false, producto: action.payload };
        case PRODUCTOS_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_DETAILS_RESET:
            return { producto: {} };
        default:
            return state;
    }
};
export const productosDetailsNoTokenReducer = (state = { producto: {} }, action) => {
    switch (action.type) {
        case PRODUCTOS_DETAILS_NT_REQUEST:
            return { ...state, loading: true };
        case PRODUCTOS_DETAILS_NT_SUCCESS:
            return { loading: false, producto: action.payload };
        case PRODUCTOS_DETAILS_NT_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_DETAILS_NT_RESET:
            return { producto: {} };
        default:
            return state;
    }
};

export const productosUpdateReducer = (state = { productos: {} }, action) => {
    switch (action.type) {
        case PRODUCTOS_UPDATE_REQUEST:
            return { loading: true };
        case PRODUCTOS_UPDATE_SUCCESS:
            return { loading: false, success: true, productos: action.payload };
        case PRODUCTOS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_UPDATE_RESET:
            return { productos: {} };
        default:
            return state;
    }
};

export const productosDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCTOS_DELETE_REQUEST:
            return { loading: true};
        case PRODUCTOS_DELETE_SUCCESS:
            return { loading: false, success: true};
        case PRODUCTOS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const productosActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCTOS_ACTIVATE_REQUEST:
            return { loading: true};
        case PRODUCTOS_ACTIVATE_SUCCESS:
            return { loading: false, success: true};
        case PRODUCTOS_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_ACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};

export const productosInfoCartReducer = (
    state = { loading: true, productosInfoCart: [] },
    action
) => {
    switch (action.type) {
        case PRODUCTOS_INFO_CART_REQUEST:
            return { loading: true, productosInfoCart: [] };
        case PRODUCTOS_INFO_CART_SUCCESS:
            return {
                loading: false,
                productosInfoCart: action.payload.result,
            };
        case PRODUCTOS_INFO_CART_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCTOS_INFO_CART_RESET:
            return { productosInfoCart: [] };
        default:
            return state;
    }
};