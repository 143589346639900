import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import ValidateToken from "./auth/ValidateToken";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ModalSimple from "./componentes/ModalSimple";
import { useState } from "react";
/* SCREENS */

import './index.css';
import Dashboard from "./vistas/admin/dashboard/Dashboard";
import { ProductList } from "./vistas/admin/productos/ProductosList";
import { CategoriasList } from "./vistas/admin/categorias/CategoriasList";
import { CarritosList } from "./vistas/admin/carritos/CarritosList";
import { OrdenesList } from "./vistas/admin/ordenes/OrdenesList";
import { UsuariosList } from "./vistas/admin/usuarios/UsuariosList";
import { ContactoList } from "./vistas/admin/contacto/ContactoList";
//import ProductList from "./vistas/admin/productos/ProductosList";
const Main = () => {
  const [redirecting, setRedirecting] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useState(() => {
    return (<ValidateToken logOut={() => {
      //Funcionalidad de cerrar sesion
      setRedirecting(true);
      setTimeout(() => {
        if (localStorage.getItem("userInfo")) {
          localStorage.removeItem("userInfo");
        }
        //dispatch({ type: USER_LOGOUT });
        //dispatch({ type: USER_DETAILS_RESET });
        //dispatch({ type: USER_LOGOUT });
        navigate("/home");
      }, 2500);


    }} />);
  }, [localStorage]);
  return (
    <>
      <div className="w-full dark:bg-dark">
        <div className="md:grid md:grid-cols-principal">

          <div className="md:col-span-2 pl-[14%] sm:pl-[10%] md:pl-[0%] overflow-y-auto h-screen">

            <Outlet />
            <Routes>
              {/*<Route exact path="/inventario" element={<Carrito />} />*/}
              <Route exact path="/usuarios" element={<UsuariosList />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/productos" element={<ProductList />} />
              <Route exact path="/categorias" element={<CategoriasList />} />
              <Route exact path="/carritos" element={<CarritosList />} />
              <Route exact path="/ordenes" element={<OrdenesList />} />
              <Route exact path="/contactoList" element={<ContactoList />} />

            </Routes>
          </div>

          {redirecting ? <ModalSimple /> : null}
          {/*<div>
            <Footer />
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default Main;
