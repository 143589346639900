import React from "react";
import Swal from "sweetalert2";
export function AlertNoti({ letra_color, fondo_color, icono, title }) {
    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        color: letra_color,
        background: fondo_color,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });
    Toast.fire({
        icon: icono,
        title: title,
    });

}