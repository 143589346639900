import {
    Card,
    CardBody,
    CardHeader,
    Typography,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    CogIcon,
    ArchiveBoxIcon,
    CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import Chart from "react-apexcharts";
//import { ArchiveBoxIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

const initialChartConfig = {
    type: "line",
    height: 240,
    series: [
        {
            name: "Sales",
            data: [],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        title: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#020617"],
        stroke: {
            lineCap: "round",
            curve: "smooth",
        },
        markers: {
            size: 0,
        },
        xaxis: {
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
            categories: [],
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
        },
        grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 5,
                right: 20,
            },
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            theme: "dark",
        },
    },
};
const iconsMap = {
    PresentationChartBarIcon: PresentationChartBarIcon,
    ShoppingBagIcon: ShoppingBagIcon,
    UserCircleIcon: UserCircleIcon,
    Cog6ToothIcon: Cog6ToothIcon,
    InboxIcon: InboxIcon,
    PowerIcon: PowerIcon,
    ChevronRightIcon: ChevronRightIcon,
    ChevronDownIcon: ChevronDownIcon,
    CogIcon: CogIcon,
    ArchiveBoxIcon: ArchiveBoxIcon,
    CurrencyDollarIcon: CurrencyDollarIcon
};
export default function DashboardLine({ salesData, titulo = '', descripcion = '', icon = 'ArchiveBoxIcon', prediccion=false }) {
    const [chartConfig, setChartConfig] = useState(initialChartConfig);
    useEffect(() => {
        async function fetchData() {
            try {
                //const response = await axios.get("/tu-api-endpoint"); // Reemplaza con tu endpoint real
                //const salesData = response.data.result.info_ventas;

                const months = prediccion?salesData.map(item => item.mes):salesData.map(item => item.mes.split('-')[1]);
                const sales = salesData.map(item => item.result);

                setChartConfig(prevConfig => ({
                    ...prevConfig,
                    series: [{ name: "Sales", data: sales }],
                    options: {
                        ...prevConfig.options,
                        xaxis: {
                            ...prevConfig.options.xaxis,
                            categories: months,
                        },
                    },
                }));
            } catch (error) {
                console.error("Error fetching sales data:", error);
            }
        }

        fetchData();
    }, [salesData]);
    const IconComponent = iconsMap[icon];

    return (
        <Card>
            <CardHeader
                floated={false}
                shadow={false}
                color="transparent"
                className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
            >
                <div className="w-max rounded-lg bg-gray-900 p-5 text-white">
                    <IconComponent className="h-6 w-6" />
                </div>
                <div>
                    <Typography variant="h6" color="blue-gray">
                        {titulo}
                    </Typography>
                    <Typography
                        variant="small"
                        color="gray"
                        className="max-w-sm font-normal"
                    >
                        {descripcion}
                    </Typography>
                </div>
            </CardHeader>
            <CardBody className="px-2 pb-0">
                <Chart {...chartConfig} />
            </CardBody>
        </Card>
    );
}