import axios from "axios";
import {
  CATEGORIAS_CREATE_FAIL,
  CATEGORIAS_CREATE_REQUEST,
  CATEGORIAS_CREATE_SUCCESS,
  CATEGORIAS_DELETE_FAIL,
  CATEGORIAS_DELETE_REQUEST,
  CATEGORIAS_DELETE_SUCCESS,
  CATEGORIAS_DETAILS_FAIL,
  CATEGORIAS_DETAILS_REQUEST,
  CATEGORIAS_DETAILS_RESET,
  CATEGORIAS_DETAILS_SUCCESS,
  CATEGORIAS_LIST_FAIL,
  CATEGORIAS_LIST_REQUEST,
  CATEGORIAS_LIST_SUCCESS,
  CATEGORIAS_UPDATE_FAIL,
  CATEGORIAS_UPDATE_REQUEST,
  CATEGORIAS_UPDATE_SUCCESS,
  CATEGORIAS_ACTIVATE_REQUEST,
  CATEGORIAS_ACTIVATE_SUCCESS,
  CATEGORIAS_ACTIVATE_FAIL
} from "../../constantes/categoriasConstants";
import {
  COMBO_CATEGORIA_LIST_FAIL,
  COMBO_CATEGORIA_LIST_REQUEST,
  COMBO_CATEGORIA_LIST_SUCCESS,
} from "../../constantes/comboCategoriasConstants"
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import Swal from 'sweetalert2'
import { AlertNoti } from "../../componentes/AlertNoti";

export const categoriasListaRegistros = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIAS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/categorias/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: CATEGORIAS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response);
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: CATEGORIAS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCategoria = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIAS_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    await axios.delete(URL_SERVER + `/categorias/eliminar/${id}`, config);
    dispatch({
      type: CATEGORIAS_DELETE_SUCCESS,
    });
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Categoria inactivada' })
    dispatch(categoriasListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al eliminar categoria' })
    dispatch({
      type: CATEGORIAS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createCategoria = (categoria) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIAS_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    //create categoria
    const { data } = await axios.post(
      URL_SERVER + "/categorias/crear",
      categoria?.data,
      config
    );
    dispatch({
      type: CATEGORIAS_CREATE_SUCCESS,
      payload: data,
    });

    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Categoria creada' })

    dispatch(categoriasListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al crear categoria' })

    dispatch({
      type: CATEGORIAS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    categoria.setOkCreate(false);
    categoria.setMensaje('Error al crear');

  }
};
export const activeCategoria = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIAS_ACTIVATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    await axios.put(URL_SERVER + `/categorias/activar/${id}`, id, config);
    dispatch({
      type: CATEGORIAS_ACTIVATE_SUCCESS,
    });
    dispatch(categoriasListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));

    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Categoria activada' });

  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al activar categoria' });
    dispatch({
      type: CATEGORIAS_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateCategoria = (categoria) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIAS_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    //update product
    const { data } = await axios.put(
      URL_SERVER + `/categorias/actualizar/${categoria?.data?.id}`,
      categoria?.data,
      config
    );
    dispatch({
      type: CATEGORIAS_UPDATE_SUCCESS,
      payload: data,
    });
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Categoria actualizada' })
    dispatch(categoriasListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al actualizar categoria' })
    dispatch({
      type: CATEGORIAS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listCategoriasDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORIAS_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/categorias/obtener/${id}`, config);
    dispatch({
      type: CATEGORIAS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIAS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listaComboCategoria = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMBO_CATEGORIA_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        token: userInfo?.token,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/categorias/combo`,
      config
    );
    dispatch({
      type: COMBO_CATEGORIA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: COMBO_CATEGORIA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: CATEGORIAS_DETAILS_RESET });
};