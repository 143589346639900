import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import {
    Dialog,
    DialogBody,
    //  DialogContent,
    DialogHeader,
    Typography,
    //  DialogTitle,
} from "@material-tailwind/react";
import { Button, Input } from '@material-tailwind/react';
import { agregarAlCarrito } from '../actions/carrito/carritoActions';
import { useDispatch, useSelector } from 'react-redux';
import { listProductosDetails, listProductosDetailsNoToken } from '../actions/productos/productosActions';
import { URL_SERVER } from '../constantes/serverUrl';

const ModalDetalleProducto = (props) => {
    const { isOpen = true, onClose = () => { }, productId } = props
    const dispatch = useDispatch();
    const useData = useSelector((state) => state.userLogin || null);
    const { error, userInfo } = useData;
    const [nombre, setNombre] = useState(null);
    const [descripcion, setDescripcion] = useState(null);
    const [precio, setPrecio] = useState(null);
    const [img_path, setImgPath] = useState(null);
    const [cantidad, setCantidad] = useState(1);

    //const handleOpen = () => setShowModal((cur) => !cur);

    const { producto } = useSelector((state) => state.productosDetailsNoTokenR);

    const handleAddToCart = () => {
        if (!error) {
            if (userInfo) {
                if (userInfo?.perfil === 31) {
                    dispatch(agregarAlCarrito({ productId, cantidad }));
                } else if ([16, 22].includes(userInfo?.perfil)) {
                    return
                }
            }
        }
    };

    const handleCantidadChange = (e) => {
        setCantidad(Number(e.target.value));
    };

    useEffect(() => {
        console.log(productId, isOpen)
        if (productId && isOpen) {
            console.log('esta abierto')
            dispatch(listProductosDetailsNoToken(productId));
        } else {
            setNombre('');
            setDescripcion('');
            setPrecio('');
            setImgPath('');
        }
    }, [isOpen, productId]);

    useEffect(() => {
        console.log('producto: ', producto);
        if (producto && producto?.result?.length > 0) {
            setNombre(producto?.result[0].nombre);
            setDescripcion(producto?.result[0].observacion);
            setPrecio(producto?.result[0].precio);
            setImgPath(producto?.result[0].imgpath);
        }
    }, [producto, isOpen, productId]);


    if (!producto) return null; // Evita mostrar el modal si no hay datos del producto

    return (
        <Dialog open={isOpen} handler={onClose}>

            <DialogHeader toggler={onClose}>
                <DialogBody className="text-2xl font-bold">{nombre}</DialogBody>
            </DialogHeader>
            <div className="grid gap-6 sm:grid-cols-2">
                <div className="aspect-square overflow-hidden rounded-lg border bg-muted">
                    <img
                        src={`${URL_SERVER}/images_p/${img_path}`}
                        alt={nombre}
                        className="h-full w-full object-cover"
                    />
                </div>
                <div className="flex flex-col gap-4">
                    <div className="space-y-2">
                        <Typography
                            variant="small"
                            color="gray"
                            className="font-normal opacity-75 truncate max-w-[200px] line-clamp-3"
                        >
                            <ul>
                                {Array.isArray(descripcion) ? (
                                    descripcion.map((observacion, index) => (
                                        <li key={index}>- {observacion}</li>
                                    ))
                                ) : (
                                    <span>{descripcion}</span>
                                )}
                            </ul>
                        </Typography>
                    </div>
                    <div className="text-3xl font-bold text-purple-600">
                        ${precio}
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="quantity" className="text-sm font-medium">
                            Cantidad
                        </label>
                        <Input
                            id="quantity"
                            type="number"
                            min="1"
                            value={cantidad}
                            onChange={handleCantidadChange}
                            className="w-24"
                        />
                    </div>
                    <Button
                        className="w-full bg-purple-600 hover:bg-purple-700 text-white"
                        onClick={handleAddToCart}
                    >
                        AÑADIR AL CARRITO
                    </Button>
                </div>
            </div>

        </Dialog>
    );
}

export default ModalDetalleProducto;