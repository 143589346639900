import {
    CARRITO_LIST_RESET,
    CARRITO_CREATE_FAIL,
    CARRITO_DELETE_REQUEST,
    CARRITO_CREATE_REQUEST,
    CARRITO_CREATE_SUCCESS,
    CARRITO_DELETE_FAIL,
    CARRITO_DELETE_RESET,
    CARRITO_DELETE_SUCCESS,
    CARRITO_DETAILS_FAIL,
    CARRITO_DETAILS_REQUEST,
    CARRITO_DETAILS_RESET,
    CARRITO_DETAILS_SUCCESS,
    CARRITO_LIST_FAIL,
    CARRITO_LIST_REQUEST,
    CARRITO_LIST_SUCCESS,
    CARRITO_UPDATE_FAIL,
    CARRITO_UPDATE_REQUEST,
    CARRITO_UPDATE_RESET,
    CARRITO_UPDATE_SUCCESS,
    CARRITO_ACTIVATE_REQUEST,
    CARRITO_ACTIVATE_SUCCESS,
    CARRITO_ACTIVATE_FAIL,
    CARRITO_ACTIVATE_RESET,
} from '../constantes/carritoConstants'
export const carritosListReducer = (
    state = { loading: true, carritos: [] },
    action
) => {
    switch (action.type) {
        case CARRITO_LIST_REQUEST:
            return { loading: true, carritos: [] };
        case CARRITO_LIST_SUCCESS:
            return {
                loading: false,
                carritos: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case CARRITO_LIST_FAIL:
            return { loading: false, error: action.payload };
        case CARRITO_LIST_RESET:
            return { carritos: [] };
        default:
            return state;
    }
};

export const carritoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CARRITO_CREATE_REQUEST:
            return { loading: true };
        case CARRITO_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CARRITO_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const carritoDetailsReducer = (state = { carrito: {} }, action) => {
    switch (action.type) {
        case CARRITO_DETAILS_REQUEST:
            return { ...state, loading: true };
        case CARRITO_DETAILS_SUCCESS:
            return { loading: false, carrito: action.payload };
        case CARRITO_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case CARRITO_DETAILS_RESET:
            return { carrito: {} };
        default:
            return state;
    }
};

export const carritoUpdateReducer = (state = { carrito: {} }, action) => {
    switch (action.type) {
        case CARRITO_UPDATE_REQUEST:
            return { loading: true };
        case CARRITO_UPDATE_SUCCESS:
            return { loading: false, success: true, carrito: action.payload };
        case CARRITO_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CARRITO_UPDATE_RESET:
            return { carrito: {} };
        default:
            return state;
    }
};

export const carritoDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CARRITO_DELETE_REQUEST:
            return { loading: true };
        case CARRITO_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CARRITO_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CARRITO_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const carritoActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case CARRITO_ACTIVATE_REQUEST:
            return { loading: true };
        case CARRITO_ACTIVATE_SUCCESS:
            return { loading: false, success: true };
        case CARRITO_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case CARRITO_ACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};




