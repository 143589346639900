import { PaperClipIcon } from '@heroicons/react/20/solid';
import { Button, Chip, Input } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validarCadenaSoloLetras, validarEntero } from '../../funfiones/validaciones';
import { listUsuarioDetails, obtenerMiID, updateInfoUser } from '../../actions/usuarios/usuarioActions';
import { useNavigate } from 'react-router-dom';

export default function InfoUserLoging() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nombre, setNombre] = useState(null);
    const [apellido, setApellido] = useState(null);
    const [celular, setCelular] = useState(null);
    const [correo, setCorreo] = useState(null);
    const [direccion, setDireccion] = useState(null);
    const [estado, setEstado] = useState(null);

    const [avisoNombre, setAvisoNombre] = useState('');
    const [avisoApellido, setAvisoApellido] = useState('');
    const [avisoCelular, setAvisoCelular] = useState('');

    const [id_usuario, setIdUsuario] = useState(null);

    const usuarioDataEdit = useSelector((state) => state.usuariosDetailsR);

    useEffect(() => {
        dispatch(obtenerMiID(setIdUsuario));
    }, []);

    useEffect(() => {
        if (id_usuario)
            dispatch(listUsuarioDetails(id_usuario));
    }, [id_usuario]);

    useEffect(() => {
        if (usuarioDataEdit.usuarios?.result) {
            setNombre(usuarioDataEdit.usuarios?.result[0].nombre);
            setApellido(usuarioDataEdit.usuarios?.result[0].apellido);
            setCelular(usuarioDataEdit.usuarios?.result[0].celular);
            setCorreo(usuarioDataEdit.usuarios?.result[0].correo);
            setDireccion(usuarioDataEdit.usuarios?.result[0].direccion);
            setEstado(usuarioDataEdit.usuarios?.result[0].estado);
        }
    }, [usuarioDataEdit]);

    function validarNombre(e) {
        if (validarCadenaSoloLetras(e.target.value)) {
            setAvisoNombre('');
        } else {
            setAvisoNombre('Campo invalido o vacio');
        }
        setNombre(e.target.value);
    }

    function validarApellido(e) {
        if (validarCadenaSoloLetras(e.target.value)) {
            setAvisoApellido('');
        } else {
            setAvisoApellido('Campo invalido o vacio');
        }
        setApellido(e.target.value);
    }

    function validarCelular(e) {
        if (validarEntero(e.target.value)) {
            setAvisoCelular('');
        } else {
            setAvisoCelular('Campo invalido o vacio');
        }
        setCelular(e.target.value);
    }

    return (
        <div className='py-12 px-4 md:py-16 md:px-6 lg:py-20 lg:px-8'>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Información de usuario</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Puede cambiar y guardar sus datos.</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Nombre</dt>
                        <Input value={nombre} onChange={validarNombre} className="mt-1 sm:mt-0 sm:col-span-2"></Input>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Apellido</dt>
                        <Input value={apellido} onChange={validarApellido} className="mt-1 sm:mt-0 sm:col-span-2"></Input>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Celular</dt>
                        <Input value={celular} onChange={validarCelular} className="mt-1 sm:mt-0 sm:col-span-2"></Input>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Correo</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{correo}</dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Direccion</dt>
                        <Input value={direccion} onChange={(e) => {
                            e.preventDefault();
                            setDireccion(e.target.value);
                        }} className="mt-1 sm:mt-0 sm:col-span-2"></Input>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Estado</dt>
                        <div className="mt-1 sm:mt-0 sm:col-span-2 w-1/4">
                            <Chip
                                variant="ghost"
                                size="sm"
                                value={estado}
                                color={estado === 'ACTIVO' ? "green" : "red"}
                            />
                        </div>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6 sm:px-0">
                        <div className="mt-4 flex justify-end space-x-3 sm:col-span-2 sm:mt-0">
                            <Button className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const data = {
                                        nombre,
                                        apellido,
                                        celular,
                                        direccion
                                    }
                                    dispatch(updateInfoUser(data, id_usuario));
                                }}>
                                Guardar cambios
                            </Button>
                            <Button className="bg-green-700 text-white shadow-md hover:bg-green-600 hover:scale-105 focus:bg-green-600 active:bg-green-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/cambiarclave');
                                }}>
                                Cambiar clave
                            </Button>
                            <Button className="bg-gray-700 text-white shadow-md hover:bg-gray-600 hover:scale-105 focus:bg-gray-600 active:bg-gray-800"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/home');
                                }}>
                                Regresar
                            </Button>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    );
}
