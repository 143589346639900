import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { recuperarContra } from "../actions/autenticacion/loginActions";
import { validarEmail } from "../funfiones/validaciones";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export function RecuperarPass({ open, setOpen, setShowModalConfirmacion, setCuerpo, setCabecera }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleOpen = () => setOpen((cur) => !cur);
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const [correo, setCorreo] = React.useState("");

  const [avisoCorreo, setAvisoCorreo] = React.useState(" ");

  const recupPass = (credenciales) => dispatch(recuperarContra(credenciales));
  useEffect(() => {
    if (!error) {
      if (userInfo) {
        setOpen(false);
        if (userInfo?.perfil == 31) {
          if (location.pathname === '/home')
            window.location.reload();
          else
            navigate("/home");
        }
        else if ([16, 22].includes(userInfo?.perfil)) {
          navigate("/dashboard");
          window.location.reload();
        }
      }
    }
  }, [userInfo, error]);
  function validarCorreo(e) {
    if (validarEmail(e.target.value)) {
      setCorreo(e.target.value);
      setAvisoCorreo('');
    } else {
      setAvisoCorreo('Email invalido')
    }
  }
  return (
    <>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="blue-gray">
              Recuperar contraseña
            </Typography>
            <Typography
              className="mb-3 font-normal"
              variant="paragraph"
              color="gray"
            >
              Ingresa tu correo registrado para recuperar tu contraseña
            </Typography>
            <Typography className="-mb-2" variant="h6">
              Tu correo
            </Typography>
            <Input label="Correo" size="lg" onChange={validarCorreo} />
            <Typography color="red">{avisoCorreo}</Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <Button className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
              onClick={(e) => {
                e.preventDefault();
                setCuerpo('se envio la URL de recuperacion a su correo');
                setCabecera('Recuperar contraseña');
                setShowModalConfirmacion(true);
                setOpen(false);
                recupPass({
                  correo,
                });
              }} fullWidth>
              Recuperar
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}