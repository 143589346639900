import React, { useState, useEffect } from "react";
import { Carousel } from "@material-tailwind/react";
import { ProductCard } from "../../componentes/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { clearDataInfoForCart, getImg, productosInfoForCart } from "../../actions/productos/productosActions";

const Home = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [imagesPath, setImagesPath] = useState({});
  const { productosInfoCart } = useSelector((state) => state.productosInfoCartR);

  useEffect(() => {
    return () => {
      dispatch(clearDataInfoForCart());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(productosInfoForCart({ limitPage: 3 }));
  }, [dispatch]);

  useEffect(() => {
    if (productosInfoCart) {
      productosInfoCart.forEach((element) => {
        dispatch(getImg({ img_name: element.imagen, id: element.id, setImagesPath }));
      });
    }
  }, [productosInfoCart, dispatch]);

  const getRandomImages = (imagesObj, count) => {
    const keys = Object.keys(imagesObj);
    if (keys.length <= count) return keys.map(key => imagesObj[key]);

    const selectedKeys = [];
    while (selectedKeys.length < count) {
      const randomKey = keys[Math.floor(Math.random() * keys.length)];
      if (!selectedKeys.includes(randomKey)) {
        selectedKeys.push(randomKey);
      }
    }
    return selectedKeys.map(key => imagesObj[key]);
  };

  const randomImages = getRandomImages(imagesPath, 3);

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-100 via-purple-200 to-indigo-100 p-4 sm:p-6 md:p-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-purple-600">Exclusividad en Detalles</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">¡Bienvenido a Vernelly Detalles!</p>
              <p className="mt-6 text-lg leading-8 text-gray-600"></p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                En <b>Vernelly Detalles</b>, transformamos flores en arte y emociones en momentos inolvidables. Nos especializamos en crear arreglos florales únicos y personalizados que no solo embellecen cualquier ocasión, sino que también cuentan una historia y transmiten sentimientos profundos.
                Cada arreglo floral que diseñamos es una obra maestra cuidadosamente elaborada con las flores más frescas y de la más alta calidad. Desde ramos exuberantes hasta delicados centros de mesa, nos aseguramos de que cada detalle refleje tu estilo y personalidad.
                Además de nuestros exclusivos arreglos florales, ofrecemos una variedad de detalles especiales, como decoraciones para eventos, regalos personalizados y más. Cada creación es un testimonio de nuestra pasión por la belleza y nuestro deseo de hacer que cada momento sea memorable.
              </dl>
            </div>
          </div>
          <Carousel className="rounded-xl bg-[#000]">
            {randomImages.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-full h-64 sm:h-96 object-contain mt-4 sm:mt-[5%]"
              />
            ))}
          </Carousel>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-[5%] gap-4 mt-[3%]">
     {/*<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">*/}
          {Object.keys(imagesPath).length > 0 && productosInfoCart.map((item, index) => (
            <ProductCard
              key={index}
              id_producto={item?.id}
              imagen={imagesPath[item.id] || ""}
              nombre={item.nombre}
              descripcion={item.observacion || ""}
              precio={item.precio || 0}
              openModalAviso={open}
              setOpenModalAviso={setOpen}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
