import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navbar, Button, IconButton, Collapse, Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import ModalClose from "./ModalClose";
import {
  HomeIcon,
  ShoppingBagIcon,
  PhoneIcon,
  ShoppingCartIcon,
  UserPlusIcon,
  ArrowLeftEndOnRectangleIcon,
  UserIcon
} from "@heroicons/react/24/solid";
import logo from "../assets/imagenes/logo.png";

const NavBar = ({ setLoginCard, setRegisterCard, isLoging, setIsLoging }) => {
  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        setIsLoging(true);
      } else {
        setIsLoging(false);
      }
    }
  }, [error, userInfo, setIsLoging]);

  const closeModal = () => {
    return (
      <ModalClose
        showModal={showModal}
        setShowModal={setShowModal}
        cabecera={'Cerrar sesión'}
        cuerpo={'¿Está seguro de que desea cerrar la sesión?'}
        confirmacion={'Salir'}
        salida={'Cancelar'}
      />
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 960) {
        setOpenNav(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navList = (
    <ul className="flex flex-col lg:flex-row lg:items-center lg:space-x-10">
      <li>
        <Button
          variant="text"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            navigate('/home');
          }}
          className="flex items-center"
        >
          Inicio
          <HomeIcon className="w-5 h-5 ml-1" />
        </Button>
      </li>
      <li>
        <Button
          variant="text"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            navigate('/tienda');
          }}
          className="flex items-center"
        >
          Tienda
          <ShoppingBagIcon className="w-5 h-5 ml-1" />
        </Button>
      </li>
      <li>
        <Button
          variant="text"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            navigate('/contacto')
          }}
          className="flex items-center"
        >
          Contacto
          <PhoneIcon className="w-5 h-5 ml-1" />
        </Button>
      </li>
      <li>
        <Button
          variant="text"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            navigate('/carrito');
          }}
          className="flex items-center"
        >
          Carrito
          <ShoppingCartIcon className="w-5 h-5 ml-1" />
        </Button>
      </li>
    </ul>
  );

  const authButtons = (
    <ul className="flex flex-col lg:flex-row lg:items-center lg:space-x-4 ml-auto">
      <li>
        {!isLoging ? (
          <Button
            variant="text"
            color="black"
            onClick={(e) => {
              e.preventDefault();
              setRegisterCard(true);
            }}
            className="flex items-center"
          >
            Registrarse
            <UserPlusIcon className="w-5 h-5 ml-1" />
          </Button>
        ) : null}
      </li>
      <li className="relative">
        {isLoging ? (
          <Menu>
            <MenuHandler>
              <Button
                variant="text"
                color="black"
                className="flex items-center"
              >
                {`${userInfo?.name} ${userInfo?.lastname}`}
                <UserIcon className="w-5 h-5 ml-1" />
              </Button>
            </MenuHandler>
            <MenuList>
              <MenuItem onClick={() => navigate('/infouser')}>Ver Perfil</MenuItem>
              <MenuItem onClick={() => navigate('/infopedidos')}>Pedidos</MenuItem>
              <MenuItem onClick={() => setShowModal(true)}>Cerrar sesión</MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Button
            variant="text"
            color="black"
            onClick={(e) => {
              e.preventDefault();
              setLoginCard(true);
            }}
            className="flex items-center"
          >
            Iniciar sesión
            <ArrowLeftEndOnRectangleIcon className="w-5 h-5 ml-1" />
          </Button>
        )}
      </li>
    </ul>
  );

  return (
    <>
      <Navbar className="sticky top-0 z-40 px-4 py-2 bg-purple-500 border-b border-gray-200 rounded-b-lg shadow-lg w-full max-w-screen-3xl">
        <div className="container flex items-center justify-between mx-auto">
          {/* Logo Section */}
          <div className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="w-24 h-auto cursor-pointer"  // Adjust width as needed
              onClick={() => navigate('/home')}
            />
          </div>
          {/* Navbar Links */}
          <div className="hidden lg:flex lg:items-center lg:space-x-10">
            {navList}
          </div>
          {/* Auth Buttons */}
          <div className="hidden lg:flex lg:items-center lg:space-x-4 ml-auto">
            {authButtons}
          </div>
          {/* Mobile Menu Button */}
          <div className="flex items-center justify-end space-x-4 lg:hidden">
            <IconButton
              onClick={() => setOpenNav(!openNav)}
              className="text-black bg-transparent"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {openNav ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav} className="lg:hidden">
          {navList}
          {authButtons}
        </Collapse>
      </Navbar>
      {closeModal()}
    </>
  );
};

export default NavBar;
