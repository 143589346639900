export const CONTACTO_LIST_REQUEST = "CONTACTO_LIST_REQUEST";
export const CONTACTO_LIST_SUCCESS = "CONTACTO_LIST_SUCCESS";
export const CONTACTO_LIST_FAIL = "CONTACTO_LIST_FAIL";
export const CONTACTO_LIST_RESET = "CONTACTO_LIST_RESET";

export const CONTACTO_CREATE_REQUEST = "CONTACTO_CREATE_REQUEST";
export const CONTACTO_CREATE_SUCCESS = "CONTACTO_CREATE_SUCCESS";
export const CONTACTO_CREATE_FAIL = "CONTACTO_CREATE_FAIL";

export const CONTACTO_DETAILS_REQUEST = "CONTACTO_DETAILS_REQUEST";
export const CONTACTO_DETAILS_SUCCESS = "CONTACTO_DETAILS_SUCCESS";
export const CONTACTO_DETAILS_FAIL = "CONTACTO_DETAILS_FAIL";
export const CONTACTO_DETAILS_RESET = "CONTACTO_DETAILS_RESET";