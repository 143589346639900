import React, { useState, useEffect } from "react";
import { ProductCard } from "../../componentes/ProductCard";
import { getImg, productosInfoForCart } from "../../actions/productos/productosActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Tienda = ({ open, setOpen, setOpenDetail, setIdProductoDetail }) => {
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imagesPath, setImagesPath] = useState({});
  const { productosInfoCart } = useSelector((state) => state.productosInfoCartR);

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        if (userInfo?.perfil === 31)
          navigate("/tienda");
      } else if ([16, 22].includes(userInfo?.perfil)) {
        return
      }
    } else { navigate("/tienda"); }
  }, []);

  useEffect(() => {
    dispatch(productosInfoForCart({ limitPage: 100 }));
  }, []);

  useEffect(() => {
    if (productosInfoCart) {
      productosInfoCart.forEach((element) => {
        dispatch(getImg({ img_name: element.imagen, id: element.id, setImagesPath }));
      });
    }
  }, [productosInfoCart]);

  // Detectar el ID de la URL y abrir el modal automáticamente
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    if (id) {
      setOpenDetail(true);  // Abrir el modal
      setIdProductoDetail(id);  // Pasar el ID al estado de detalle del producto
      console.log("El ID del producto es:", id);
    }
  }, [location, setOpenDetail, setIdProductoDetail]);

  const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  };


  const shuffledProductos = productosInfoCart ? shuffleArray([...productosInfoCart]) : [];

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-100 via-purple-200 to-indigo-100 p-4 sm:p-6 md:p-8">
      <div className="w-full">
        <h1 className="text-3xl font-bold text-center text-purple-800 mb-6">Tienda</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {shuffledProductos.map((item, index) => (
            <ProductCard
              key={index}
              id_producto={item?.id}
              imagen={imagesPath[item.id] || ""}
              nombre={item.nombre}
              descripcion={item.observacion || ""}
              precio={item.precio || 0}
              openModalAviso={open}
              setOpenModalAviso={setOpen}
              setOpenDetail={setOpenDetail}
              setIdProductoDetail={setIdProductoDetail}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tienda;
