import {
    CONTACTO_DETAILS_FAIL,
    CONTACTO_DETAILS_REQUEST,
    CONTACTO_DETAILS_RESET,
    CONTACTO_DETAILS_SUCCESS,
    CONTACTO_LIST_RESET,
    CONTACTO_LIST_FAIL,
    CONTACTO_LIST_REQUEST,
    CONTACTO_LIST_SUCCESS,
    CONTACTO_CREATE_REQUEST,
    CONTACTO_CREATE_SUCCESS,
    CONTACTO_CREATE_FAIL
} from '../constantes/contactoConstants'
export const contactosListReducer = (
    state = { loading: true, contactos: [] },
    action
) => {
    switch (action.type) {
        case CONTACTO_LIST_REQUEST:
            return { loading: true, contactos: [] };
        case CONTACTO_LIST_SUCCESS:
            return {
                loading: false,
                contactos: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case CONTACTO_LIST_FAIL:
            return { loading: false, error: action.payload };
        case CONTACTO_LIST_RESET:
            return { contactos: [] };
        default:
            return state;
    }
};

export const contactoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACTO_CREATE_REQUEST:
            return { loading: true };
        case CONTACTO_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CONTACTO_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const contactoDetailsReducer = (state = { contacto: {} }, action) => {
    switch (action.type) {
        case CONTACTO_DETAILS_REQUEST:
            return { ...state, loading: true };
        case CONTACTO_DETAILS_SUCCESS:
            return { loading: false, contacto: action.payload };
        case CONTACTO_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case CONTACTO_DETAILS_RESET:
            return { contacto: {} };
        default:
            return state;
    }
};