import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";

// Importa tus reducers
//import userReducer from './reducers/userReducer';
import { userLoginReducer, userRecupPassReducer } from "./reductores/loginReducer";
import {
    productosListReducer,
    productosCreateReducer,
    productosDetailsReducer,
    productosUpdateReducer,
    productosDeleteReducer,
    productosActivateReducer,
    productosInfoCartReducer,
    productosDetailsNoTokenReducer,
} from './reductores/productosReducer';
import {
    categoriasListReducer,
    categoriasCreateReducer,
    categoriasDetailsReducer,
    categoriasUpdateReducer,
    categoriasDeleteReducer,
    categoriasActivateReducer,
} from './reductores/categoriasReducer';

import { comboCategoriaListReducer } from "./reductores/comboCategoriaReducer";

import {
    usuariosListReducer,
    usuariosCreateReducer,
    usuariosDetailsReducer,
    usuariosUpdateReducer,
    usuariosDeleteReducer,
    usuariosActivateReducer,
} from './reductores/usuariosReducer';

import {
    carritosListReducer,
    carritoCreateReducer,
    carritoDetailsReducer,
    carritoUpdateReducer,
    carritoDeleteReducer,
    carritoActivateReducer,
} from './reductores/carritoReducer';

import {
    pedidosListReducer,
    pedidosCreateReducer,
    pedidoDetailsReducer,
    pedidosUpdateReducer,
    pedidosDeleteReducer,
    pedidosActivateReducer,
    pedidoinfoUserReducer
} from './reductores/pedidosReducer';

import {
    comboUsuariosListReducer,
    comboPerfilesListReducer
} from "./constantes/comboUsuariosReducer";

import {
    reportesCreateReducer,
    reportesDeleteReducer,
    reportesDetailsReducer,
    reportesListReducer,
    reportesUpdateReducer
} from "./reductores/reportesReducer";

import { dashboardDetailsReducer } from "./reductores/dashboardReducer";
import { comboProductosListReducer } from "./reductores/comboProductosReducer";
import { prediccionProductosReducer } from "./reductores/prediccionProductosReducer";
import { contactoCreateReducer, contactoDetailsReducer, contactosListReducer } from "./reductores/contactoReducer";

const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = configureStore({
    reducer: {
        userLogin: userLoginReducer,

        userRecupPassR: userRecupPassReducer,

        dashboardDetailsR: dashboardDetailsReducer,

        productosListR: productosListReducer,
        productosCreateR: productosCreateReducer,
        productosDetailsR: productosDetailsReducer,
        productosDetailsNoTokenR: productosDetailsNoTokenReducer,
        productosUpdateR: productosUpdateReducer,
        productosDeleteR: productosDeleteReducer,
        productosActivateR: productosActivateReducer,

        comboProductosListR: comboProductosListReducer,

        prediccionProductosR: prediccionProductosReducer,

        productosInfoCartR: productosInfoCartReducer,

        categoriasListR: categoriasListReducer,
        categoriasCreateR: categoriasCreateReducer,
        categoriasDetailsR: categoriasDetailsReducer,
        categoriasUpdateR: categoriasUpdateReducer,
        categoriasDeleteR: categoriasDeleteReducer,
        categoriasActivateR: categoriasActivateReducer,

        comboCategoriaListR: comboCategoriaListReducer,

        usuariosListR: usuariosListReducer,
        usuariosCreateR: usuariosCreateReducer,
        usuariosDetailsR: usuariosDetailsReducer,
        usuariosUpdateR: usuariosUpdateReducer,
        usuariosDeleteR: usuariosDeleteReducer,
        usuariosActivateR: usuariosActivateReducer,

        comboUsuariosListR: comboUsuariosListReducer,
        comboPerfilesListR: comboPerfilesListReducer,

        carritosListR: carritosListReducer,
        carritoCreateR: carritoCreateReducer,
        carritoDetailsR: carritoDetailsReducer,
        carritoUpdateR: carritoUpdateReducer,
        carritoDeleteR: carritoDeleteReducer,
        carritoActivateR: carritoActivateReducer,

        pedidosListR: pedidosListReducer,
        pedidosCreateR: pedidosCreateReducer,
        pedidoDetailsR: pedidoDetailsReducer,
        pedidosUpdateR: pedidosUpdateReducer,
        pedidosDeleteR: pedidosDeleteReducer,
        pedidosActivateR: pedidosActivateReducer,
        pedidoinfoUserR: pedidoinfoUserReducer,

        contactoCreateR:contactoCreateReducer,
        contactosListR: contactosListReducer,
        contactoDetailsR: contactoDetailsReducer,

        reportesList: reportesListReducer,
    reportesCreate: reportesCreateReducer,
    reportesDetails: reportesDetailsReducer,
    reportesUpdate: reportesUpdateReducer,
    reportesDelete: reportesDeleteReducer,
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    //devTools: process.env.NODE_ENV !== 'production' ? composeWithDevTools() : false,
    devTools: false,
});

export default store;
