export const CATEGORIAS_LIST_REQUEST = "CATEGORIAS_LIST_REQUEST";
export const CATEGORIAS_LIST_SUCCESS = "CATEGORIAS_LIST_SUCCESS";
export const CATEGORIAS_LIST_FAIL = "CATEGORIAS_LIST_FAIL";
export const CATEGORIAS_LIST_RESET = "CATEGORIAS_LIST_RESET";

export const CATEGORIAS_CREATE_REQUEST = "CATEGORIAS_CREATE_REQUEST";
export const CATEGORIAS_CREATE_SUCCESS = "CATEGORIAS_CREATE_SUCCESS";
export const CATEGORIAS_CREATE_FAIL = "CATEGORIAS_CREATE_FAIL";

export const CATEGORIAS_DETAILS_REQUEST = "CATEGORIAS_DETAILS_REQUEST";
export const CATEGORIAS_DETAILS_SUCCESS = "CATEGORIAS_DETAILS_SUCCESS";
export const CATEGORIAS_DETAILS_FAIL = "CATEGORIAS_DETAILS_FAIL";
export const CATEGORIAS_DETAILS_RESET = "CATEGORIAS_DETAILS_RESET";

export const CATEGORIAS_UPDATE_REQUEST = "CATEGORIAS_UPDATE_REQUEST";
export const CATEGORIAS_UPDATE_SUCCESS = "CATEGORIAS_UPDATE_SUCCESS";
export const CATEGORIAS_UPDATE_FAIL = "CATEGORIAS_UPDATE_FAIL";
export const CATEGORIAS_UPDATE_RESET = "CATEGORIAS_UPDATE_RESET";

export const CATEGORIAS_DELETE_REQUEST = "CATEGORIAS_DELETE_REQUEST";
export const CATEGORIAS_DELETE_SUCCESS = "CATEGORIAS_DELETE_SUCCESS";
export const CATEGORIAS_DELETE_FAIL = "CATEGORIAS_DELETE_FAIL";
export const CATEGORIAS_DELETE_RESET = "CATEGORIAS_DELETE_RESET";

export const CATEGORIAS_ACTIVATE_REQUEST = "CATEGORIAS_ACTIVATE_REQUEST";
export const CATEGORIAS_ACTIVATE_SUCCESS = "CATEGORIAS_ACTIVATE_SUCCESS";
export const CATEGORIAS_ACTIVATE_FAIL = "CATEGORIAS_ACTIVATE_FAIL";
export const CATEGORIAS_ACTIVATE_RESET = "CATEGORIAS_ACTIVATE_RESET";