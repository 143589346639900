import React, { useState, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button, Carousel, IconButton, Tooltip } from "@material-tailwind/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from "react-redux";
import { actualizarCarrito, carritoListaRegistros, listCarritoClienteDetails } from "../../actions/carrito/carritoActions";
import { useNavigate } from "react-router-dom";
import { URL_SERVER } from "../../constantes/serverUrl";
import { createPedido } from "../../actions/pedidos/pedidosActions";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import ModalConfirmCompra from "../../componentes/ModalConfirmCompra";
import { whatsappIcon, whatsappUrl } from "../../componentes/IconSVGPath";

const Carrito = ({ open, setOpen }) => {
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("Usuario");
  const [total, setTotal] = useState(0.00);
  const [showModal, setShowModal] = useState(false);
  const { loading, carrito } = useSelector((state) => state.carritoDetailsR || { carrito: [] });

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        if (userInfo?.perfil === 31) {
          dispatch(listCarritoClienteDetails());
        }
      } else if ([16, 22].includes(userInfo?.perfil)) {
        return;
      }
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  useEffect(() => {
    if (carrito) {
      const newTotal = carrito?.result?.reduce((acc, item) => acc + item.cantidad * parseFloat(item.precio), 0);
      setTotal(newTotal);
    }
  }, [carrito]);

  const handleQuantityChange = (index, change) => {
    const updatedCarrito = carrito?.result.map((item, i) => {
      if (i === index) {
        const newQuantity = item.cantidad + change;
        const newTotal = ((newQuantity > 0 ? newQuantity : 1) * item.precio);
        return { ...item, cantidad: newQuantity > 0 ? newQuantity : 1, total: newTotal };
      }
      return item;
    });
    //ACTUALIZAR LA VARIABLE CARRITO CON DISPATCH
    dispatch(actualizarCarrito(updatedCarrito));
    dispatch({
      type: 'CARRITO_DETAILS_SUCCESS',
      payload: { result: updatedCarrito }
    });
  };

  const handleRemoveItem = (index, id) => {
    const updatedCarrito = carrito?.result.filter((_, i) => i !== index);
    // Actualizar el estado del carrito en el frontend
    dispatch({
      type: 'CARRITO_DETAILS_SUCCESS',
      payload: { result: updatedCarrito }
    });

    // Enviar la actualización al backend
    dispatch(actualizarCarrito(updatedCarrito));

    // Eliminar el producto del backend
    fetch(`${URL_SERVER}/carrito/quitardelcarrito`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'token': userInfo.token,
      },
      body: JSON.stringify({ id })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Si es necesario, realizar acciones adicionales después de eliminar el producto
          dispatch(listCarritoClienteDetails());
        }
      })
      .catch(error => {
        console.error('Error al eliminar el producto del carrito:', error);
      });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-100 via-purple-200 to-indigo-100 p-4 sm:p-6 md:p-8">
      <div className="w-full">
        <div className="grid gap-4 lg:grid-cols-3">
          <div className="lg:col-span-2 bg-gray-100 p-4 rounded-md">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold text-gray-800">Carrito</h2>
            </div>
            <hr className="border-gray-300 my-4" />

            {Array.isArray(carrito?.result) && carrito?.result.length > 0 ? (
              carrito?.result.map((item, index) => (
                <div key={index} className="space-y-4">
                  <div className="grid gap-4 sm:grid-cols-3 items-center">
                    <div className="sm:col-span-2 flex items-center gap-4">
                      <div className="w-24 h-24 shrink-0 bg-white p-1 rounded-md">
                        <img src={`${URL_SERVER}/images_p/${item.imagenpath}`} className="w-full h-full object-contain" alt={`Producto ${item.nombre}`} />
                      </div>
                      <div>
                        <h3 className="text-base font-bold text-gray-800">{item.nombre}</h3>
                      </div>
                      <div className="grid grid-cols-4 gap-2">
                        <Tooltip content="Disminuir cantidad">
                          <Button
                            className="min-w-8 p-2 border rounded-lg bg-red-900"
                            size="sm"
                            onClick={() => handleQuantityChange(index, -1)}
                          >
                            <MinusIcon className="h-5 w-5" />
                          </Button>
                        </Tooltip>
                        <span className="mx-2">{item.cantidad}</span>
                        <Tooltip content="Aumentar cantidad">
                          <Button
                            className="min-w-8 p-2 border rounded-lg bg-red-900"
                            size="sm"
                            onClick={() => handleQuantityChange(index, 1)}
                          >
                            <PlusIcon className="h-5 w-5" />
                          </Button>
                        </Tooltip>
                        <Tooltip content="Borrar del carrito">
                          <Button
                            className="min-w-8 p-2 border rounded-lg bg-red-900"
                            size="sm"
                            onClick={() => handleRemoveItem(index, item.CADE_CODIGO)}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="ml-auto">
                      <h4 className="text-lg font-bold text-gray-800">{`$${item?.total?.toFixed(2)}`}</h4>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No hay productos en el carrito.</p>
            )}
          </div>

          <div className="bg-gray-100 rounded-md p-4 lg:sticky top-0">
            <ul className="text-gray-800 mt-8 space-y-4">
              <li className="flex justify-between text-sm">Subtotal <span className="font-bold">${total?.toFixed(2) || 0}</span></li>
              <li className="flex justify-between text-sm">IVA <span className="font-bold">${total > 0 ? ((total * 0.15)?.toFixed(2)) : 0}</span></li>
              <li className="flex justify-between text-sm font-bold">Total <span className="font-bold">${total > 0 ? ((total * 1.15)?.toFixed(2)) : 0}</span></li>
            </ul>

            <div className="mt-8 space-y-2">
              <button type="button" className="text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-purple-600 hover:bg-purple-700 text-white rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  if (total > 0) {
                    setShowModal(true);
                    //dispatch(createPedido({ product_list: carrito?.result, total: (total * 1.15)?.toFixed(2) || 0 }));

                    //setTotal(0.000);
                  }
                }}>Confirmar</button>
              <button type="button" className="text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-transparent text-gray-800 border border-gray-300 rounded-md"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/tienda');
                }}>Continuar comprando</button>
              <button
                type="button"
                className="flex items-center text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-green-600 hover:bg-green-700 text-white rounded-md"
                onClick={() => {
                  window.open(
                    whatsappUrl,
                    "_blank"
                  );
                }}
              >
                <span className="mr-2 px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d={whatsappIcon}
                    />
                  </svg>
                </span>
                Pedir por whatsapp
              </button>

            </div>
          </div>
        </div>
      </div>
      <ModalConfirmCompra
        total={total} showModal={showModal} setShowModal={setShowModal} cartItems={carrito?.result} setTotal={setTotal}
      />
    </div>
  );
};

export default Carrito;
