import React, { useEffect } from "react";
import {
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Chip,
    Accordion,
    AccordionHeader,
    AccordionBody,
    Alert,
    Input,
    Drawer,
    Card,
} from "@material-tailwind/react";
import {
    PresentationChartBarIcon,
    ShoppingBagIcon,
    UserCircleIcon,
    Cog6ToothIcon,
    InboxIcon,
    PowerIcon,
    CogIcon,
    ArchiveBoxIcon,
    CurrencyDollarIcon,
    ArrowTrendingUpIcon,
    InboxArrowDownIcon
} from "@heroicons/react/24/solid";
import {
    ChevronRightIcon,
    ChevronDownIcon,
    CubeTransparentIcon,
    MagnifyingGlassIcon,
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import ModalClose from "./ModalClose";
import logo from "../assets/imagenes/logofond.PNG"

// Mapping for icons
const iconsMap = {
    PresentationChartBarIcon: PresentationChartBarIcon,
    ShoppingBagIcon: ShoppingBagIcon,
    UserCircleIcon: UserCircleIcon,
    Cog6ToothIcon: Cog6ToothIcon,
    InboxIcon: InboxIcon,
    PowerIcon: PowerIcon,
    ChevronRightIcon: ChevronRightIcon,
    ChevronDownIcon: ChevronDownIcon,
    CogIcon: CogIcon,
    ArchiveBoxIcon: ArchiveBoxIcon,
    CurrencyDollarIcon: CurrencyDollarIcon,
    ArrowTrendingUpIcon: ArrowTrendingUpIcon,
    InboxArrowDownIcon: InboxArrowDownIcon,
};

export function SidebarBurger({ menuConfig }) {
    const [open, setOpen] = React.useState(0);
    const [openAlert, setOpenAlert] = React.useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const navigate = useNavigate();

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const closeModal = () => {
        return (
            <ModalClose
                showModal={showModal}
                setShowModal={setShowModal}
                cabecera={'Cerrar sesion'}
                cuerpo={'¿Esta seguro de que desea cerrar la sesion?'}
                confirmacion={'Salir'}
                salida={'Cancelar'}
            />
        );
    };

    return (
        <>
            <IconButton variant="text" size="lg" onClick={openDrawer}>
                {isDrawerOpen ? (
                    <XMarkIcon className="h-8 w-8 stroke-2" />
                ) : (
                    <Bars3Icon className="h-8 w-8 stroke-2" />
                )}
            </IconButton>
            <Drawer open={isDrawerOpen} onClose={closeDrawer} className="bg-purple-200  rounded-lg">
                <Card
                    color="transparent"
                    shadow={false}
                    className="h-[calc(100vh-2rem)] w-full h-full p-4 bg-gradient-to-br from-pink-100 via-purple-200 to-indigo-100 rounded-lg" // Added rounded-lg for rounded corners
                >
                    <div className="mb-2 flex items-center gap-4 p-4">
                        <img
                            src={logo}
                            alt="brand"
                            className="h-12 w-12 rounded-full" // Added rounded-full for rounded image
                        />
                        <Typography variant="h5" color="blue-gray">
                            Vernelly
                        </Typography>
                    </div>
                    <List>
                        {menuConfig.map((module, index) => {
                            const IconComponent = iconsMap[module.icon];
                            return (
                                <Accordion
                                    key={index}
                                    open={open === index + 1}
                                    icon={
                                        module.submodules.length > 0 ? (
                                            <ChevronDownIcon
                                                strokeWidth={2.5}
                                                className={`mx-auto h-4 w-4 transition-transform ${open === index + 1 ? "rotate-180" : ""
                                                    }`}
                                            />
                                        ) : null
                                    }
                                >
                                    <ListItem className="p-0 rounded-lg" selected={open === index + 1} onClick={(e) => {
                                        if (module.submodules?.length === 0) {
                                            setIsDrawerOpen(false)
                                        }

                                        e.preventDefault();
                                        navigate(module?.ruta)
                                    }}>
                                        <AccordionHeader
                                            onClick={(e) => { module?.action === 'logout' ? setShowModal(true) : handleOpen(index + 1) }}
                                            className="border-b-0 p-3 rounded-lg" // Added rounded-lg for rounded corners
                                        >
                                            <ListItemPrefix>
                                                <IconComponent className="h-5 w-5" />
                                            </ListItemPrefix>
                                            <Typography
                                                color="blue-gray"
                                                className="mr-auto font-normal"
                                            >
                                                {module.title}
                                            </Typography>
                                        </AccordionHeader>
                                    </ListItem>
                                    {module.submodules.length > 0 && (
                                        <AccordionBody className="py-1">
                                            <List className="p-0">
                                                {module.submodules.map((submodule, subIndex) => {
                                                    const SubIconComponent = iconsMap[submodule.icon];
                                                    return (
                                                        <ListItem key={subIndex} onClick={(e) => {
                                                            setIsDrawerOpen(false);
                                                            e.preventDefault();
                                                            navigate(submodule?.ruta)
                                                        }}>
                                                            <ListItemPrefix>
                                                                <SubIconComponent
                                                                    strokeWidth={3}
                                                                    className="h-3 w-5"
                                                                />
                                                            </ListItemPrefix>
                                                            {submodule.title}
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </AccordionBody>
                                    )}
                                </Accordion>
                            );
                        })}
                    </List>
                </Card>
            </Drawer>
            {closeModal()}
        </>
    );
}
