export const PEDIDOS_LIST_REQUEST = "PEDIDOS_LIST_REQUEST";
export const PEDIDOS_LIST_SUCCESS = "PEDIDOS_LIST_SUCCESS";
export const PEDIDOS_LIST_FAIL = "PEDIDOS_LIST_FAIL";
export const PEDIDOS_LIST_RESET = "PEDIDOS_LIST_RESET";

export const PEDIDOS_CREATE_REQUEST = "PEDIDOS_CREATE_REQUEST";
export const PEDIDOS_CREATE_SUCCESS = "PEDIDOS_CREATE_SUCCESS";
export const PEDIDOS_CREATE_FAIL = "PEDIDOS_CREATE_FAIL";

export const PEDIDOS_DETAILS_REQUEST = "PEDIDOS_DETAILS_REQUEST";
export const PEDIDOS_DETAILS_SUCCESS = "PEDIDOS_DETAILS_SUCCESS";
export const PEDIDOS_DETAILS_FAIL = "PEDIDOS_DETAILS_FAIL";
export const PEDIDOS_DETAILS_RESET = "PEDIDOS_DETAILS_RESET";

export const PEDIDOS_UPDATE_REQUEST = "PEDIDOS_UPDATE_REQUEST";
export const PEDIDOS_UPDATE_SUCCESS = "PEDIDOS_UPDATE_SUCCESS";
export const PEDIDOS_UPDATE_FAIL = "PEDIDOS_UPDATE_FAIL";
export const PEDIDOS_UPDATE_RESET = "PEDIDOS_UPDATE_RESET";

export const PEDIDOS_DELETE_REQUEST = "PEDIDOS_DELETE_REQUEST";
export const PEDIDOS_DELETE_SUCCESS = "PEDIDOS_DELETE_SUCCESS";
export const PEDIDOS_DELETE_FAIL = "PEDIDOS_DELETE_FAIL";
export const PEDIDOS_DELETE_RESET = "PEDIDOS_DELETE_RESET";

export const PEDIDOS_ACTIVATE_REQUEST = "PEDIDOS_ACTIVATE_REQUEST";
export const PEDIDOS_ACTIVATE_SUCCESS = "PEDIDOS_ACTIVATE_SUCCESS";
export const PEDIDOS_ACTIVATE_FAIL = "PEDIDOS_ACTIVATE_FAIL";
export const PEDIDOS_ACTIVATE_RESET = "PEDIDOS_ACTIVATE_RESET";

export const PEDIDOS_INFOUSER_REQUEST = "PEDIDOS_INFOUSER_REQUEST";
export const PEDIDOS_INFOUSER_SUCCESS = "PEDIDOS_INFOUSER_SUCCESS";
export const PEDIDOS_INFOUSER_FAIL = "PEDIDOS_INFOUSER_FAIL";
export const PEDIDOS_INFOUSER_RESET = "PEDIDOS_INFOUSER_RESET";