import axios from "axios";
import {
  CARRITO_CREATE_FAIL,
  CARRITO_CREATE_REQUEST,
  CARRITO_CREATE_SUCCESS,
  CARRITO_DELETE_FAIL,
  CARRITO_DELETE_REQUEST,
  CARRITO_DELETE_SUCCESS,
  CARRITO_DETAILS_FAIL,
  CARRITO_DETAILS_REQUEST,
  CARRITO_DETAILS_RESET,
  CARRITO_DETAILS_SUCCESS,
  CARRITO_LIST_FAIL,
  CARRITO_LIST_REQUEST,
  CARRITO_LIST_SUCCESS,
  CARRITO_ACTIVATE_REQUEST,
  CARRITO_ACTIVATE_SUCCESS,
  CARRITO_ACTIVATE_FAIL,
  CARRITO_UPDATE_REQUEST,
  CARRITO_UPDATE_SUCCESS,
  CARRITO_UPDATE_FAIL,
} from "../../constantes/carritoConstants";
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import Swal from 'sweetalert2';
import { AlertNoti } from "../../componentes/AlertNoti";

export const carritoListaRegistros = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARRITO_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/carrito/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: CARRITO_LIST_SUCCESS,
      payload: data,
    });

  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: CARRITO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const activarCarrito = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARRITO_ACTIVATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    await axios.put(URL_SERVER + `/carrito/activar/${id}`, id, config);
    dispatch({
      type: CARRITO_ACTIVATE_SUCCESS,
    });
    dispatch(carritoListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      color:'white',
      background:'green',
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: "Se activo el carrito"
    });
  } catch (error) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      color:'white',
      background:'red',
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: "Error al activar carrito"
    });
    dispatch({
      type: CARRITO_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const actualizarCarrito = (carrito) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARRITO_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    await axios.put(URL_SERVER + `/carrito/actualizar`, carrito, config);
    dispatch({
      type: CARRITO_UPDATE_SUCCESS,
    });
    dispatch(carritoListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      color:'white',
      background:'green',
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: "Se actualizo el carrito"
    });
  } catch (error) {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-start",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      color:'white',
      background:'red',
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: "success",
      title: "Error al actualizar carrito"
    });
    dispatch({
      type: CARRITO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCarrito = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARRITO_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    const data = await axios.delete(URL_SERVER + `/carrito/eliminar/${id}`, config);
    dispatch({
      type: CARRITO_DELETE_SUCCESS,
    });
    dispatch(carritoListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10
    }))
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Carrito inactivado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al eliminar carrito' })
    dispatch({
      type: CARRITO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const agregarAlCarrito = (carrito) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARRITO_CREATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };

    //update product
    const { data } = await axios.post(
      URL_SERVER + `/carrito/agregardetalle`,
      carrito,
      config
    );
    Swal.fire({
      title: "Añadido",
      text: "El producto se agrego al carrito",
      icon: "success"
    });
    dispatch({
      type: CARRITO_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "Error al agregar producto al carrito",
      icon: "error"
    });
    dispatch({
      type: CARRITO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listCarritoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CARRITO_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/carrito/obtener/${id}`, config);
    dispatch({
      type: CARRITO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CARRITO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listCarritoClienteDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CARRITO_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/carrito/listar_cliente`, config);
    dispatch({
      type: CARRITO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CARRITO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: CARRITO_DETAILS_RESET });
};