import {
    PREDICCION_PRODUCTOS_LIST_RESET,
    PREDICCION_PRODUCTOS_LIST_FAIL,
    PREDICCION_PRODUCTOS_LIST_REQUEST,
    PREDICCION_PRODUCTOS_LIST_SUCCESS,
} from '../constantes/dataPrediccionConstants'
export const prediccionProductosReducer = (
    state = { loading: true, data_prediccion: [] },
    action
) => {
    switch (action.type) {
        case PREDICCION_PRODUCTOS_LIST_REQUEST:
            return { loading: true, data_prediccion: [] };
        case PREDICCION_PRODUCTOS_LIST_SUCCESS:
            return {
                loading: false,
                data_prediccion: action.payload.predicciones,
            };
        case PREDICCION_PRODUCTOS_LIST_FAIL:
            return { loading: false, error: action.payload };
        case PREDICCION_PRODUCTOS_LIST_RESET:
            return { data_prediccion: [] };
        default:
            return state;
    }
};