export function validarEmail(email) {
    const regex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  export function validarPassword(password) {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
  }

  export function validarCadenaConLongitud(str, min, max) {
    // Expresión regular para verificar si la cadena está vacía o tiene solo espacios en blanco
    const isEmpty = /^\s*$/;
    if (isEmpty.test(str)) {
        return false;
    }

    // Verificar si la longitud de la cadena está dentro del rango permitido
    const length = str.length;
    if (length < min || length > max) {
        return false;
    }
    return true;
}
export function validarPrecio(cadena) {
  const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
  return regex.test(cadena) && parseFloat(cadena) !== 0;
}


export function validarEntero(cadena) {
  const regex = /^-?\d*$/;
  return regex.test(cadena);
}

export function validarCadenaSoloLetras(cadena) {
  const regex = /^[A-Za-z]+$/;
  return cadena && regex.test(cadena);
}
export function validarCelular(cadena) {
  const regex = /^\d{0,10}$/;
  regex.test(cadena);
}
