export const CARRITO_LIST_REQUEST = "CARRITO_LIST_REQUEST";
export const CARRITO_LIST_SUCCESS = "CARRITO_LIST_SUCCESS";
export const CARRITO_LIST_FAIL = "CARRITO_LIST_FAIL";
export const CARRITO_LIST_RESET = "CARRITO_LIST_RESET";

export const CARRITO_CREATE_REQUEST = "CARRITO_CREATE_REQUEST";
export const CARRITO_CREATE_SUCCESS = "CARRITO_CREATE_SUCCESS";
export const CARRITO_CREATE_FAIL = "CARRITO_CREATE_FAIL";

export const CARRITO_DETAILS_REQUEST = "CARRITO_DETAILS_REQUEST";
export const CARRITO_DETAILS_SUCCESS = "CARRITO_DETAILS_SUCCESS";
export const CARRITO_DETAILS_FAIL = "CARRITO_DETAILS_FAIL";
export const CARRITO_DETAILS_RESET = "CARRITO_DETAILS_RESET";

export const CARRITO_UPDATE_REQUEST = "CARRITO_UPDATE_REQUEST";
export const CARRITO_UPDATE_SUCCESS = "CARRITO_UPDATE_SUCCESS";
export const CARRITO_UPDATE_FAIL = "CARRITO_UPDATE_FAIL";
export const CARRITO_UPDATE_RESET = "CARRITO_UPDATE_RESET";

export const CARRITO_DELETE_REQUEST = "CARRITO_DELETE_REQUEST";
export const CARRITO_DELETE_SUCCESS = "CARRITO_DELETE_SUCCESS";
export const CARRITO_DELETE_FAIL = "CARRITO_DELETE_FAIL";
export const CARRITO_DELETE_RESET = "CARRITO_DELETE_RESET";

export const CARRITO_ACTIVATE_REQUEST = 'CARRITO_ACTIVATE_REQUEST';
export const CARRITO_ACTIVATE_SUCCESS = 'CARRITO_ACTIVATE_SUCCESS';
export const CARRITO_ACTIVATE_FAIL = 'CARRITO_ACTIVATE_FAIL';
export const CARRITO_ACTIVATE_RESET = 'CARRITO_ACTIVATE_RESET';