import React from "react";
import { useDispatch } from "react-redux";
import { USER_LOGOUT, USER_DETAILS_RESET } from "../constantes/loginConstants";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useLocation } from 'react-router-dom';

const ModalClose = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { showModal, setShowModal, cabecera, cuerpo, confirmacion, salida } = props
    return (
        <>
            <Dialog size="sm" open={showModal} handler={() => setShowModal(false)}>
                <DialogHeader toggler={() => setShowModal(false)}>
                    {cabecera}
                </DialogHeader>
                <DialogBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {cuerpo}
                    </p>
                </DialogBody>
                <DialogFooter>
                    <Button
                        color="black"
                        buttonType="link"
                        onClick={(e) => setShowModal(false)}
                        ripple={false}

                    >
                        {salida}
                    </Button>

                    <Button
                        color="red"
                        onClick={async (e) => {
                            e.preventDefault();
                            localStorage.removeItem("userInfo");
                            dispatch({ type: USER_LOGOUT });
                            dispatch({ type: USER_DETAILS_RESET });
                            dispatch({ type: USER_LOGOUT });

                            if (location.pathname !== '/home') {
                                navigate("/home");
                                window.location.reload();
                            }
                            else window.location.reload();

                            setShowModal(false)
                        }}
                        ripple={true}
                    >
                        {confirmacion}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default ModalClose;