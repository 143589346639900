import axios from "axios";
import {
  CONTACTO_LIST_REQUEST,
  CONTACTO_LIST_SUCCESS,
  CONTACTO_LIST_FAIL,
  CONTACTO_DETAILS_REQUEST,
  CONTACTO_DETAILS_SUCCESS,
  CONTACTO_DETAILS_FAIL,
  CONTACTO_DETAILS_RESET,
  CONTACTO_CREATE_REQUEST,
  CONTACTO_CREATE_SUCCESS,
  CONTACTO_CREATE_FAIL
} from "../../constantes/contactoConstants";
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import Swal from "sweetalert2";
import { AlertNoti } from "../../componentes/AlertNoti";

export const contactoListaRegistros = (parameters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACTO_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/contacto/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: CONTACTO_LIST_SUCCESS,
      payload: data,
    });

  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: CONTACTO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const enviarMensajeContacto = (contacto) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTACTO_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };

    const { data } = await axios.post(
      URL_SERVER + "/contacto/enviarmensaje",
      contacto,
      config
    );
    dispatch({
      type: CONTACTO_CREATE_SUCCESS,
      payload: data,
    });
    Swal.fire({
      title: "Enviado",
      text: "Mensaje enviado",
      icon: "success"
    });
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "Error al enviar mensaje",
      icon: "error"
    });
    console.log(error);
    dispatch({
      type: CONTACTO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listContactoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACTO_DETAILS_REQUEST });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };
    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/contacto/obtener/${id}`, config);
    dispatch({
      type: CONTACTO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACTO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: CONTACTO_DETAILS_RESET });
};