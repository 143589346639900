import { useState, useEffect } from 'react'
import { ChevronDown, ChevronUp, Package, ChevronLeft, ChevronRight } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { listPedidosInfoUser } from '../../actions/pedidos/pedidosActions'
import { formatDate } from '../../utils/utils';

export default function InfoPedidosScreen() {
    const dispatch = useDispatch();
    const [pedidos, setPedidos] = useState([])
    const [paginaActual, setPaginaActual] = useState(1)
    const [totalPaginas, setTotalPaginas] = useState(1)
    const [limitPage, setLimitPage] = useState(5)
    const [valorBusqueda, setValorBusqueda] = useState('')
    const [pedidoExpandido, setPedidoExpandido] = useState(null)
    const [cargando, setCargando] = useState(false)
    const { loading, page, pages, pedidos_infouser } = useSelector((state) => state.pedidoinfoUserR);

    useEffect(() => {
        const cargarPedidos = async () => {
            setCargando(true)
            try {
                dispatch(listPedidosInfoUser({ page: paginaActual, limitPage, valorBusqueda }))
                setTotalPaginas(pages)
            } catch (error) {
                console.error('Error al cargar los pedidos:', error)
            } finally {
                setCargando(false)
            }
        }

        cargarPedidos()
    }, [paginaActual]);


    const togglePedido = (id) => {
        setPedidoExpandido(prevId => prevId === id ? null : id)
    }

    // Cambiar la página
    const cambiarPagina = (nuevaPagina) => {
        if (nuevaPagina >= 1 && nuevaPagina <= pages) {
            setPaginaActual(nuevaPagina);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-pink-100 to-purple-200 p-8">
            <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold text-center mb-8 text-purple-800">Mis Pedidos</h1>
                {loading ? (
                    <div className="text-center text-purple-600">Cargando pedidos...</div>
                ) : (
                    pedidos_infouser?.length > 0 ? (
                        <>
                            <div className="space-y-4 mb-6">
                                {pedidos_infouser?.map((pedido) => (
                                    <div key={pedido.orden_codigo} className="bg-white rounded-lg shadow-md overflow-hidden">
                                        <div
                                            className="p-4 cursor-pointer flex justify-between items-center bg-gradient-to-r from-pink-400 to-purple-500 text-white"
                                            onClick={() => togglePedido(pedido.orden_codigo)}
                                        >
                                            <div className="flex items-center space-x-4">
                                                <Package className="h-6 w-6" />
                                                <span className="font-semibold">Pedido #{pedido.orden_codigo}</span>
                                                <span className="text-sm font-medium">Estado: {pedido.estado}</span>
                                            </div>
                                            <div className="flex items-center space-x-4">
                                                <span>{formatDate(pedido.fecha_ingreso)}</span>
                                                {pedidoExpandido === pedido.orden_codigo ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
                                            </div>
                                        </div>
                                        <div className={`transition-all duration-300 ease-in-out ${pedidoExpandido === pedido.orden_codigo ? 'max-h-screen opacity-100 p-4' : 'max-h-0 opacity-0 p-0 overflow-hidden'}`}>
                                            {pedidoExpandido === pedido.orden_codigo && (
                                                <div className="p-4">
                                                    {/*<p className="text-purple-700 font-semibold mb-2">Estado: {pedido.estado}</p>*/}
                                                    <p className="text-purple-700 font-semibold mb-2">Total: ${parseFloat(pedido.total).toFixed(2)}</p>
                                                    <h3 className="text-purple-800 font-semibold mt-4 mb-2">Productos:</h3>
                                                    <ul className="list-disc list-inside text-purple-600">
                                                        {pedido.detalles.map((detalle, index) => (
                                                            <li key={index}>
                                                            {detalle.producto_nombre} (x{detalle.cantidad}) - 
                                                            <span className="ml-2 font-semibold text-purple-800">${parseFloat(detalle.precio).toFixed(2)}</span>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Paginación */}
                            {pages > 1 && (
                                <div className="flex justify-center items-center space-x-4">
                                    <button
                                        onClick={() => cambiarPagina(paginaActual - 1)}
                                        disabled={paginaActual === 1}
                                        className="p-2 rounded-full bg-purple-500 text-white disabled:bg-purple-300"
                                    >
                                        <ChevronLeft className="h-6 w-6" />
                                    </button>
                                    <span className="text-purple-800 font-semibold">
                                        Página {page} de {pages}
                                    </span>
                                    <button
                                        onClick={() => cambiarPagina(paginaActual + 1)}
                                        disabled={paginaActual === pages}
                                        className="p-2 rounded-full bg-purple-500 text-white disabled:bg-purple-300"
                                    >
                                        <ChevronRight className="h-6 w-6" />
                                    </button>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="text-center text-purple-600">No hay pedidos para mostrar</div>
                    )
                )}

            </div>
        </div>
    )
}
