import axios from "axios";

import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_FAIL,
    USER_LOGIN_SUCCESS,
    USER_RECUP_PASS_REQUEST,
    USER_RECUP_PASS_SUCCESS,
    USER_RECUP_PASS_FAIL,
} from "../../constantes/loginConstants";
import { URL_SERVER } from '../../constantes/serverUrl'
import Swal from 'sweetalert2';

export const login = (credenciales) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.post(
            URL_SERVER + "/login/",
            { ...credenciales?.data },
            config
        );
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });
        localStorage.setItem("userInfo", JSON.stringify(data));
        window.location.reload();

    } catch (error) {
        Swal.fire({
            title: "Error",
            text: "Error o contraseña incorrecta",
            icon: "error"
        });
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
export const recuperarContra = (credenciales) => async (dispatch) => {
    try {
        dispatch({
            type: USER_RECUP_PASS_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        //genera y manda el link de recuperacion de clave al correo
        const { data } = await axios.post(
            URL_SERVER + "/login/recuperarPass",
            { ...credenciales },
            config
        );
        dispatch({
            type: USER_RECUP_PASS_SUCCESS,
            payload: data,
        });

        localStorage.setItem("userInfo", JSON.stringify(data));

    } catch (error) {
        dispatch({
            type: USER_RECUP_PASS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
export const cambairClave = (parameters) => async (dispatch, getState) => {
    try {
        //get user from state
        const {
            userLogin: { userInfo },
        } = getState();

        //headers
        const config = {
            headers: {
                token: userInfo.token,
            },
        };

        const { data } = await axios.put(
            URL_SERVER + "/login/cambiar_clave",
            { ...parameters },
            config
        );

    } catch (error) {
        console.log(error);
    }
};
export const cambioClavePorRecuperacion = (parameters) => async (dispatch) => {
    try {
        const { data } = await axios.put(
            URL_SERVER + "/login/cmabiarPorRecup",
            { ...parameters }
        );

    } catch (error) {
        console.log(error);
    }
};
export const confirmarRegistro = (parameters) => async (dispatch) => {
    try {
        const { data } = await axios.put(
            URL_SERVER + "/usuarios/confirmLink",
            { ...parameters }
        );

    } catch (error) {
        console.log(error);
    }
};

export const validToken = (parameters) => async (dispatch, getState) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
        };
        const { data } = await axios.get(
            URL_SERVER +
            `/login/validToken/${parameters.token}`,
            config
        );
        parameters.setTokenValid(data.valid);
    } catch (error) {
        console.log(error);
    }
};
export const validTokenConfirm = (parameters) => async (dispatch, getState) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json"
            },
        };
        const { data } = await axios.get(
            URL_SERVER +
            `/login/validTokenconfirm/${parameters.token}`,
            config
        );
        parameters.setTokenValid(data.valid);
    } catch (error) {
        console.log(error);
    }
};

