import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cambairClave, cambioClavePorRecuperacion, confirmarRegistro, validToken, validTokenConfirm } from '../../actions/autenticacion/loginActions';
import { useDispatch } from "react-redux";
import "./PasswordInput.css";
import { Button, Input, Typography } from '@material-tailwind/react';

const ConfirmRegistroScreen = () => {
  const { token } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokenValid, setTokenValid] = useState(false);
  useEffect(() => {
    dispatch(validTokenConfirm({ token, setTokenValid }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(confirmarRegistro({ token }));
    navigate("/home");
  };

  if (!tokenValid) {
    return <div>Token inválido</div>;
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full p-10 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-800 md:text-2xl dark:text-white">
            Confirmar Registro
          </h2>
          <form className="mt-4 space-y-4 lg:mt-4 md:space-y-5" onSubmit={handleSubmit}>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bienvenido a Vernelly detalles</label>
            <Button color="purple" type="submit">Confirmar</Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ConfirmRegistroScreen;
