import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Select,
    Option,
    DialogHeader,
    DialogBody,
    DialogFooter
} from "@material-tailwind/react";
import { validarCadenaConLongitud, validarPrecio } from "../../../funfiones/validaciones";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProductos, listProductosDetails, updateProductos } from "../../../actions/productos/productosActions";
import { AlertCustom } from "../../../componentes/AlertCustom";
import { listaComboCategoria } from "../../../actions/categorias/categoriasActions";

export function ProductForm(props) {
    const { open, setOpen, setOkCreate, setMensaje, tipo = 'Crear', idProduct = null } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpen = () => setOpen((cur) => !cur);
    const [nombre, setNombre] = React.useState(null);
    const [id_categoria, setIdCategoria] = React.useState(null);
    const [codigoP, setCodigoP] = React.useState(null);
    const [precio, setPrecio] = React.useState(null);
    const [observacion, setObservacion] = React.useState(null);
    const [observaciones, setObservaciones] = React.useState([]);
    const [file, setFile] = React.useState(null);
    const [categoriasComboList, setCategoriasComboList] = React.useState([]);

    const [aviso_nombre, setAvisoNombre] = React.useState(tipo === 'Crear' ? " " : "");
    const [aviso_id_categoria, setAvisoIdCategoria] = React.useState(tipo === 'Crear' ? " " : "");
    const [aviso_codigoP, setAvisoCodigoP] = React.useState(tipo === 'Crear' ? " " : "");
    const [aviso_precio, setAvisoPrecio] = React.useState(tipo === 'Crear' ? " " : "");
    const [aviso_observacion, setAvisoObservacion] = React.useState(tipo === 'Crear' ? " " : "");
    const [aviso_file, setAvisoFile] = React.useState(tipo === 'Crear' ? " " : "");

    const guardarProducto = (credenciales) => dispatch(createProductos(credenciales));
    const actualizarProducto = (credenciales) => dispatch(updateProductos(credenciales));
    const comboCategorias = useSelector((state) => state.comboCategoriaListR);
    const productDataEdit = useSelector((state) => state.productosDetailsR);

    useEffect(() => {
        dispatch(listaComboCategoria({ navigate }));
        if (tipo === 'Editar') {
            dispatch(listProductosDetails(idProduct));
        } else {
            setNombre(null);
            setIdCategoria(null);
            setCodigoP(null);
            setPrecio(null);
            setObservacion(null);
            setFile(null);
            setObservaciones([]);
        }
    }, [dispatch, navigate, tipo, idProduct]);

    useEffect(() => {
        if (comboCategorias) {
            setCategoriasComboList(comboCategorias?.combo_categoria);
        }
    }, [comboCategorias]);

    useEffect(() => {
        if (productDataEdit && idProduct && tipo === 'Editar') {
            setNombre(productDataEdit?.producto?.result[0]?.nombre || null);
            setIdCategoria(productDataEdit?.producto?.result[0]?.id_categoria || null);
            setCodigoP(productDataEdit?.producto?.result[0]?.codigox || null);
            setObservacion(productDataEdit?.producto?.result[0]?.observacion || null);
            setPrecio(productDataEdit?.producto?.result[0]?.precio || null);
            setObservaciones(productDataEdit?.producto?.result[0]?.observacion?.split('\n') || []);
        }
    }, [productDataEdit, idProduct, tipo]);

    function validarNombre(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 100)) {
            setNombre(e.target.value);
            setAvisoNombre('');
        } else {
            setAvisoNombre('Ingrese nombre');
            setNombre('');
        }
    }
    function validarCategoria(e) {
        setIdCategoria(e.toString());
        setAvisoIdCategoria('');
    }
    function validarCodigoP(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 100)) {
            setCodigoP(e.target.value);
            setAvisoCodigoP('');
        } else {
            setAvisoCodigoP('Ingrese código');
            setCodigoP('');
        }
    }
    function validarPrecioInicial(e) {
        if (validarPrecio(e.target.value)) {
            setPrecio(e.target.value);
            setAvisoPrecio('');
        } else {
            setAvisoPrecio('Ingrese precio');
            setPrecio('');
        }
    }
    function validarImagen(e) {
        setFile(e.target.files[0]);
    }
    const handleAddObservacion = () => {
        if (observacion.trim()) {
            setObservaciones([...observaciones, observacion]);
            setObservacion('');
        }
    };
    const handleRemoveObservacion = (index) => {
        setObservaciones(observaciones.filter((_, i) => i !== index));
    };
    const hanleCrearProducto = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append("nombre", nombre);
        formData.append("id_categoria", id_categoria);
        formData.append("codigoP", codigoP);
        formData.append("precio", precio);
        formData.append("observacion", observaciones.join('\n'));
        if (tipo === 'Crear') {
            guardarProducto({ data: formData, setOkCreate, setMensaje });
        } else {
            formData.append("id", idProduct);
            actualizarProducto({ data: formData, setOkCreate, setMensaje });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                handler={handleOpen}
                className="bg-white shadow-none max-w-[90vw] sm:max-w-[60vw] md:max-w-[50vw]"
            >
                <DialogHeader className="justify-between">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                            {tipo} producto
                        </Typography>
                        <Typography color="gray" variant="paragraph">
                            {tipo === 'Crear' ? 'Ingrese los datos del nuevo producto' : 'Edite los datos del producto'}
                        </Typography>
                    </div>
                </DialogHeader>
                <DialogBody className="overflow-y-auto px-5">
                    <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Input label="Nombre" value={nombre} size="lg" onChange={validarNombre} />
                            <label className="text-red-500">{aviso_nombre}</label>
                        </div>
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Select label="Categoría" size="lg" onChange={(e) => validarCategoria(e)}>
                                {categoriasComboList.map((element) => (
                                    <Option key={element?.id} value={element?.id?.toString()}>
                                        {element?.nombre}
                                    </Option>
                                ))}
                            </Select>
                            <label className="text-red-500">{aviso_id_categoria}</label>
                        </div>
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Input label="Código personalizado" value={codigoP} size="lg" onChange={validarCodigoP} />
                            <label className="text-red-500">{aviso_codigoP}</label>
                        </div>
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Input label="Precio" value={precio} size="lg" type="number" onChange={validarPrecioInicial} />
                            <label className="text-red-500">{aviso_precio}</label>
                        </div>
                        <div className="w-full px-3 mt-8">
                            <Typography variant="small" color="gray">Observaciones (características)</Typography>
                            <div className="flex space-x-2 mt-2">
                                <Input
                                    label="Agregar característica"
                                    value={observacion}
                                    onChange={(e) => setObservacion(e.target.value)}
                                    size="lg"
                                />
                                <Button onClick={handleAddObservacion} className="bg-blue-500">Agregar</Button>
                            </div>
                            <ul className="mt-4 list-disc pl-5">
                                {observaciones.map((obs, index) => (
                                    <li key={index} className="flex justify-between">
                                        <span>{obs}</span>
                                        <Button color="red" size="sm" onClick={() => handleRemoveObservacion(index)}>Eliminar</Button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Input label="Imagen" size="lg" type="file" onChange={validarImagen} />
                            {/* Puedes mostrar la imagen aquí si es necesario */}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <div className="w-full flex flex-wrap -mx-3 mb-3">
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Button className="bg-gray-600 w-full" onClick={(e) => { e.preventDefault(); setOpen(false); }}>
                                Cancelar
                            </Button>
                        </div>
                        <div className="w-full sm:w-1/2 px-3 mt-8">
                            <Button variant="gradient" className="w-full" onClick={hanleCrearProducto}>
                                {tipo === 'Crear' ? 'Guardar' : tipo}
                            </Button>
                        </div>
                    </div>
                </DialogFooter>
            </Dialog>
            <AlertCustom hexBorder={'#2ec946'} hexBG={'#2ec946'} hexText={'#2ec946'} />
        </>
    );
}
