import {
    DASHBOARD_FAIL,
    DASHBOARD_REQUEST,
    DASHBOARD_RESET,
    DASHBOARD_SUCCESS,
} from '../constantes/dashboardConstants'


export const dashboardDetailsReducer = (state = { dashboard: {} }, action) => {
    switch (action.type) {
        case DASHBOARD_REQUEST:
            return { ...state, loading: true };
        case DASHBOARD_SUCCESS:
            return { loading: false, dashboard: action.payload };
        case DASHBOARD_FAIL:
            return { loading: false, error: action.payload };
        case DASHBOARD_RESET:
            return { dashboard: {} };
        default:
            return state;
    }
};