export const USUARIO_LIST_REQUEST = "USUARIO_LIST_REQUEST";
export const USUARIO_LIST_SUCCESS = "USUARIO_LIST_SUCCESS";
export const USUARIO_LIST_FAIL = "USUARIO_LIST_FAIL";
export const USUARIO_LIST_RESET = "CUSUARIO_LIST_RESET";

export const USUARIO_CREATE_REQUEST = "USUARIO_CREATE_REQUEST";
export const USUARIO_CREATE_SUCCESS = "USUARIO_CREATE_SUCCESS";
export const USUARIO_CREATE_FAIL = "USUARIO_CREATE_FAIL";

export const USUARIO_REGISTRO_REQUEST = "USUARIO_REGISTRO_REQUEST";
export const USUARIO_REGISTRO_SUCCESS = "USUARIO_REGISTRO_SUCCESS";
export const USUARIO_REGISTRO_FAIL = "USUARIO_REGISTRO_FAIL";

export const USUARIO_DETAILS_REQUEST = "USUARIO_DETAILS_REQUEST";
export const USUARIO_DETAILS_SUCCESS = "USUARIO_DETAILS_SUCCESS";
export const USUARIO_DETAILS_FAIL = "USUARIO_DETAILS_FAIL";
export const USUARIO_DETAILS_RESET = "USUARIO_DETAILS_RESET";

export const USUARIO_UPDATE_REQUEST = "USUARIO_UPDATE_REQUEST";
export const USUARIO_UPDATE_SUCCESS = "USUARIO_UPDATE_SUCCESS";
export const USUARIO_UPDATE_FAIL = "USUARIO_UPDATE_FAIL";
export const USUARIO_UPDATE_RESET = "USUARIO_UPDATE_RESET";

export const USUARIO_DELETE_REQUEST = "USUARIO_DELETE_REQUEST";
export const USUARIO_DELETE_SUCCESS = "USUARIO_DELETE_SUCCESS";
export const USUARIO_DELETE_FAIL = "USUARIO_DELETE_FAIL";
export const USUARIO_DELETE_RESET = "USUARIO_DELETE_RESET";

export const USUARIO_ACTIVATE_REQUEST = "USUARIO_ACTIVATE_REQUEST";
export const USUARIO_ACTIVATE_SUCCESS = "USUARIO_ACTIVATE_SUCCESS";
export const USUARIO_ACTIVATE_FAIL = "USUARIO_ACTIVATE_FAIL";
export const USUARIO_ACTIVATE_RESET = "USUARIO_ACTIVATE_RESET";