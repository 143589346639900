import React, { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { validarCadenaConLongitud, validarEmail, validarPassword, validarCadenaSoloLetras, validarEntero } from "../funfiones/validaciones";
import { registrarUsuario } from "../actions/usuarios/usuarioActions";
import { useDispatch } from "react-redux";

export function RegisterCard({ open, setOpen, setOpenLogin, setShowModalConfirmacion, setCuerpo, setCabecera }) {
  const dispatch = useDispatch();
  const [correo, setCorreo] = useState('');
  const [password, setPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [celular, setCelular] = useState('');
  const [repetirPassword, setRepetirPassword] = useState('');

  const [aviso_correo, setAvisoCorreo] = useState(' ');
  const [aviso_password, setAvisoPassword] = useState(' ');
  const [aviso_nombre, setAvisoNombre] = useState(' ');
  const [aviso_apellido, setAvisoApellido] = useState(' ');
  const [aviso_celular, setAvisoCelular] = useState(' ');
  const [aviso_repetirPassword, setAvisoRepetirPassword] = useState(' ');

  const handleOpen = () => setOpen((cur) => !cur);
  const registrarUser = (credenciales) => dispatch(registrarUsuario(credenciales));

  function validarCampos() {
    if (
      aviso_correo?.length > 0 ||
      aviso_password?.length > 0 ||
      aviso_nombre?.length > 0 ||
      aviso_apellido?.length > 0 ||
      aviso_celular?.length > 0 ||
      aviso_repetirPassword?.length > 0
    ) {
      aviso_correo?.length > 0 ? setAvisoCorreo('Campo invalido o vacio') : setAvisoCorreo('');
      aviso_password?.length > 0 ? setAvisoPassword('Campo invalido o vacio') : setAvisoPassword('');
      aviso_nombre?.length > 0 ? setAvisoNombre('Campo invalido o vacio') : setAvisoNombre('');
      aviso_apellido?.length > 0 ? setAvisoApellido('Campo invalido o vacio') : setAvisoApellido('');
      aviso_celular?.length > 0 ? setAvisoCelular('Campo invalido o vacio') : setAvisoCelular('');
      aviso_repetirPassword?.length > 0 ? setAvisoRepetirPassword('Las contraseñas no coinciden') : setAvisoRepetirPassword('');
      return false;
    } else {
      return true;
    }
  }

  function validarCorreo(e) {
    if (validarEmail(e.target.value)) {
      setAvisoCorreo('');
    } else {
      setAvisoCorreo('Campo invalido o vacio');
    }
    setCorreo(e.target.value);
  }

  function validarNombre(e) {
    if (validarCadenaConLongitud(e.target.value, 1, 200)) {
      setAvisoNombre('');
    } else {
      setAvisoNombre('Campo invalido o vacio');
    }
    setNombre(e.target.value);
  }

  function validarApellido(e) {
    if (validarCadenaConLongitud(e.target.value, 1, 200)) {
      setAvisoApellido('');
    } else {
      setAvisoApellido('Campo invalido o vacio');
    }
    setApellido(e.target.value);
  }

  function validarCelular(e) {
    if (validarEntero(e.target.value)) {
      setAvisoCelular('');
      setCelular(e.target.value);
    } else {
      setAvisoCelular('Campo invalido o vacio');
    }
  }

  function validarPassw(e) {
    const passValue = e.target.value;
    if (validarCadenaConLongitud(passValue, 6, 16)) {
      setAvisoPassword('');
    } else {
      setAvisoPassword('Campo invalido o vacio');
    }
    setPassword(passValue);
  }

  function validarRepeatPassw(e) {
    if (e.target.value === password) {
      setAvisoRepetirPassword('');
    } else {
      setAvisoRepetirPassword('Las contraseñas no coinciden');
    }
    setRepetirPassword(e.target.value);
  }

  return (
    <>
      <Dialog
        size="md"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[400px] sm:max-w-[500px] lg:max-w-[600px]">
          <CardBody className="flex flex-col gap-4 max-h-[400px] overflow-y-auto">
            <Typography variant="h4" color="blue-gray">
              Registrarse
            </Typography>
            <Typography className="mb-3 font-normal" variant="paragraph" color="gray">
              Ingrese su correo y contraseña para registrarse
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Typography className="mb-2" variant="h6">
                  Tu nombre
                </Typography>
                <Input label="Nombre" size="lg" value={nombre} maxLength={200} color="purple" onChange={validarNombre} />
                <Typography color="red">{aviso_nombre}</Typography>
              </div>
              <div>
                <Typography className="mb-2" variant="h6">
                  Tu apellido
                </Typography>
                <Input label="Apellido" size="lg" value={apellido} maxLength={200} color="purple" onChange={validarApellido} />
                <Typography color="red">{aviso_apellido}</Typography>
              </div>
              <div>
                <Typography className="mb-2" variant="h6">
                  Celular
                </Typography>
                <Input label="Celular" size="lg" value={celular} maxLength={10} color="purple" onChange={validarCelular} />
                <Typography color="red">{aviso_celular}</Typography>
              </div>
              <div>
                <Typography className="mb-2" variant="h6">
                  Tu correo
                </Typography>
                <Input label="Correo" size="lg" value={correo} maxLength={100} color="purple" onChange={validarCorreo} />
                <Typography color="red">{aviso_correo}</Typography>
              </div>
              <div>
                <Typography className="mb-2" variant="h6">
                  Tu contraseña
                </Typography>
                <Input label="Contraseña" size="lg" value={password} maxLength={16} color="purple" type="password" onChange={validarPassw} />
                <Typography color="red">{aviso_password}</Typography>
              </div>
              <div>
                <Typography className="mb-2" variant="h6">
                  Repetir contraseña
                </Typography>
                <Input label="Contraseña" size="lg" value={repetirPassword} maxLength={16} color="purple" type="password" onChange={validarRepeatPassw} />
                <Typography color="red">{aviso_repetirPassword}</Typography>
              </div>
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              fullWidth
              className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
              onClick={(e) => {
                e.preventDefault();
                if (validarCampos()) {
                  setCabecera('Confirmar registro');
                  setCuerpo('Se envio un correo de confirmacion');
                  setShowModalConfirmacion(true);
                  registrarUser({
                    correo,
                    password,
                    nombre,
                    apellido,
                    celular
                  });
                  setOpen(false);
                }
              }}
            >
              Registrarse
            </Button>
            <Typography variant="small" className="mt-4 flex justify-center">
              ¿Ya tienes una cuenta?
              <Typography
                as="a"
                href="#signup"
                variant="small"
                color="purple"
                className="ml-1 font-bold"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                  setOpenLogin(true);
                }}
              >
                Inicia sesión
              </Typography>
            </Typography>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
