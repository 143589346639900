import React, { useEffect, useState } from 'react';
import { CheckBadgeIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    IconButton,
    Tooltip,
    Input,
    Select,
    Option,
} from "@material-tailwind/react";
import { ProductForm } from "./ProductoForm";
import { AlertCustom } from "../../../componentes/AlertCustom";
import { useDispatch, useSelector } from 'react-redux';
import { productosListaRegistros } from '../../../actions/productos/productosActions';
import ModalActivarDesactivar from '../../../componentes/ModalActivarDesactivar';
import { useNavigate } from 'react-router-dom';
import { getExcel } from '../../../actions/reportes/reportesActions';
import { listaComboCategoria } from '../../../actions/categorias/categoriasActions';


const TABLE_HEAD = ["Nombre", "Codigo", "Categoria", "Precio", "Observacion", "Estado", "Acciones"];

export function ProductList() {
    const useData = useSelector((state) => state.userLogin || null);
    const { error, userInfo } = useData;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openProductForm, setOpenProductForm] = useState(false);
    const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
    const [okCreate, setOkCreate] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [page, setPage] = useState(1);
    const [limitPage, setLimitPage] = useState('10');
    const [valorBusqueda, setValorBusqueda] = useState('');
    const [dataList, setDataList] = useState([]);
    const [tipoForm, setTipoForm] = useState('Crear');
    const [idProduct, setIdProduct] = useState(null);
    const [activar_eliminar, setActivarEliminar] = useState(null);
    const [id_categoria, setIdCategoria] = React.useState(0);
    const [categoriasComboList, setCategoriasComboList] = React.useState([]);

    const comboCategorias = useSelector((state) => state.comboCategoriaListR);
    const { loading, productos, pages, page: currentPage } = useSelector((state) => state.productosListR);

    useEffect(() => {
        if (userInfo && ![16, 22].includes(userInfo?.perfil)) {
            navigate("/home");
        }
    }, [userInfo, navigate]);

    const handleOpenProductForm = () => {
        setTipoForm('Crear')
        setOpenProductForm(!openProductForm);
    };

    const handlePageSizeChange = (e) => {
        setLimitPage(String(e));
        setPage(1);
    };

    const handleSearchChange = (e) => {
        setValorBusqueda(e.target.value || '');
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const getReport = async () => {
        dispatch(getExcel({
            value: Number(2),
            name: String('Reporte productos'),
        }));
    }

    function validarCategoria(e) {
        setIdCategoria(e);
    }

    useEffect(() => {
        if (okCreate) {
            setOpenProductForm(false);
            setTimeout(() => {
                setOkCreate(false);
            }, 2000);
        }
    }, [okCreate]);

    useEffect(() => {
        dispatch(productosListaRegistros({ page, limitPage, valorBusqueda, id_categoria }));
    }, [dispatch, page, limitPage, valorBusqueda, id_categoria]);

    useEffect(() => {
        if (productos && !loading) {
            setDataList(productos);
        }
    }, [productos, loading]);
    useEffect(() => {
        dispatch(listaComboCategoria({ navigate }))
    }, []);
    useEffect(() => {
        if (comboCategorias) {
            setCategoriasComboList(comboCategorias?.combo_categoria);
        }
    }, [comboCategorias]);

    return (
        <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                    <div>
                        <Typography variant="h5" color="blue-gray">
                            Productos
                        </Typography>
                    </div>
                    <div className="flex w-full shrink-0 gap-2 md:w-max relative">

                        <Button className="flex items-center gap-3 bg-purple-500" size="sm" onClick={getReport}>
                            <ArrowDownTrayIcon strokeWidth={2} className="h-4 w-4" /> Descargar reporte
                        </Button>
                        <Button className="flex items-center gap-3 bg-purple-500" size="sm" onClick={handleOpenProductForm}>
                            <PlusIcon strokeWidth={2} className="h-4 w-4" /> Agregar Producto
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <div className='flex w-full gap-2 mb-[1%] mt-[1%] items-center'>
                <div className="flex-shrink-0">
                    <Select value={limitPage} onChange={handlePageSizeChange}>
                        <Option key={1} value={'10'}>10 por pagina</Option>
                        <Option key={1} value={'30'}>30 por pagina</Option>
                        <Option key={1} value={'50'}>50 por pagina</Option>
                    </Select>
                </div>
                <div className="flex-shrink-0">
                    <Select label="Categoría" size="lg" onChange={(e) => validarCategoria(e)}>
                        <Option key={0} value='0'>
                            Todos
                        </Option>
                        {categoriasComboList.map((element, index) => (
                            <Option key={index} value={element?.id?.toString()}>
                                {element?.nombre}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="flex-grow w-1/2">
                    <Input
                        label="Buscar"
                        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-y border-blue-gray-100 bg-purple-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map(
                            (
                                {
                                    id,
                                    nombre,
                                    categoria_nombre,
                                    codigox,
                                    precio,
                                    observacion,
                                    estado,
                                },
                                index,
                            ) => {
                                const isLast = index === dataList.length - 1;
                                const classes = isLast
                                    ? "p-4"
                                    : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={id}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-bold"
                                                >
                                                    {nombre}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-bold"
                                            >
                                                {codigox}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {categoria_nombre}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {precio}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                <ul>
                                                    {Array.isArray(observacion) ? (
                                                        observacion.map((obser, index) => (
                                                            <li key={index}>- {obser}</li>
                                                        ))
                                                    ) : (
                                                        <span>{observacion}</span>
                                                    )}
                                                </ul>
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="w-max">
                                                <Chip
                                                    variant="ghost"
                                                    size="sm"
                                                    value={estado}
                                                    color={estado === 'ACTIVO' ? "green" : "red"}
                                                />
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Tooltip content="Editar categoria">
                                                <Button
                                                    className="inline-flex items-center justify-center p-2 border rounded-lg bg-blue-500 mx-1.5"
                                                    size="sm"
                                                    onClick={() => { setOpenProductForm(true); setTipoForm('Editar'); setIdProduct(id) }}
                                                >
                                                    <PencilIcon className="h-5 w-5" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip content={estado === 'ACTIVO' ? "Eliminar categoria" : "Activar categoria"}>
                                                <Button className={`inline-flex items-center justify-center p-2 border rounded-lg bg-${estado === 'ACTIVO' ? `red` : `blue`}-500`} size="sm"
                                                    onClick={() => { setOpenDeleteProduct(true); setIdProduct(id); setActivarEliminar(estado) }}>
                                                    {estado === 'ACTIVO' ? <TrashIcon className="h-5 w-5" /> : <CheckBadgeIcon className="h-5 w-5" />}
                                                </Button>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </CardBody>

            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Button variant="outlined" size="sm" disabled={page === 1} onClick={() => handlePageChange(page - 1)}>
                    Anterior
                </Button>
                <div className="flex items-center gap-2">
                    {Array.from({ length: pages }, (_, index) => index + 1).map((pageNumber) => (
                        <IconButton
                            key={pageNumber}
                            variant={pageNumber === page ? "outlined" : "text"}
                            size="sm"
                            onClick={() => handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </IconButton>
                    ))}
                </div>
                <Button variant="outlined" size="sm" disabled={page === pages} onClick={() => handlePageChange(page + 1)}>
                    Siguiente
                </Button>
            </CardFooter>
            {openProductForm && <ProductForm open={openProductForm} setOpen={setOpenProductForm} setOkCreate={setOkCreate} setMensaje={setMensaje} tipo={tipoForm} idProduct={idProduct} />}
            {openDeleteProduct && <ModalActivarDesactivar id={idProduct} activar_eliminar={activar_eliminar} open={openDeleteProduct} setOpen={setOpenDeleteProduct}
                cabecera={activar_eliminar === 'ACTIVO' ? 'Desactivar producto' : 'Activar producto'}
                cuerpo={activar_eliminar === 'ACTIVO' ? '¿Desea desactivar este producto?' : '¿Desea activar este producto?'}
                confirmacion='Aceptar' salida='Cancelar' color={activar_eliminar === 'ACTIVO' ? 'red' : 'green'} tipoItem='producto' />}
            {okCreate && <AlertCustom hexBorder={'#2ec946'} hexBG={'#2ec946'} hexText={'#2ec946'} mensaje={mensaje} />}
        </Card>
    );
}
