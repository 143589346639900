import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input,
  IconButton,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { agregarAlCarrito } from '../actions/carrito/carritoActions';
import { InformationCircleIcon } from "@heroicons/react/24/solid";
export function ProductCard({ id_producto, imagen, nombre, descripcion, precio, openModalAviso, setOpenModalAviso, setOpenDetail, setIdProductoDetail }) {
  const useData = useSelector((state) => state.userLogin || null);
  const { error, userInfo } = useData;
  const dispatch = useDispatch();
  const [cantidad, setCantidad] = useState(1);

  const adminPhoneNumber = "593978993494";

  // Mensaje predefinido para enviar en WhatsApp, incluyendo el ID del producto
  const whatsappMessage = `Quisiera más información de este producto:\nhttp://localhost:3000/tienda?id=${id_producto}`;

  // URL para redirigir a WhatsApp
  const whatsappUrl = `https://wa.me/${adminPhoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  const handleAddToCart = () => {
    if (!error) {
      if (userInfo) {
        if (userInfo?.perfil === 31) {
          dispatch(agregarAlCarrito({ id_producto, cantidad }));
        } else if ([16, 22].includes(userInfo?.perfil)) {
          return
        }
      } else {
        setOpenModalAviso(true);
      }
    } else {
      setOpenModalAviso(true);
    }
  };
  const handleCantidadChange = (e) => {
    setCantidad(Number(e.target.value));
  };

  return (
    <Card className="w-full sm:w-80 bg-purple-100 shadow-lg h-full">
      <CardHeader shadow={false} floated={false} className="h-64 sm:h-80">
        <img
          src={imagen}
          alt="card-image"
          className="h-full w-full object-cover rounded-t-lg"
        />
        <IconButton
            ripple={false}
            fullWidth={true}
            onClick={() => { setOpenDetail(true); setIdProductoDetail(id_producto) }}
            className="absolute top-4 right-4 bg-purple-700 text-white hover:bg-purple-600 rounded-full"
            size="sm"
          >
            <InformationCircleIcon className="h-5 w-5" />
          </IconButton>
      </CardHeader>
      <CardBody className="bg-purple-50 min-h-[150px] max-h-[200px]">
        <div className="mb-2 flex items-center justify-between">
          <Typography color="purple" className="font-bold truncate max-w-[200px] text-lg">
            {nombre}
          </Typography>
          <Typography color="purple" className="font-bold text-lg">
            ${precio}
          </Typography>
        </div>
        <Typography
          variant="small"
          color="gray"
          className="font-normal opacity-75 truncate max-w-[200px] line-clamp-3"
        >
          <ul>
            {Array.isArray(descripcion) ? (
              descripcion.map((observacion, index) => (
                <li key={index}>- {observacion}</li>
              ))
            ) : (
              <span>{descripcion}</span>
            )}
          </ul>
        </Typography>

      </CardBody>
      <CardFooter className="pt-0 bg-purple-50">
        <div className="flex items-center justify-between mb-4">
          <Typography variant="small" color="gray" className="font-normal">
            Cantidad:
          </Typography>
          <Input
            type="number"
            value={cantidad}
            onChange={handleCantidadChange}
            min="1"
            className="w-20 text-center"
          />
        </div>
        <Button
          ripple={false}
          fullWidth={true}
          onClick={handleAddToCart}
          className="bg-purple-700 text-white shadow-md hover:bg-purple-600 hover:scale-105 focus:bg-purple-600 active:bg-purple-800"
        >
          Añadir al carrito
        </Button>
        <Button
          ripple={false}
          fullWidth={true}
          onClick={() => window.open(whatsappUrl, '_blank')}
          className="bg-green-700 text-white shadow-md hover:bg-green-600 hover:scale-105 focus:bg-green-600 active:bg-green-800"
        >
          Pedir por WhatsApp
        </Button>
      </CardFooter>
    </Card>
  );
}
