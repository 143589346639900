import{
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_RECUP_PASS_REQUEST,
USER_RECUP_PASS_SUCCESS,
USER_RECUP_PASS_FAIL,
    } from '../constantes/loginConstants'
    export const userLoginReducer = (state = {}, action) => {
        switch (action.type) {
            case USER_LOGIN_REQUEST:
                return { loading: true };
            case USER_LOGIN_SUCCESS:
                return { loading: false, userInfo: action.payload };
            case USER_LOGIN_FAIL:
                return { loading: false, error: action.payload };
            case USER_LOGOUT:
                return {};
            default:
                return state;
        }
    };
    export const userRecupPassReducer = (state = {}, action) => {
        switch (action.type) {
            case USER_RECUP_PASS_REQUEST:
                return { loading: true };
            case USER_RECUP_PASS_SUCCESS:
                return { loading: false, urlRecover: action.payload };
            case USER_RECUP_PASS_FAIL:
                return { loading: false, error: action.payload };
            case USER_LOGOUT:
                return {};
            default:
                return state;
        }
    };