import {
    USUARIO_LIST_RESET,
    USUARIO_CREATE_FAIL,
    USUARIO_DELETE_REQUEST,
    USUARIO_CREATE_REQUEST,
    USUARIO_CREATE_SUCCESS,
    USUARIO_DELETE_FAIL,
    USUARIO_DELETE_RESET,
    USUARIO_DELETE_SUCCESS,
    USUARIO_DETAILS_FAIL,
    USUARIO_DETAILS_REQUEST,
    USUARIO_DETAILS_RESET,
    USUARIO_DETAILS_SUCCESS,
    USUARIO_LIST_FAIL,
    USUARIO_LIST_REQUEST,
    USUARIO_LIST_SUCCESS,
    USUARIO_UPDATE_FAIL,
    USUARIO_UPDATE_REQUEST,
    USUARIO_UPDATE_RESET,
    USUARIO_UPDATE_SUCCESS,
    USUARIO_ACTIVATE_REQUEST,
    USUARIO_ACTIVATE_SUCCESS,
    USUARIO_ACTIVATE_FAIL,
    USUARIO_ACTIVATE_RESET,
    USUARIO_REGISTRO_REQUEST,
    USUARIO_REGISTRO_SUCCESS,
    USUARIO_REGISTRO_FAIL,
} from '../constantes/usuariosConstants'
export const usuariosListReducer = (
    state = { loading: true, usuarios: [] },
    action
) => {
    switch (action.type) {
        case USUARIO_LIST_REQUEST:
            return { loading: true, usuarios: [] };
        case USUARIO_LIST_SUCCESS:
            return {
                loading: false,
                usuarios: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case USUARIO_LIST_FAIL:
            return { loading: false, error: action.payload };
        case USUARIO_LIST_RESET:
            return { usuarios: [] };
        default:
            return state;
    }
};

export const usuariosCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case USUARIO_CREATE_REQUEST:
            return { loading: true };
        case USUARIO_CREATE_SUCCESS:
            return { loading: false, success: true };
        case USUARIO_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
export const usuariosRegistroReducer = (state = {}, action) => {
    switch (action.type) {
        case USUARIO_REGISTRO_REQUEST:
            return { loading: true };
        case USUARIO_REGISTRO_SUCCESS:
            return { loading: false, success: true };
        case USUARIO_REGISTRO_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const usuariosDetailsReducer = (state = { usuarios: {} }, action) => {
    switch (action.type) {
        case USUARIO_DETAILS_REQUEST:
            return { ...state, loading: true };
        case USUARIO_DETAILS_SUCCESS:
            return { loading: false, usuarios: action.payload };
        case USUARIO_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case USUARIO_DETAILS_RESET:
            return { usuarios: {} };
        default:
            return state;
    }
};

export const usuariosUpdateReducer = (state = { usuarios: {} }, action) => {
    switch (action.type) {
        case USUARIO_UPDATE_REQUEST:
            return { loading: true };
        case USUARIO_UPDATE_SUCCESS:
            return { loading: false, success: true, usuarios: action.payload };
        case USUARIO_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case USUARIO_UPDATE_RESET:
            return { usuarios: {} };
        default:
            return state;
    }
};

export const usuariosDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USUARIO_DELETE_REQUEST:
            return { loading: true };
        case USUARIO_DELETE_SUCCESS:
            return { loading: false, success: true };
        case USUARIO_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case USUARIO_DELETE_RESET:
            return {};
        default:
            return state;
    }
};
export const usuariosActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case USUARIO_ACTIVATE_REQUEST:
            return { loading: true };
        case USUARIO_ACTIVATE_SUCCESS:
            return { loading: false, success: true };
        case USUARIO_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case USUARIO_ACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};



