import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { listCarritoDetails } from "../../../actions/carrito/carritoActions";

export function CarritosForm({ open, setOpen, setOkCreate, setMensaje, id_carrito }) {
    const dispatch = useDispatch();
    const handleOpen = () => setOpen((cur) => !cur);


    useEffect(() => {
        
        if (id_carrito)
            dispatch(listCarritoDetails(id_carrito))
    }, [])
    const { carrito } = useSelector((state) => state.carritoDetailsR);

    return (
        <>
            <Dialog
                open={open}
                handler={handleOpen}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full max-w-[40rem]">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Datos del carrito
                        </Typography>
                        <ul role="list" className="divide-y divide-gray-100">
                            {Array.isArray(carrito?.result) && carrito?.result.length > 0 ? (
                                carrito?.result?.map((item) => (
                                    <li key={item.id} className="flex justify-between gap-x-6 py-5">
                                        <div className="flex min-w-0 gap-x-4">
                                            <div className="min-w-0 flex-auto">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">{item.nombre_producto}</p>
                                            </div>
                                        </div>
                                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                            <p className="mt-1 text-xs leading-5 text-gray-500">
                                                {item.cantidad}
                                            </p>
                                        </div>
                                    </li>
                                ))) : (
                                <p className="text-gray-500">No hay productos en el carrito.</p>
                            )}
                        </ul>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-1/2 md:w-1/2 px-3 mt-8">
                                <Button className="bg-[#646464] w-full"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpen(false);
                                    }}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Dialog>
        </>
    );
}
