import axios from "axios";
import {
  USUARIO_CREATE_FAIL,
  USUARIO_CREATE_REQUEST,
  USUARIO_CREATE_SUCCESS,
  USUARIO_DELETE_REQUEST,
  USUARIO_DELETE_SUCCESS,
  USUARIO_DETAILS_FAIL,
  USUARIO_DETAILS_REQUEST,
  USUARIO_DETAILS_RESET,
  USUARIO_DETAILS_SUCCESS,
  USUARIO_LIST_FAIL,
  USUARIO_LIST_REQUEST,
  USUARIO_LIST_SUCCESS,
  USUARIO_UPDATE_FAIL,
  USUARIO_UPDATE_REQUEST,
  USUARIO_UPDATE_SUCCESS,
  USUARIO_ACTIVATE_REQUEST,
  USUARIO_ACTIVATE_SUCCESS,
  USUARIO_ACTIVATE_FAIL,
  USUARIO_REGISTRO_REQUEST,
  USUARIO_REGISTRO_SUCCESS,
  USUARIO_REGISTRO_FAIL
} from "../../constantes/usuariosConstants";
import {
  COMBO_USUARIOS_LIST_FAIL,
  COMBO_USUARIOS_LIST_REQUEST,
  COMBO_USUARIOS_LIST_SUCCESS,
  COMBO_PERFILES_LIST_REQUEST,
  COMBO_PERFILES_LIST_SUCCESS,
  COMBO_PERFILES_LIST_FAIL
} from "../../constantes/comboUsuariosConstants";
import { USER_LOGOUT, USER_DETAILS_RESET } from "../../constantes/loginConstants";
import { URL_SERVER } from "../../constantes/serverUrl";
import Swal from "sweetalert2";
import { AlertNoti } from "../../componentes/AlertNoti";

export const usuariosListaRegistros = (parameters) => async (dispatch, getState) => {
  //var navigate = useNavigate();
  try {

    dispatch({
      type: USUARIO_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/usuarios/listar?valorBusqueda=${parameters.valorBusqueda}&page=${parameters.page}&limit=${parameters.limitPage}`,
      config
    );
    dispatch({
      type: USUARIO_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error?.response?.data?.message === "jwt expired") {
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: USER_LOGOUT });
      parameters.navigate("/login");
    }
    if (error?.response?.statusText === "Unauthorized") {
      parameters.navigate("/errorRedirectScreen");
    }
    dispatch({
      type: USUARIO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteUsuario = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_DELETE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };

    //api call to delete product
    const data = await axios.delete(URL_SERVER + `/usuarios/eliminar/${id}`, config);
    dispatch({
      type: USUARIO_DELETE_SUCCESS,
    });
    dispatch(usuariosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }))
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Usuario inactivado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al eliminar usuario' })
    dispatch({
      type: USUARIO_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const activateUsuario = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_ACTIVATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };

    await axios.put(URL_SERVER + `/usuarios/activar/${id}`, {}, config);
    dispatch({
      type: USUARIO_ACTIVATE_SUCCESS,
    });
    dispatch(usuariosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Usuario activado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'erro', title: 'Error al activar usuario' })
    dispatch({
      type: USUARIO_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createUsuario = (usuario) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_CREATE_REQUEST,
    });

    //get cooperativa from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };

    const { data } = await axios.post(
      URL_SERVER + "/usuarios/crear",
      usuario?.data,
      config
    );
    dispatch({
      type: USUARIO_CREATE_SUCCESS,
      payload: data,
    });
    usuario.setOkCreate(true);
    usuario.setMensaje(data?.mensaje || 'Ingresado correctamente');
    dispatch(usuariosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10,
    }));
    AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Usuario creado' })
  } catch (error) {
    AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al crear usuario' })
    dispatch({
      type: USUARIO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    usuario.setOkCreate(false);
    usuario.setMensaje('Error al crear');
  }
};

export const updateUsuario =
  (usuario) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USUARIO_UPDATE_REQUEST,
      });

      //get user from state
      const {
        userLogin: { userInfo },
      } = getState();
      //headers
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: userInfo.token,
        },
      };

      //update product
      const { data } = await axios.put(
        URL_SERVER + `/usuarios/actualizar/${usuario?.data?.id}`,
        usuario?.data,
        config
      );
      dispatch({
        type: USUARIO_UPDATE_SUCCESS,
        payload: data,
      });
      usuario.setOkCreate(true);
      usuario.setMensaje(data?.mensaje || 'Ingresado correctamente');
      dispatch(usuariosListaRegistros({
        valorBusqueda: '',
        page: 1,
        limitPage: 10,
      }));
      AlertNoti({ letra_color: 'white', fondo_color: 'green', icono: 'success', title: 'Usuario actualizado' })
    } catch (error) {
      AlertNoti({ letra_color: 'white', fondo_color: 'red', icono: 'error', title: 'Error al actualizar usuario' })
      dispatch({
        type: USUARIO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      usuario.setOkCreate(false);
      usuario.setMensaje('Error al crear');
    }
  };

export const updateInfoUser = (usuario, id_usuario) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };

    //update product
    const { data } = await axios.put(
      URL_SERVER + `/usuarios/actualizarInfoUser`,
      usuario,
      config
    );
    dispatch({
      type: USUARIO_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch(listUsuarioDetails(id_usuario));
    Swal.fire({
      title: "Actualizado",
      text: "Se actualziaron sus datos",
      icon: "success"
    });
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "Error al actualziar sus datos",
      icon: "error"
    });
    dispatch({
      type: USUARIO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listUsuarioDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USUARIO_DETAILS_REQUEST });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };

    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/usuarios/obtener/${id}`, config);
    dispatch({
      type: USUARIO_DETAILS_SUCCESS,
      payload: data,
    });
    dispatch(usuariosListaRegistros({
      valorBusqueda: '',
      page: 1,
      limitPage: 10
    }))
  } catch (error) {
    dispatch({
      type: USUARIO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const obtenerMiID = (setIdUsuario) => async (dispatch, getState) => {
  try {
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        token: userInfo.token,
      },
    };

    //api call to get product
    const { data } = await axios.get(URL_SERVER + `/usuarios/getid`, config);
    setIdUsuario(data?.result);
  } catch (error) {
    console.log(error);
  }
};
export const clearData = () => async (dispatch, getState) => {
  dispatch({ type: USUARIO_DETAILS_RESET });
};
export const comboUsuarios = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMBO_USUARIOS_LIST_REQUEST,
    });
    const { data } = await axios.get(
      URL_SERVER +
      `/usuario/combo`
    );
    dispatch({
      type: COMBO_USUARIOS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMBO_USUARIOS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listComboPerfiles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMBO_PERFILES_LIST_REQUEST,
    });
    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: userInfo.token,
      },
    };
    const { data } = await axios.get(
      URL_SERVER +
      `/usuarios/combo_perfiles`,
      config
    );
    dispatch({
      type: COMBO_PERFILES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMBO_PERFILES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registrarUsuario = (usuario) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USUARIO_REGISTRO_REQUEST,
    });

    //get cooperativa from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json"
      },
    };
    const { data } = await axios.post(
      URL_SERVER + "/usuarios/registrar",
      usuario,
      config
    );
    dispatch({
      type: USUARIO_REGISTRO_SUCCESS,
      payload: data,
    });
    Swal.fire({
      title: "Registrado",
      text: "Registro exitoso",
      icon: "success"
    });
    try {
      await axios.post(
        URL_SERVER + "/usuarios/enviarConfirmLink",
        usuario,
        config
      );
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error al enviar correo de confirmacion",
        icon: "error"
      });
      dispatch({
        type: USUARIO_REGISTRO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "Error al registrarse",
      icon: "error"
    });
    dispatch({
      type: USUARIO_REGISTRO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
