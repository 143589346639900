import {
    PEDIDOS_LIST_RESET,
    PEDIDOS_CREATE_FAIL,
    PEDIDOS_DELETE_REQUEST,
    PEDIDOS_CREATE_REQUEST,
    PEDIDOS_CREATE_SUCCESS,
    PEDIDOS_DELETE_FAIL,
    PEDIDOS_DELETE_RESET,
    PEDIDOS_DELETE_SUCCESS,
    PEDIDOS_DETAILS_FAIL,
    PEDIDOS_DETAILS_REQUEST,
    PEDIDOS_DETAILS_RESET,
    PEDIDOS_DETAILS_SUCCESS,
    PEDIDOS_LIST_FAIL,
    PEDIDOS_LIST_REQUEST,
    PEDIDOS_LIST_SUCCESS,
    PEDIDOS_UPDATE_FAIL,
    PEDIDOS_UPDATE_REQUEST,
    PEDIDOS_UPDATE_RESET,
    PEDIDOS_UPDATE_SUCCESS,
    PEDIDOS_ACTIVATE_REQUEST,
    PEDIDOS_ACTIVATE_SUCCESS,
    PEDIDOS_ACTIVATE_FAIL,
    PEDIDOS_ACTIVATE_RESET,
    PEDIDOS_INFOUSER_REQUEST,
    PEDIDOS_INFOUSER_SUCCESS,
    PEDIDOS_INFOUSER_FAIL,
    PEDIDOS_INFOUSER_RESET,
} from '../constantes/pedidosConstants'
export const pedidosListReducer = (
    state = { loading: true, pedidos: [] },
    action
) => {
    switch (action.type) {
        case PEDIDOS_LIST_REQUEST:
            return { loading: true, pedidos: [] };
        case PEDIDOS_LIST_SUCCESS:
            return {
                loading: false,
                pedidos: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case PEDIDOS_LIST_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_LIST_RESET:
            return { pedidos: [] };
        default:
            return state;
    }
};

export const pedidosCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PEDIDOS_CREATE_REQUEST:
            return { loading: true };
        case PEDIDOS_CREATE_SUCCESS:
            return { loading: false, success: true };
        case PEDIDOS_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const pedidoDetailsReducer = (state = { pedido: {} }, action) => {
    switch (action.type) {
        case PEDIDOS_DETAILS_REQUEST:
            return { ...state, loading: true };
        case PEDIDOS_DETAILS_SUCCESS:
            return { loading: false, pedido: action.payload };
        case PEDIDOS_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_DETAILS_RESET:
            return { pedido: {} };
        default:
            return state;
    }
};

/*export const pedidoinfoUserReducer = (state = { pedidos_infouser: {} }, action) => {
    switch (action.type) {
        case PEDIDOS_INFOUSER_REQUEST:
            return { ...state, loading: true };
        case PEDIDOS_INFOUSER_SUCCESS:
            return { 
                loading: false, 
                pedidos_infouser: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
             };
        case PEDIDOS_INFOUSER_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_INFOUSER_RESET:
            return { pedidos_infouser: {} };
        default:
            return state;
    }
};*/

export const pedidoinfoUserReducer = (
    state = { loading: true, pedidos_infouser: [] },
    action
) => {
    switch (action.type) {
        case PEDIDOS_INFOUSER_REQUEST:
            return { loading: true, pedidos_infouser: [] };
        case PEDIDOS_INFOUSER_SUCCESS:
            return {
                loading: false,
                pedidos_infouser: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case PEDIDOS_INFOUSER_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_INFOUSER_RESET:
            return { pedidos_infouser: [] };
        default:
            return state;
    }
};

export const pedidosUpdateReducer = (state = { pedidos: {} }, action) => {
    switch (action.type) {
        case PEDIDOS_UPDATE_REQUEST:
            return { loading: true };
        case PEDIDOS_UPDATE_SUCCESS:
            return { loading: false, success: true, pedidos: action.payload };
        case PEDIDOS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_UPDATE_RESET:
            return { pedidos: {} };
        default:
            return state;
    }
};

export const pedidosDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PEDIDOS_DELETE_REQUEST:
            return { loading: true };
        case PEDIDOS_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PEDIDOS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const pedidosActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case PEDIDOS_ACTIVATE_REQUEST:
            return { loading: true };
        case PEDIDOS_ACTIVATE_SUCCESS:
            return { loading: false, success: true };
        case PEDIDOS_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case PEDIDOS_ACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};



