import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import Main from './Main';
import Home from "./vistas/inicio/HomeVista";
import Tienda from './vistas/tienda/TiendaVista';
import Carrito from "./vistas/carrito/CarritoVista";
import store from './store';
import { Provider, useSelector } from 'react-redux';
import { LoginCard } from './componentes/LoginCard';
import { RegisterCard } from './componentes/RegisterCard';
import { useState, useEffect } from 'react';
import NavBar from './componentes/NavBar';
import { SidebarBurger } from './componentes/SideBarAdmin';
import menuAdmin from './assets/data/menu_admin.json'
import ModalGenerico from './componentes/ModalGenerico';
import { RecuperarPass } from './componentes/RecuperarPass';
import InfoUserLoging from './vistas/infousuario/InfoUserLoging';
import { CambiarPassword } from './vistas/infousuario/CambiarPassword';
import RestaurarClaveScreen from './vistas/infousuario/RestaurarClaveScreen';
import { Contacto } from './vistas/contacto/Contacto';
import ConfirmRegistroScreen from './vistas/infousuario/ConfirmRegistroScreen';
import InfoPedidosScreen from './vistas/infousuario/InfoPedidosScreen';
import ModalDetalleProducto from './componentes/ModalDetalleProducto';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

function AppContent() {
  const [openloginCard, setLoginCard] = useState(false);
  const [registerCard, setRegisterCard] = useState(false);
  const [recoverPassCard, setRecoverPassCard] = useState(false);
  const [rutaResult, setRutaResult] = useState('');
  const [isadmin, setIsAdmin] = useState(false);
  const [isLoging, setIsLoging] = useState(false);
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [cabecera, setCabecera] = useState('');
  const [cuerpo, setCuerpo] = useState('');
  const useData = useSelector((state) => state.userLogin || null);
  const [openDetail, setOpenDetail] = useState(false);
  const [id_producto_detail, setIdProductoDetail] = useState(null);
  const { error, userInfo } = useData;
  const navigate = useNavigate();

  useEffect(() => {
    if (rutaResult) {
      navigate(rutaResult);
      if (rutaResult === 'dashboard')
        setIsAdmin(true);
      else
        setIsAdmin(false);
    }
  }, [rutaResult, navigate]);

  useEffect(() => {
    if (!error) {
      if (userInfo) {
        if (userInfo?.perfil == 31)
          setIsAdmin(false)
        else if ([16, 22].includes(userInfo?.perfil)) {
          setIsAdmin(true);
          navigate('/dashboard')
          return
        }
      }
    }
  }, []);
  useEffect(() => {
    console.log(openDetail);
    console.log(id_producto_detail);
  }, [openDetail, id_producto_detail])

  return (
    <div className={isadmin ? `container mx-auto mt-4` : ''} >
      {!isadmin ? <NavBar setLoginCard={setLoginCard} setRegisterCard={setRegisterCard} isLoging={isLoging} setIsLoging={setIsLoging} /> : <SidebarBurger menuConfig={menuAdmin} />}


      <Routes>
        <Route path="/" element={<PrivateRoute />} />
        <Route path="/*" element={<Main />} />
        <Route path="/home" element={<Home open={openloginCard} setOpen={setLoginCard} />} />
        <Route exact path="/tienda" element={<Tienda open={openloginCard} setOpen={setLoginCard} setOpenDetail={setOpenDetail} setIdProductoDetail={setIdProductoDetail} />} />
        <Route exact path="/carrito" element={<Carrito open={openloginCard} setOpen={setLoginCard} />} />
        <Route path="/infouser" element={<InfoUserLoging />} />
        <Route path="/infopedidos" element={<InfoPedidosScreen />} />
        <Route path="/cambiarclave" element={<CambiarPassword />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route exact path="/restaurar_clave/:token" element={<RestaurarClaveScreen />} />
        <Route exact path="/confirmar_registro/:token" element={<ConfirmRegistroScreen />} />
      </Routes>
      {
        <ModalDetalleProducto
          isOpen={openDetail}
          onClose={() => setOpenDetail(false)}
          productId={id_producto_detail}
        />
      }
      {openloginCard && (
        <LoginCard open={openloginCard} setOpen={setLoginCard} setOpenRegister={setRegisterCard} setRutaResult={setRutaResult} setRecoverPassCard={setRecoverPassCard} />
      )}
      {registerCard && (
        <RegisterCard open={registerCard} setOpen={setRegisterCard} setOpenLogin={setLoginCard} setShowModalConfirmacion={setShowModalConfirmacion} setCuerpo={setCuerpo} setCabecera={setCabecera} />
      )}
      {recoverPassCard && (
        <RecuperarPass open={recoverPassCard} setOpen={setRecoverPassCard} setShowModalConfirmacion={setShowModalConfirmacion} setCuerpo={setCuerpo} setCabecera={setCabecera} />
      )}
      {
        <ModalGenerico
          cabecera={cabecera}
          cuerpo={cuerpo}
          showModal={showModalConfirmacion}
          setShowModal={setShowModalConfirmacion}
        />
      }
      
    </div>
  );
}

export default App;
