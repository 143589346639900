import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
} from "@material-tailwind/react";
import { validarCadenaConLongitud } from "../../../funfiones/validaciones";
import { useDispatch, useSelector } from "react-redux";
import { listCategoriasDetails, createCategoria, updateCategoria, clearData } from "../../../actions/categorias/categoriasActions";

export function CategoriasForm({ open, setOpen, setOkCreate, setMensaje, tipoForm, id_categoria }) {
    const dispatch = useDispatch();
    const handleOpen = () => { dispatch(clearData()); setOpen((cur) => !cur); };
    const [nombre, setNombre] = React.useState("");
    const [observacion, setObservacion] = React.useState("");

    const guardarCategoria = (credenciales) => dispatch(createCategoria(credenciales));
    const actualizarCategoria = (credenciales) => dispatch(updateCategoria(credenciales));
    const categoriaDataEdit = useSelector((state) => state.categoriasDetailsR);

    useEffect(() => {
        if (id_categoria && tipoForm === 'Editar') {
            dispatch(listCategoriasDetails(id_categoria));
        } else {
            setNombre('');
            setObservacion('');
        }
    }, [id_categoria, tipoForm]);

    useEffect(() => {
        if (categoriaDataEdit?.categoria?.result && id_categoria && tipoForm === 'Editar') {
            setNombre(categoriaDataEdit?.categoria?.result[0]?.nombre);
            setObservacion(categoriaDataEdit?.categoria?.result[0]?.observacion);
        } else {
            setNombre('');
            setObservacion('');
        }
    }, [categoriaDataEdit]);

    function validarNombre(e) {
        if (validarCadenaConLongitud(e.target.value, 1, 100)) {
            setNombre(e.target.value);
        }
    }

    const hanleCrearCategoria = (e) => {
        e.preventDefault();
        const data = {
            nombre: nombre,
            observacion: observacion,
        }
        if (id_categoria) {
            data.id = id_categoria;
            actualizarCategoria({ data, setOkCreate, setMensaje });
        } else {
            guardarCategoria({ data, setOkCreate, setMensaje });
        }
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                handler={handleOpen}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full max-w-[40rem] sm:max-w-[90%]">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {tipoForm === 'Crear' ? 'Nueva Categoría' : 'Editar Categoría'}
                        </Typography>
                        <Typography
                            className="mb-3 font-normal"
                            variant="paragraph"
                            color="gray"
                        >
                            {tipoForm === 'Crear' ? 'Ingrese los datos de la nueva categoría' : 'Edite los datos de la categoría'}
                        </Typography>
                        <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full sm:w-1/2 px-3 mt-8">
                                <Input label="Nombre" size="lg" value={nombre} onChange={validarNombre} />
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-8">
                                <Input label="Observación" size="lg" value={observacion} onChange={(e) => setObservacion(e.target.value)} />
                            </div>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <div className="flex flex-wrap -mx-3 mb-3">
                            <div className="w-full sm:w-1/2 px-3 mt-8">
                                <Button className="bg-[#646464] w-full" onClick={(e) => { e.preventDefault(); setOpen(false); }}>
                                    Cancelar
                                </Button>
                            </div>
                            <div className="w-full sm:w-1/2 px-3 mt-8">
                                <Button variant="gradient" className="w-full" onClick={hanleCrearCategoria}>
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Dialog>
        </>
    );
}
