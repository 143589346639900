import React, { useState } from 'react';
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Select,
    Option,
} from "@material-tailwind/react";

export function ModalChangeState({ open, handleOpen, idPedido, onUpdateState }) {
    const [estado, setEstado] = useState("");

    const handleChangeEstado = (e) => {
        setEstado(e);
    };

    const handleSubmit = () => {
        if (estado && idPedido) {
            onUpdateState(idPedido, estado); // Llamar a la función para actualizar el estado del pedido
        }
        handleOpen(); // Cerrar el modal
    };

    return (
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Cambiar Estado del Pedido</DialogHeader>
            <DialogBody divider>
                <Select label="Selecciona el estado" onChange={handleChangeEstado}>
                    <Option value="PENDIENTE">Pendiente</Option>
                    <Option value="EN PROCESO">En Proceso</Option>
                    <Option value="TERMINADO">Terminado</Option>
                    <Option value="CANCELADO">Cancelado</Option>
                </Select>
            </DialogBody>
            <DialogFooter>
                <Button variant="text" color="red" onClick={handleOpen}>
                    Cancelar
                </Button>
                <Button variant="gradient" color="green" onClick={handleSubmit}>
                    Guardar
                </Button>
            </DialogFooter>
        </Dialog>
    );
}
