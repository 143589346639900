export function formatDate(isoString) {
    const date = new Date(isoString);

    // Extraer los componentes de la fecha
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();

    // Formatear la fecha como dd/mm/yyyy
    return `${day}/${month}/${year}`;
}