import {
    CATEGORIAS_LIST_RESET,
    CATEGORIAS_CREATE_FAIL,
    CATEGORIAS_DELETE_REQUEST,
    CATEGORIAS_CREATE_REQUEST,
    CATEGORIAS_CREATE_SUCCESS,
    CATEGORIAS_DELETE_FAIL,
    CATEGORIAS_DELETE_RESET,
    CATEGORIAS_DELETE_SUCCESS,
    CATEGORIAS_DETAILS_FAIL,
    CATEGORIAS_DETAILS_REQUEST,
    CATEGORIAS_DETAILS_RESET,
    CATEGORIAS_DETAILS_SUCCESS,
    CATEGORIAS_LIST_FAIL,
    CATEGORIAS_LIST_REQUEST,
    CATEGORIAS_LIST_SUCCESS,
    CATEGORIAS_UPDATE_FAIL,
    CATEGORIAS_UPDATE_REQUEST,
    CATEGORIAS_UPDATE_RESET,
    CATEGORIAS_UPDATE_SUCCESS,
    CATEGORIAS_ACTIVATE_REQUEST,
    CATEGORIAS_ACTIVATE_SUCCESS,
    CATEGORIAS_ACTIVATE_FAIL,
    CATEGORIAS_ACTIVATE_RESET
} from '../constantes/categoriasConstants'
export const categoriasListReducer = (
    state = { loading: true, categoria: [] },
    action
) => {
    switch (action.type) {
        case CATEGORIAS_LIST_REQUEST:
            return { loading: true, categorias: [] };
        case CATEGORIAS_LIST_SUCCESS:
            return {
                loading: false,
                categorias: action.payload.result,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case CATEGORIAS_LIST_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIAS_LIST_RESET:
            return { categorias: [] };
        default:
            return state;
    }
};

export const categoriasCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIAS_CREATE_REQUEST:
            return { loading: true };
        case CATEGORIAS_CREATE_SUCCESS:
            return { loading: false, success: true };
        case CATEGORIAS_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const categoriasDetailsReducer = (state = { categoria: {} }, action) => {
    switch (action.type) {
        case CATEGORIAS_DETAILS_REQUEST:
            return { ...state, loading: true };
        case CATEGORIAS_DETAILS_SUCCESS:
            return { loading: false, categoria: action.payload };
        case CATEGORIAS_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIAS_DETAILS_RESET:
            return { categoria: {} };
        default:
            return state;
    }
};

export const categoriasUpdateReducer = (state = { categorias: {} }, action) => {
    switch (action.type) {
        case CATEGORIAS_UPDATE_REQUEST:
            return { loading: true };
        case CATEGORIAS_UPDATE_SUCCESS:
            return { loading: false, success: true, categorias: action.payload };
        case CATEGORIAS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIAS_UPDATE_RESET:
            return { categorias: {} };
        default:
            return state;
    }
};

export const categoriasDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIAS_DELETE_REQUEST:
            return { loading: true};
        case CATEGORIAS_DELETE_SUCCESS:
            return { loading: false, success: true};
        case CATEGORIAS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIAS_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const categoriasActivateReducer = (state = {}, action) => {
    switch (action.type) {
        case CATEGORIAS_ACTIVATE_REQUEST:
            return { loading: true};
        case CATEGORIAS_ACTIVATE_SUCCESS:
            return { loading: false, success: true};
        case CATEGORIAS_ACTIVATE_FAIL:
            return { loading: false, error: action.payload };
        case CATEGORIAS_ACTIVATE_RESET:
            return {};
        default:
            return state;
    }
};



