import React from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { activeProductos, deleteProductos } from "../actions/productos/productosActions";
import { activeCategoria, deleteCategoria } from "../actions/categorias/categoriasActions";
import { activateUsuario, deleteUsuario } from "../actions/usuarios/usuarioActions";
import { activarCarrito, deleteCarrito } from "../actions/carrito/carritoActions";

const ModalActivarDesactivar = (props) => {
    const dispatch = useDispatch();
    const { id, activar_eliminar, open, setOpen, cabecera, cuerpo, confirmacion, salida, color, tipoItem } = props

    const handleDeleteActivate = () => {
        switch (tipoItem) {
            case 'producto':
                activar_eliminar === 'ACTIVO' ? dispatch(deleteProductos(id)) : dispatch(activeProductos(id));
                break;
            case 'categoria':
                activar_eliminar === 'ACTIVO' ? dispatch(deleteCategoria(id)) : dispatch(activeCategoria(id));
                break;
            case 'usuario':
                activar_eliminar === 'ACTIVO' ? dispatch(deleteUsuario(id)) : dispatch(activateUsuario(id));
                break;
            case 'carrito':
                activar_eliminar === 'ACTIVO' ? dispatch(deleteCarrito(id)) : dispatch(activarCarrito(id));
                break;
            default:
                break;
        }
        setOpen(false);
    };

    return (
        <>
            <Dialog size="sm" open={open} handler={() => setOpen(false)}>
                <DialogHeader toggler={() => setOpen(false)}>
                    {cabecera}
                </DialogHeader>
                <DialogBody>
                    <p className="text-base leading-relaxed text-gray-600 font-normal">
                        {cuerpo}
                    </p>
                </DialogBody>
                <DialogFooter>
                    <Button
                        color="black"
                        buttonType="link"
                        onClick={() => setOpen(false)}
                        ripple={false}
                    >
                        {salida}
                    </Button>

                    <Button
                        color={color}
                        onClick={handleDeleteActivate}
                        ripple={true}
                    >
                        {confirmacion}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ModalActivarDesactivar;
