import React, { useState } from "react";
import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import { validarCadenaConLongitud, validarEmail } from "../../funfiones/validaciones";
import { useDispatch } from "react-redux";
import { enviarMensajeContacto } from "../../actions/contacto/contactoActions";

export function Contacto() {
    const dispatch = useDispatch();

    const [nombres, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');

    const [avisoNombre, setAvisoNombre] = useState(' ');
    const [avisoApellidos, setAvisoApellidos] = useState(' ');
    const [avisoCorreo, setAvisoCorreo] = useState(' ');
    const [avisoMensaje, setAvisoMensaje] = useState(' ');

    function validarCampos() {
        if (avisoNombre?.length === 0 && avisoApellidos?.length === 0 && avisoCorreo?.length === 0 && avisoMensaje?.length === 0) {
            return true;
        } else {
            if (avisoNombre?.length > 0) setAvisoNombre('Ingrese un nombre valido');
            if (avisoApellidos?.length > 0) setAvisoApellidos('Ingrese un apellido valido');
            if (avisoCorreo?.length > 0) setAvisoCorreo('Ingrese un correo valido');
            if (avisoMensaje?.length > 0) setAvisoMensaje('Ingrese un mensaje valido');
            return false;
        }
    }

    return (
        <section className="px-4 py-8 sm:px-8 lg:py-16">
            <div className="container mx-auto text-center">
                <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-4 !text-base lg:!text-2xl"
                >
                    Contactate con nosotros
                </Typography>
                <Typography
                    variant="h1"
                    color="blue-gray"
                    className="mb-4 !text-3xl lg:!text-5xl"
                >
                    Estamos aquí para ayudar
                </Typography>
                <Typography className="mb-10 font-normal !text-lg lg:mb-20 mx-auto max-w-3xl !text-gray-500">
                    Ya sea que se trate de una pregunta sobre nuestros servicios, una solicitud de asistencia técnica o sugerencias para una mejora.
                </Typography>
                <div className="grid grid-cols-1 gap-x-12 gap-y-6 lg:grid-cols-2 items-start">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d498.7215340298919!2d-79.46221118621814!3d-0.2752753759716784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwMTYnMzEuMCJTIDc5wrAyNyc0My4wIlc!5e0!3m2!1ses!2sec!4v1718769749315!5m2!1ses!2sec"
                        width="100%"
                        height="350"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="border border-purple-900 rounded-lg p-2"
                    ></iframe>
                    <form
                        action="#"
                        className="flex flex-col gap-4"
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <Typography
                                    variant="small"
                                    className="mb-2 text-left font-medium !text-purple-900"
                                >
                                    Nombres
                                </Typography>
                                <Input
                                    color="purple"
                                    size="lg"
                                    placeholder="Nombres"
                                    name="first-name"
                                    className="focus:border-t-purple-900"
                                    containerProps={{
                                        className: "min-w-full",
                                    }}
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        if (validarCadenaConLongitud(e?.target?.value, 1, 200))
                                            setAvisoNombre('')
                                        else setAvisoNombre('Ingrese un nombre valido')
                                        setNombre(e.target.value);
                                    }}
                                />
                                <Typography color="red">{avisoNombre}</Typography>
                            </div>
                            <div>
                                <Typography
                                    variant="small"
                                    className="mb-2 text-left font-medium !text-purple-900"
                                >
                                    Apellidos
                                </Typography>
                                <Input
                                    color="purple"
                                    size="lg"
                                    placeholder="Apellidos"
                                    name="last-name"
                                    className="focus:border-t-purple-900"
                                    containerProps={{
                                        className: "!min-w-full",
                                    }}
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        if (validarCadenaConLongitud(e?.target?.value, 1, 200))
                                            setAvisoApellidos('')
                                        else setAvisoApellidos('Ingrese un apellido valido')
                                        setApellidos(e.target.value);
                                    }}
                                />
                                <Typography color="red">{avisoApellidos}</Typography>
                            </div>
                        </div>
                        <div>
                            <Typography
                                variant="small"
                                className="mb-2 text-left font-medium !text-purple-900"
                            >
                                Tu correo
                            </Typography>
                            <Input
                                color="purple"
                                size="lg"
                                placeholder="nombre@email.com"
                                name="email"
                                className="focus:border-t-purple-900"
                                containerProps={{
                                    className: "!min-w-full",
                                }}
                                labelProps={{
                                    className: "hidden",
                                }}
                                onChange={(e) => {
                                    e.preventDefault();
                                    if (validarEmail(e?.target?.value))
                                        setAvisoCorreo('')
                                    else setAvisoCorreo('Ingrese un correo valido')
                                    setCorreo(e.target.value);
                                }}
                            />
                            <Typography color="red">{avisoCorreo}</Typography>
                        </div>
                        <div>
                            <Typography
                                variant="small"
                                className="mb-2 text-left font-medium !text-purple-900"
                            >
                                Tu mensaje
                            </Typography>
                            <Textarea
                                rows={6}
                                color="purple"
                                placeholder="Mensaje"
                                name="message"
                                className="focus:border-t-purple-900"
                                containerProps={{
                                    className: "!min-w-full",
                                }}
                                labelProps={{
                                    className: "hidden",
                                }}
                                onChange={(e) => {
                                    e.preventDefault();
                                    if (validarCadenaConLongitud(e?.target?.value, 1, 200))
                                        setAvisoMensaje('')
                                    else setAvisoMensaje('Ingrese un mensaje valido')
                                    setMensaje(e.target.value);
                                }}
                            />
                            <Typography color="red">{avisoMensaje}</Typography>
                        </div>
                        <Button className="w-full" color="purple"
                            onClick={(e) => {
                                e.preventDefault();
                                if (validarCampos()) {
                                    const data = {
                                        nombres,
                                        apellidos,
                                        correo,
                                        mensaje
                                    };
                                    dispatch(enviarMensajeContacto(data));
                                    setNombre('');
                                    setApellidos('');
                                    setCorreo('');
                                    setMensaje('');
                                }
                            }}>
                            Enviar mensaje
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Contacto;


                        